import { Box, Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormHelperText, Grid, InputLabel, MenuItem, Select, Typography, useTheme } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Alert from "@material-ui/lab/Alert/Alert";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { Entry } from "../../../../model/Entry";
import { initNewOrder } from "../../../../model/Order";
import { OrderItem } from "../../../../model/OrderItem";
import { PointOfSale } from "../../../../model/PointOfSale";
import { Product, RaceProductType, raceDataProductTypes } from "../../../../model/Product";
import { ApiBackend } from "../../../../providers/apibackend";
import { copyValuesFromPersonToEntry, getDateOfBirthAsString } from "../../../../utilities/EntryHelpers";
import ProductList from "../../../Common/ProductList/ProductList";
import ReadonlyTextField from "../../../Common/ReadonlyTextField";
import SelectPointOfSale from "../../../Orders/SelectPointOfSale";
import AddEntryAddons from "./AddEntryAddons";
import AddEntryCustomFields from "./AddEntryCustomFields";
import { awaiter, getInitPriceGroup, getInitPriceGroupWithMaxAge, isValidCode } from "./utils";
import { vasaloppetTimeGetterFormatter } from "../../../../utilities/date";
import { OrderContact } from "../../../../model/OrderContact";
import CardPaymentStep from "../../../Orders/Payments/CardPaymentStep";
import SwishPaymentStep from "../../../Orders/Payments/SwishPaymentStep";
import StartGroupSelector from "../../../Common/StartGroupSelector";
import withAlert, { IAlertContext } from "../../../Common/dialog/Alert";
import { ProductListChangeEvent } from "../../../Common/ProductList/utils";
import StripePaymentStep from "../../../Orders/Payments/StripePamentStep";

export interface IDefaults {
    raceProduct: Product;
    productVariantId: string;
    priceGroup?: string;
}

interface IProps {
    personId: string;
    defaults?: IDefaults;
    restrictedProductType?: RaceProductType;
    onSave: () => void;
    onAbort: () => void;
}

type PaymentType = "link" | "account" | "card" | "swish";
const AddEntryDialog = ({ personId, defaults, restrictedProductType, onSave, onAbort, showAlert }: IProps & IAlertContext) => {
    const [race, setRace] = useState(defaults ? defaults.raceProduct.id : null);
    const [variant, setVariant] = useState(defaults ? defaults.productVariantId : null);
    const [priceGroup, setPriceGroup] = useState(defaults?.priceGroup ? defaults.priceGroup : null);
    const [raceProduct, setRaceProduct] = useState(defaults ? defaults.raceProduct : null);
    const [order, setOrder] = useState(initNewOrder());
    const [step, setStep] = useState("entry");
    const [price, setPrice] = useState(0);
    const [estimating, setEstimating] = useState(false);
    const [code, setCode] = useState("");
    const [kortterminalPos, setKortterminalPos] = useState(null as PointOfSale);
    const [pointOfSale, setPointOfSale] = useState("");
    const [paymentComment, setPaymentComment] = useState("");
    const [paymentType, setPaymentType] = useState<PaymentType>("link");
    const [isCreatingOrder, setIsCreatingOrder] = useState(false);
    const [createOrderStatus, setCreateOrderStatus] = useState("");
    const [hasOrderItems, setHasOrderItems] = useState(false);
    const [hasStartGroupRequiredError, setHasStartGroupRequiredError] = useState(false);
    const [entryValidationErrors, setEntryValidationErrors] = useState(null as string[]);

    const initialized = useRef<boolean>(false);
    const backend = new ApiBackend();
    const theme = useTheme();

    // Run on load
    useEffect(() => {

        init();

    }, []);

    useEffect(() => {
        if (!initialized.current) {
            return;
        }

        if (!order?.items) {
            return;
        }

        order.items[0].entry.productId = race;
        order.items[0].productId = race;
        order.items[0].entry.variantId = null;
        order.items[0].entry.priceGroupId = null;
        order.items[0].productVariantId = null;
        order.items[0].productVariantPriceGroupId = null;

        setOrder({ ...order });
        setRaceProduct(null);
        setVariant(null);

        if (race) {
            backend.getProduct(race).then((prod) => {
                setRaceProduct(prod);

                if (prod.variants && prod.variants.length === 1) {
                    setVariant(prod.variants[0].Id);
                }
            });
        }
    }, [race]);

    useEffect(() => {
        if (!initialized.current) {
            return;
        }

        if (!variant || !order?.items || !raceProduct) {
            setPriceGroup(null);
            return;
        }

        order.items[0].entry.variantId = variant;
        order.items[0].productVariantId = variant;

        const varItem = raceProduct.variants.find(x => x.Id == variant);
        const raceStart = raceProduct.Event?.startDate ?? raceProduct.startGroups[0].startTime;
        let initPg = getInitPriceGroupWithMaxAge(raceStart, varItem, order.items[0].entry);
        if (!initPg || initPg.price == 0) {
            // If no valid pricegroup or a pricegroup with 0 cost, try find without agefilter
            let potential = getInitPriceGroup(varItem);
            if (potential?.id !== initPg?.id) {
                initPg = potential;
            }
        }
        order.items[0].entry.eventKey = varItem.productVariantRaceData.eventKey;
        order.items[0].productVariantPriceGroupId = initPg?.id;
        order.items[0].entry.priceGroupId = initPg?.id;
        order.items[0].entry.startgroup = null;

        setOrder({ ...order });
        setHasOrderItems(true);
        setHasStartGroupRequiredError(false);
        setPriceGroup(initPg?.id);
        validateEntryForRace();
    }, [variant]);

    useEffect(() => {
        if (!initialized.current) {
            return;
        }

        if (!order?.items) {
            return;
        }

        order.items[0].entry.priceGroupId = priceGroup;
        order.items[0].productVariantPriceGroupId = priceGroup;
        setOrder({ ...order });
        updatePrice();
    }, [priceGroup]);

    useEffect(() => {
        if (step === "order") {
            setPointOfSale("");
            setPaymentType("link");
            setPaymentComment("");
            setIsCreatingOrder(false);
        }
    }, [step]);

    const init = async () => {
        const getPerson = async (): Promise<void> => {
            const person = await backend.getPerson(personId);
            let e = {} as Entry;
            copyValuesFromPersonToEntry(person, e);
            order.items = [
                { entry: e, numberOfProducts: 1 } as OrderItem
            ]
            order.contact = {
                firstName: person.firstName,
                lastName: person.lastName,
                address: person.address,
                postalcode: person.postCode,
                city: person.city,
                country: person.country,
                email: person.email,
                phone: person.mobileNumber,
                loggedInUserId: person.id
            } as OrderContact;
            order.locale = person.nationality == "SWE" || person.nationality == "NOR" ? "se" : "en";

            if (!!defaults) {
                order.items[0].entry.productId = defaults.raceProduct.id;
                order.items[0].productId = defaults.raceProduct.id;
                order.items[0].entry.variantId = defaults.productVariantId;
                order.items[0].entry.priceGroupId = defaults.priceGroup;
                order.items[0].productVariantId = defaults.productVariantId;
                order.items[0].productVariantPriceGroupId = defaults.priceGroup;
            }

            setOrder(order);

            if (!!defaults) {
                setHasOrderItems(true);
                updatePrice();
            }
        };

        const getPos = async (): Promise<void> => {
            const pointOfSale = await backend.getKortterminalVlPointOfSale();
            setKortterminalPos(pointOfSale);
        };

        await Promise.all([getPerson(), getPos()]);
        initialized.current = true;
    };

    const validateEntryForRace = async (): Promise<void> => {
        setEntryValidationErrors(null);

        const validationResult = await backend.validateEntryForRace(order.items[0].entry, raceProduct?.id, variant);
        if (validationResult) {
            const translationMap = new Map<string, string>([["duplicate_entry", "Personen är redan anmäld till detta lopp"]]);
            setEntryValidationErrors(validationResult.map(x => {
                return translationMap.get(x.code)
            }));
        }
    };

    const updatePrice = async (): Promise<void> => {
        if (!race || !variant) {
            setPrice(0);
            return;
        }

        setEstimating(true);

        const res = await backend.adminEstimateOrder(order);
        setPrice(res.totalPrice);

        if (!res.order.items || res.order.items.length === 0) {
            setHasOrderItems(false);
        } else {
            order.items = res.order.items;
            setHasOrderItems(true);
        }

        order.discountCodes = res.order.discountCodes;
        setEstimating(false);
        setOrder({ ...order });
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const entry = order.items[0].entry;
        order.items[0].entry = {
            ...entry,
            [e.target.name]: e.target.value
        };

        if (e.target.name === "startgroup" && e.target.value) {
            setHasStartGroupRequiredError(false);
        }

        setOrder({ ...order });
    };

    const handleStartGroupSelect = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const entry = order.items[0].entry;
        entry.startgroup = e.target.value;

        setHasStartGroupRequiredError(false);
        setOrder({ ...order });
    };

    const handleStartGroupClear = (): void => {
        const entry = order.items[0].entry;
        entry.startgroup = null;

        setOrder({ ...order });
    };

    const handlePaymentTypeChecked = (paymentType: PaymentType) => {
        if (paymentType === "account") {
            setPointOfSale("");
        } else if (paymentType === "card") {
            setPointOfSale(kortterminalPos.id);
        } else if (paymentType === "swish") {
            setPointOfSale("");
        }

        setPaymentType(paymentType);
    };

    const getCreateOrder = async () => {
        setIsCreatingOrder(true);
        setCreateOrderStatus("Skapar order");

        const toSave = order;
        if (paymentType === "link") {
            // Säkerställer att ingen annan prisgrupp kommer att välja på checkout-sidan. Vald prisgrupp i kontrollen gäller.
            toSave.locked = true;
        }

        const res = await backend.adminCreateOrder(toSave);

        if (res.totalSum <= 0) {
            if (restrictedProductType && restrictedProductType === "acred") {
                // Sätter hårt om till "PENDING" då det ska vara initial status för kontrollenskapade ackrediteringar
                const entry = res.items[0].entry;
                entry.status = "PENDING";
                await backend.saveEntry(entry);
            }

            onSave();
            return;
        }

        return res;
    };

    const createEntry = async (): Promise<void> => {
        try {
            const createdOrder = await getCreateOrder();

            if (!createdOrder) {
                return;
            }

            let work: Promise<boolean>;
            if (paymentType == "account" || paymentType == "card") {
                setCreateOrderStatus("Registrerar betalning");
                work = backend.addOrderPayment({
                    orderId: createdOrder.id,
                    paymentType: "PAYMENT",
                    amount: createdOrder.totalSum,
                    pointOfSaleId: pointOfSale,
                    description: paymentComment
                });
            } else if (paymentType == "link") {
                setCreateOrderStatus("Skickar länk till betalning");
                work = backend.sendOrderPaymentRequest(createdOrder.id);
            }

            if (!work) {
                return; // Can this happen???
            }

            await work;

            setCreateOrderStatus("Klart!");
            onSave();
        } catch (err) {
            alert(err);
        } finally {
            setIsCreatingOrder(false);
        }
    };

    const renderCardStep = () => {
        return (
            <Grid item xs={12}>
              {/*  <CardPaymentStep
                    price={price}
                    onComplete={(comment) => {
                        setPaymentComment(comment);
                        createEntry();
                    }}
                />*/}
                <StripePaymentStep  
                    initializePaymentDisabled={isCreatingOrder}
                    onRequestCreateOrder={async () => {
                        return await getCreateOrder();
                    }}
                    onProgress={(message) => {
                        setCreateOrderStatus(message);
                    }}
                    onComplete={async () => {
                        setIsCreatingOrder(false);
                        await awaiter(3000);
                        onSave();
                    }}
                    onAbort={() => {
                        setIsCreatingOrder(false);
                    }}/>
            </Grid>
        );
    };

    const renderSwishStep = () => {
        return (
            <Grid item xs={12}>
                <SwishPaymentStep
                    phone={order.contact.phone}
                    initializePaymentDisabled={isCreatingOrder}
                    onPhoneChange={(value) => {
                        order.contact.phone = value;
                        setOrder({ ...order });
                    }}
                    onRequestCreateOrder={async () => {
                        return await getCreateOrder();
                    }}
                    onProgress={(message) => {
                        setCreateOrderStatus(message);
                    }}
                    onComplete={async () => {
                        setIsCreatingOrder(false);
                        await awaiter(3000);
                        onSave();
                    }}
                    onAbort={() => {
                        setIsCreatingOrder(false);
                    }}
                />
            </Grid>
        );
    };

    const renderOrderStep = () => {
        return <>
            <Grid container spacing={2}>
                {price == 0 &&
                    <Grid item xs={12}
                        style={{ background: theme.palette.primary.dark, color: theme.palette.primary.main }}>
                        Ingen betalning behövs
                    </Grid>
                }

                {price > 0 && <>
                    <Grid item xs={12}
                        style={{ background: theme.palette.primary.dark, color: theme.palette.primary.main }}>
                        Betalning
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel label="Skicka betallänk"
                            control={
                                <Checkbox
                                    checked={paymentType == "link"}
                                    onChange={(ev) => {
                                        if (ev.target.checked) {
                                            handlePaymentTypeChecked("link");
                                        }
                                    }}
                                />
                            }
                        />
                        <FormControlLabel label="Boka mot konto"
                            control={
                                <Checkbox
                                    checked={paymentType == "account"}
                                    onChange={(ev) => {
                                        if (ev.target.checked) {
                                            handlePaymentTypeChecked("account");
                                        }
                                    }}
                                />
                            }
                        />
                        <FormControlLabel label="Kortbetalning"
                            control={
                                <Checkbox
                                    checked={paymentType == "card"}
                                    onChange={(ev) => {
                                        if (ev.target.checked) {
                                            handlePaymentTypeChecked("card");
                                        }
                                    }}
                                />
                            }
                        />
                        <FormControlLabel label="Swish"
                            control={
                                <Checkbox
                                    checked={paymentType == "swish"}
                                    onChange={(ev) => {
                                        if (ev.target.checked) {
                                            handlePaymentTypeChecked("swish");
                                        }
                                    }}
                                />
                            }
                        />
                    </Grid>
                    {paymentType == "link" && <>
                        <Grid item xs={12}>
                            <TextField label="E-post"
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                value={order.contact.email}
                                onChange={(ev) => {
                                    order.contact.email = ev.target.value;
                                    setOrder({ ...order });
                                }}
                            />
                        </Grid>
                    </>
                    }
                    {paymentType == "account" && <>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel shrink>POS</InputLabel>
                                <SelectPointOfSale
                                    onChange={setPointOfSale}
                                />
                            </FormControl>

                        </Grid>
                        <Grid item xs={6}>
                            <TextField label="Kommentar"
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                value={paymentComment}
                                onChange={(ev) => {
                                    setPaymentComment(ev.target.value);
                                }}
                            />
                        </Grid>
                    </>}

                    {paymentType == "card" &&
                        renderCardStep()
                    }

                    {paymentType == "swish" &&
                        renderSwishStep()
                    }

                    {isCreatingOrder && <>
                        <Grid item xs={12}
                            style={{ background: theme.palette.primary.dark, color: theme.palette.primary.main }}>
                            <CircularProgress />
                            {createOrderStatus}
                        </Grid>
                    </>}
                </>}
            </Grid>
        </>;
    };

    const entry = order && order.items && order.items[0].entry ? order.items[0].entry : null;
    const setEntry = (entry: Entry): void => {
        order.items[0].entry = entry;
        setOrder({ ...order });
    };

    const addDiscountCode = async (): Promise<void> => {
        const validationResult = await backend.validateProductDiscountCode(code, race, variant);

        const result = isValidCode(validationResult);
        if (!result) {
            showAlert("Felaktig kod", "Den angivna koden är inte giltig.");
            return;
        }

        if (result === "ORDER") {
            if (!order.discountCodes) {
                order.discountCodes = [];
            }

            order.discountCodes.push({ code: code, description: "", value: null });
        } else {
            if (!!order.items[0].code) {
                console.log(order.items[0].code);
                showAlert("En anmälningskod är redan tillagd", "Ta bort den gamla anmälningskoden och försök igen.");
                return;
            }

            order.items[0].code = code;
            order.items[0].entry.usedEntryCode = code;
        }

        setOrder({ ...order });
        setCode("");
        updatePrice();
    };

    const renderEntryStep = () => {
        const selectedVariant = raceProduct?.variants?.find(x => x.Id === variant);
        const productTypes = restrictedProductType ? [restrictedProductType] : raceDataProductTypes;

        return <Grid container spacing={2}>
            <Grid item xs={6}>
                <FormControl fullWidth>
                    <InputLabel shrink>Lopp</InputLabel>
                    <ProductList
                        initialValue={defaults ? defaults.raceProduct.id : ""}
                        showArchived={false}
                        productTypes={productTypes}
                        onChange={(evt: ProductListChangeEvent) => {
                            setRace(evt.products[0]?.id);
                        }}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={6}>
                <FormControl fullWidth>
                    <InputLabel shrink>Variant</InputLabel>
                    <Select
                        value={variant ?? ""}
                        onChange={(ev) => {
                            setVariant(ev.target.value as string);
                        }}
                    >
                        {raceProduct && raceProduct.variants.map((v) => {
                            return <MenuItem key={v.Id} value={v.Id}>{v.Name}</MenuItem>
                        })}
                    </Select>
                </FormControl>
            </Grid>

            {variant && !hasOrderItems && !estimating && <>
                <Grid item xs={12}>
                    <Alert variant="filled" severity="warning">
                        <Typography variant="body2">
                            Denna kombination av produkt/variant är inte giltig. Se över konfigurationen för loppet.
                        </Typography>
                        <Typography variant="body2">
                            Det kan möjligen bero på att det saknas giltig prisgrupp för den valda varianten.
                        </Typography>
                    </Alert>
                </Grid>
            </>}

            {variant && !estimating && entryValidationErrors && <>
                <Grid item xs={12}>
                    {entryValidationErrors.map(x => {
                        return (
                            <Alert key={x} variant="filled" severity="warning">
                                <Typography variant="body2">
                                    {x}
                                </Typography>
                            </Alert>
                        );
                    })}
                </Grid>
            </>}

            {initialized.current && variant && hasOrderItems && <>
                <Grid item xs={12}
                    style={{ background: theme.palette.primary.dark, color: theme.palette.primary.main }}
                >
                    Personuppgifter
                </Grid>
                <Grid item xs={6}>
                    <ReadonlyTextField
                        value={entry.firstName}
                        label="Förnamn"
                    />
                </Grid>
                <Grid item xs={6}>
                    <ReadonlyTextField
                        value={entry.lastName}
                        label="Efternamn"
                    />
                </Grid>
                <Grid item xs={6}>
                    <ReadonlyTextField
                        value={entry.address}
                        label="Address"
                    />
                </Grid>
                <Grid item xs={3}>
                    <ReadonlyTextField
                        value={entry.postalCode}
                        label="postnummer"
                    />
                </Grid>
                <Grid item xs={3}>
                    <ReadonlyTextField
                        value={entry.city}
                        label="Ort"
                    />
                </Grid>
                <Grid item xs={6}>
                    <ReadonlyTextField
                        value={entry.email}
                        label="E-post"
                    />
                </Grid>
                <Grid item xs={6}>
                    <ReadonlyTextField
                        value={entry.phone}
                        label="Tel."
                    />
                </Grid>
                <Grid item xs={6}>
                    <ReadonlyTextField
                        value={getDateOfBirthAsString(entry)}
                        label="Födelsedatum"
                    />
                </Grid>
                <Grid item xs={6}>
                    <ReadonlyTextField
                        value={entry.nationality}
                        label="Nationalitet"
                    />
                </Grid>
                <AddEntryCustomFields
                    entry={entry}
                    raceProduct={raceProduct}
                    variant={variant}
                    updateEntry={(entry) => setEntry(entry)}
                />
                <Grid item xs={12}
                    style={{ background: theme.palette.primary.dark, color: theme.palette.primary.main }}
                >
                    Loppspecifika uppgifter
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        name="clubName"
                        InputLabelProps={{ shrink: true }}
                        value={entry.clubName || ""}
                        label="Klubb"
                        fullWidth
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        name="team"
                        label="Team"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        value={entry.team || ""}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        name="prePackingGroup"
                        label="Packningsgrupp"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        value={entry.prePackingGroup || ""}
                        onChange={handleChange} />
                </Grid>
                <Grid item xs={3}>
                    {raceProduct && <FormControl fullWidth error={hasStartGroupRequiredError}>
                        <InputLabel shrink>Startgrupp</InputLabel>
                        <StartGroupSelector
                            productIds={raceProduct.id}
                            initialValue={entry.startgroup || ""}
                            clearable
                            onChange={handleStartGroupSelect}
                            onClear={handleStartGroupClear}
                            valueFormatter={(gr) => {
                                const remainingSellable = gr.numberOfSellablePlaces - gr.numberOfOccupiedPlaces - gr.numberOfReservedPlaces;
                                const remainingTotal = gr.maxParticipants - gr.numberOfOccupiedPlaces - gr.numberOfReservedPlaces; // Skippa efter dialog
                                return `${gr.name}, ${vasaloppetTimeGetterFormatter(gr.startTime)}, ${remainingTotal} lediga (${remainingSellable} publikt lediga)`;
                            }}
                        />
                        {hasStartGroupRequiredError && <FormHelperText>Startledstilldelning är aktiv. Vänligen välj en startgrupp.</FormHelperText>}
                    </FormControl>}
                </Grid>
                <Grid item xs={3}>
                    {selectedVariant && <FormControl fullWidth>
                        <InputLabel shrink>Prisgrupp</InputLabel>
                        <Select
                            native
                            value={priceGroup ?? ""}
                            onChange={(ev) => {
                                setPriceGroup(ev.target.value as string);
                            }}
                        >
                            <option key="_" value=""></option>
                            {selectedVariant.priceGroups.map((pg) => {
                                return <option key={pg.id} value={pg.id}>{pg.Name}</option>
                            })}
                        </Select>
                    </FormControl>}
                </Grid>

                <AddEntryAddons
                    entry={entry}
                    raceProduct={raceProduct}
                    updateEntry={(entry) => {
                        setEntry(entry);
                        updatePrice();
                    }}
                />

                <Grid item xs={12}
                    style={{ background: theme.palette.primary.dark, color: theme.palette.primary.main }}
                >
                    Rabattkoder
                </Grid>
                {order && order.discountCodes && order.discountCodes.map((discountCode, idx) => {
                    return <Fragment key={idx}>
                        <Grid item xs={12}>Orderkoder</Grid>
                        <Grid item xs={8}>
                            <Typography variant="body2">
                                {discountCode.code}{discountCode.description ? (" - " + discountCode.description) : ""}{discountCode.value ? (" (" + discountCode.value + "kr)") : ""}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Button
                                onClick={() => {
                                    order.discountCodes.splice(idx, 1);
                                    setOrder({ ...order });
                                    updatePrice();
                                }}
                            >
                                Ta bort
                            </Button>
                        </Grid>
                    </Fragment>;
                })}
                {order && order.items && order.items.length === 1 && order.items[0].code &&
                    <Fragment>
                        <Grid item xs={12}>Anmälningskod</Grid>
                        <Grid item xs={8}>
                            <Typography variant="body2">
                                {order.items[0].code}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Button
                                onClick={() => {
                                    order.items[0].code = null;
                                    order.items[0].entry.usedEntryCode = null;
                                    setOrder({ ...order });
                                    updatePrice();
                                }}
                            >
                                Ta bort
                            </Button>
                        </Grid>
                    </Fragment>
                }
                <Grid item xs={8}>
                    <TextField
                        fullWidth
                        value={code}
                        onChange={(ev) => {
                            setCode(ev.target.value);
                        }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Button color="secondary" onClick={addDiscountCode}>Lägg till</Button>
                </Grid>
            </>}
        </Grid>
    };

    const nextStepFn = (): string | null => {
        switch (step) {
            case "entry":
                return "order";
            default:
                return null;
        }
    };

    const prevStepFn = (): string | null => {
        switch (step) {
            case "entry":
                return null;
            case "order":
                return "entry";
            case "summary":
                return "order";
            default:
                return null;
        }
    };

    const prevStep = prevStepFn();
    const nextStep = nextStepFn();

    const isEntryValid = (): boolean => {
        if (!raceProduct.startGroupAllocationActive) {
            setHasStartGroupRequiredError(false);
            return true;
        }

        const isTruthy = !!(order.items && order.items.length > 0 && order.items[0].entry?.startgroup);
        setHasStartGroupRequiredError(!isTruthy);

        return isTruthy;
    };

    const handleNextStepRequest = (next: string) => {
        if (next === "order" && !isEntryValid()) {
            return;
        }

        setStep(next);
    };

    const showCreateEntryBtn = () => !["card", "swish"].some(x => x === paymentType);

    const renderEntryForm = () => (<>
        <Dialog
            disableEscapeKeyDown
            fullWidth={true}
            maxWidth="lg"
            aria-labelledby="add-relay-member-title"
            open={true}>
            <DialogTitle id="add-relay-member-title">
                Lägg till anmälan
            </DialogTitle>
            <DialogContent dividers style={{ minHeight: 300 }}>
                {step == "entry" && renderEntryStep()}
                {step == "order" && renderOrderStep()}
            </DialogContent>
            <DialogActions>
                <Box style={{ display: "flex", lineHeight: "37px", width: "100%" }}>
                    <Box style={{ flex: 1 }}></Box>
                    <Box style={{ width: 200 }}>
                        Totalt pris: {estimating ?
                            <CircularProgress color="secondary" />
                            : <b>{price} kr</b>
                        }
                    </Box>
                    <Box style={{ display: "flex", flex: 1, gap: 8, justifyContent: "flex-end" }}>
                        {prevStep &&
                            <Button
                                variant="contained"
                                onClick={() => setStep(prevStep)}
                                color="secondary">Föregående</Button>
                        }
                        {nextStep && variant && hasOrderItems &&
                            <Button
                                variant="contained"
                                disabled={estimating}
                                onClick={() => handleNextStepRequest(nextStep)}
                                color="secondary">Nästa</Button>
                        }
                        {step == "order" && showCreateEntryBtn() &&
                            <Button
                                variant="contained"
                                disabled={estimating || isCreatingOrder}
                                onClick={() => { createEntry() }}
                                color="secondary">Skapa anmälan</Button>
                        }

                        <Button
                            variant="contained"
                            disabled={estimating || isCreatingOrder}
                            onClick={() => onAbort()}
                            color="secondary">Avbryt</Button>
                    </Box>
                </Box>
            </DialogActions>
        </Dialog>
    </>);

    return renderEntryForm();
};

export default withAlert(AddEntryDialog);
