import { Subset } from "../typings/GlobalExtensions";
import { AccountingInfo } from "./AccountingInfo";
import { Departure } from "./Departure";
import { Event } from "./Event";
import { ProductGiftCardItem } from "./ProductGiftCardItem";
import { ProductVariant } from "./ProductVariant";
import { StartGroup } from "./StartGroup";

const ProductTypes = ["race", "busticket", "skipass", "skiwax", "giftcard", "insurance", "diploma", "massage", "event", "acred", "other"] as const;
export type ProductType = typeof ProductTypes[number];
export type RaceProductType = Subset<ProductType, "race" | "event" | "acred">;
export const raceDataProductTypes: RaceProductType[] = ["race", "event", "acred"];

export class Product {
    name: string;
    name_en: string;
    publicId: string;
    description: string;
    description_en: string;
    type: ProductType;
    active: boolean;
    id: string;
    departures?: Departure[];
    variants?: ProductVariant[];
    startGroupAllocationActive: boolean;
    bibAllocationActive: boolean;
    veteranUseOwnNumbers: boolean;
    startGroups?: StartGroup[];
    accountingInfo?: AccountingInfo;
    PaymentRefPrefix: string;
    static createNew(): Product {
        return {
            name: '', description: '', name_en: '', startGroupAllocationActive: false, description_en: '', type: 'other', id: '', publicId: '',
            variants: null, active: false, PaymentRefPrefix: null, EventId: null, IsArchived: false, symplifyJourneyForTicket: null, symplifyJourneyForTicketEN: null,
            addOnProducts: null, accountingInfo: null, departures: null, maxParticipants: 99999, startGroups: null,
            textAcceptTerms: null, textAcceptGDPR: null, linkAcceptTerms: null, mandatoryTerms: false, linkAcceptGDPR: null, labelLinkAcceptGDPR: null, labelLinkAcceptTerms: null, physicalProduct: false, bibAllocationActive: false, veteranUseOwnNumbers: false,
            requiresSex: false, requiresDateOfBirth: false, requiresNationality: false, requiresAddress: false, requiresCountry: false, personalBib: false, openForTeamTags: false, canBePurchasedAtMyPages: false, canBePurchasedAtB2bPortal: false
        };
    }
    EventId: string;
    Event?: Event;
    IsArchived: boolean;
    maxParticipants?: number;
    symplifyJourneyForTicket: string;
    symplifyJourneyForTicketEN: string;
    addOnProducts: string[];

    textAcceptTerms: string;
    linkAcceptTerms: string;
    labelLinkAcceptTerms: string
    mandatoryTerms: boolean;

    textAcceptGDPR: string;
    linkAcceptGDPR: string;
    labelLinkAcceptGDPR: string;

    productRequiresSocialSecurityNumber?: boolean;
    raceStartCertificateAvailable?: boolean;
    raceEntryOpen?: boolean;
    preRaceEntryOpen?: boolean;
    raceEntriesCanBeChanged?: boolean;
    participantCanChangeStartGroup?: boolean;

    requiresSex: boolean;
    requiresDateOfBirth: boolean;
    requiresNationality: boolean;
    requiresAddress: boolean;
    requiresCountry: boolean;

    giftCardItems?: ProductGiftCardItem[];
    physicalProduct: boolean;
    personalBib: boolean;
    openForTeamTags: boolean;
    canBePurchasedAtMyPages: boolean;
    canBePurchasedAtB2bPortal: boolean;
}