import * as yup from "yup";

const mandatoryMessage = "Obligatoriskt";

export const editPickupItemValidationSchema = yup
    .object()
    .shape(
        {
            "publicId": yup
                .string()
                .required(mandatoryMessage),
            "status": yup
                .string()
                .required(mandatoryMessage)
        }
    );
