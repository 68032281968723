import { FormikErrors, FormikProps, FormikTouched } from "formik";
import { PickupItem } from "../../../model/PickupItem";
import React from "react";
import { Box, Chip, FormControl, InputLabel, TextField, Typography } from "@material-ui/core";
import { PickupItemLocation } from "../../../model/PickupItemLocation";

interface IProps extends FormikProps<Pick<PickupItem, "message">> {
    location: PickupItemLocation;
    disabled?: boolean;
    label?: string;
    errors: FormikErrors<any>;
    touched: FormikTouched<any>;
    values: any;
    handleBlur: {
        (e: React.FocusEvent<any>): void;
        <T = string | any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
    };
    handleChange: {
        (e: React.ChangeEvent<any>): void;
        <T = string | React.ChangeEvent<any>>(field: T): T extends React.ChangeEvent<any> ? void : (e: string | React.ChangeEvent<any>) => void;
    };
    valueGetter?: () => any;
    onChange?: (handler: any) => (e: any) => void;
}

class FormPickupLocationMessageField extends React.Component<IProps> {
    private readonly propName = "message";

    render(): JSX.Element {
        const { disabled, label, values, touched, errors, handleChange, handleBlur, valueGetter, onChange } = this.props;

        const errorFn = () => {
            return Boolean(touched[this.propName] && errors[this.propName]);
        };

        const helperFn = () => {
            return touched[this.propName] && errors[this.propName];
        };

        const changeImpl = onChange ? onChange(handleChange) : handleChange;
        const boxStyle: React.CSSProperties = { display: "flex", marginTop: 20, flexDirection: "column", gap: 5 };

        return (
            <FormControl disabled={disabled} fullWidth>
                {label && <InputLabel shrink>{label}</InputLabel>}
                <Box style={boxStyle}>
                    {this.renderChips()}
                    <TextField
                        id={this.propName}
                        name={this.propName}
                        disabled={disabled}
                        style={{ flex: 1, margin: '0 3px' }}
                        minRows={3}
                        maxRows={5}
                        multiline={true}
                        value={valueGetter ? valueGetter() : values[this.propName] ?? ""}
                        fullWidth
                        onChange={changeImpl}
                        onBlur={handleBlur}
                        error={errorFn()}
                        helperText={helperFn()}
                    >
                    </TextField>
                </Box>

            </FormControl>
        );
    }

    private handleChipClick = (templateText: string) => {
        const { values, setFieldValue } = this.props;

        const currentValue = values[this.propName] ?? "";
        let prefix = "";
        if (currentValue) {
            prefix = "\n";
        }

        const nextValue = currentValue + prefix + templateText;
        setFieldValue(this.propName, nextValue);
    };

    private renderChips = () => {
        const { location } = this.props;

        if (!location?.messageTemplate) {
            return null;
        }

        const parts = location.messageTemplate.split("\n");

        const outerBoxStyle = { display: "flex", gap: 5 };
        const chipBoxStyle = { display: "flex", flexFlow: "row wrap", gap: 5 };

        return (
            <Box style={outerBoxStyle}>
                <Box style={{ lineHeight: "32px" }}>
                    <Typography variant="caption">Snabbknappar</Typography>
                </Box>
                <Box style={chipBoxStyle}>
                    {parts.map((x, idx) => {
                        return (
                            <Chip key={idx} label={x} color="secondary"
                                onClick={() => {
                                    this.handleChipClick(x);
                                }}
                            >
                            </Chip>
                        )
                    })}
                </Box>
            </Box>
        );
    };
}

export default FormPickupLocationMessageField;