import React, { useContext, useEffect, useRef } from "react";
import { Box, Button, Grid, TextField, Typography } from "@material-ui/core";
import { useState } from "react";
import { Order } from "../../../model/Order";
import { ApiBackend } from "../../../providers/apibackend";
import { Alert } from "@material-ui/lab";
import ExpoContext from "../../../contexts/ExpoContext";

interface IProps {
    initializePaymentDisabled: boolean;
    onComplete: () => void;
    onAbort: () => void;

    onRequestCreateOrder?: () => Promise<Order>;
    onProgress?: (message: string) => void;
}

const awaiter = (ms: number): Promise<void> => {
    return new Promise(resolve => {
        setTimeout(() => {
            resolve();
        }, ms);
    });
};


const StripePaymentStep = (props: IProps) => {

    const expoContext = useContext(ExpoContext);
    const [stripeStep, setStripeStep] = useState<"1" | "2" | "3">("1");
    const [stripeError, setStripeError] = useState(null as string);
    const [stripeCancelled, setStripeCancelled] = useState(false);

    const stateRef = useRef<boolean>();
    stateRef.current = stripeCancelled;
    const backend = new ApiBackend();

    useEffect(() => {
        if (stripeCancelled) {
            setStripeStep("1");
            setStripeError(null);
            props.onAbort();
        }

    }, [stripeCancelled]);

    const initStripe = async (orderId: string): Promise<boolean> => {
        setStripeCancelled(false);
        setStripeError(null);
        reportProgress("Initierar kort-betalning");

        const res = await backend.initializeStripePayment(orderId, expoContext.userStripeTerminal.id, expoContext?.userPosSettings?.Id ?? "");
        if (!res) {
            throw ("Kunde inte initiera kort-betalning");
        }

        setStripeStep("2");
        reportProgress("Stripe-betalning initierad, väntar på kund");

        const exitStatuses = ["PAID", "DECLINED", "ERROR"] as const;
        type StatusType = typeof exitStatuses[number];
        const work = async (): Promise<StatusType> => {
            let result = await backend.checkStripePaymentStatus(res);
            while (!stateRef.current && !exitStatuses.some(x => x === result) && !stripeCancelled) {
                await awaiter(500);
                result = await backend.checkStripePaymentStatus(res);
            }

            return result as unknown as StatusType;
        };

        const result = await work();

        if (stateRef.current) {
            return false;
        }

        if (result === "PAID") {
            return true;
        }

        if (result === "DECLINED") {
            throw ("Betalning avbruten av användaren");
        }

        throw ("Ett fel uppstod vid betalning");
    };

    const handleInitialize = async () => {
        try {
            const order = await props.onRequestCreateOrder();
            const orderId = order.id;

            const res = await initStripe(orderId);
            if (res)
            {
                props.onComplete();
            }
            else 
            {
                props.onAbort();
            }
            
        } catch (err) {
            setStripeError(err);
            props.onAbort();
        }
    };

    const reportProgress = (message: string) => {
        if (props.onProgress) {
            props.onProgress(message);
        }
    };

    const render = () => {
        let content: JSX.Element;

        switch (stripeStep) {
            case "1":
                content = (<>
                    <Typography variant="h6">1(3) - Kortbetalning [{expoContext.userStripeTerminal.name}]</Typography>
                    {stripeError &&
                        <Alert severity="error">{stripeError}</Alert>
                    }
                    <Button
                        variant="contained"
                        color="secondary"
                        fullWidth
                        disabled={props.initializePaymentDisabled}
                        onClick={handleInitialize}
                    >
                        Initiera betalning
                    </Button>
                </>);
                break;
            case "2":
                content = (<>
                    <Typography variant="h6">2(3) - Betalning initierad, Slutför betalningen på kortterminal {expoContext.userStripeTerminal.name} </Typography>
                    {stripeError &&
                        <Alert severity="error">{stripeError}</Alert>
                    }
                    <Button
                        variant="contained"
                        color="secondary"
                        fullWidth
                        disabled={stripeCancelled}
                        onClick={async () => {
                            let back = new ApiBackend();
                            await back.cancelStripePayment(expoContext.userStripeTerminal.id);
                            setStripeCancelled(true);
                        }}
                    >
                        Avbryt
                    </Button>
                </>);
                break;
            case "3":
                content = (<>
                    <Typography variant="h6">3(3) - Stripebetalning slutförd</Typography>
                    <Alert>Klart!</Alert>
                </>);
                break;
        }
        return (
            <Grid item xs={12}>
                <Box display="flex" alignItems="center" justifyContent="center">
                    <Box display="flex" alignItems="center" justifyContent="space-between" flexDirection="column" style={{ gap: 16, width: "80%" }}>
                        {content}
                    </Box>
                </Box>
            </Grid>
        );
    };

    return render();
};

export default StripePaymentStep
