import React, { useState } from "react";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import { ProductType } from "../../../model/Product";
import useDidMountEffect from "../../Common/UseDidMountEffect";
import { ProductSettings, getInitialValue } from "./utils";
import VasaloppetFieldset from "../../Common/VasaloppetFieldset";

interface IProps {
    productType: ProductType;
    defaultValue: ProductSettings;
    onChange: (nextValue: ProductSettings) => void;
}

const EditProductSettings = ({ productType, defaultValue, onChange }: IProps) => {
    const [value, setValue] = useState(getInitialValue(defaultValue));

    useDidMountEffect(() => {
        // When productType changes -> Reset all checkboxes to default state
        const nextValue: ProductSettings = {
            productRequiresSocialSecurityNumber: false,
            preRaceEntryOpen: false,
            raceEntryOpen: false,
            raceEntriesCanBeChanged: false,
            raceStartCertificateAvailable: false,
            physicalProduct: false,
            participantCanChangeStartGroup: false,
            requiresSex: false,
            requiresDateOfBirth: false,
            requiresNationality: false,
            requiresAddress: false,
            requiresCountry: false,
            personalBib: false,
            openForTeamTags: false,
            canBePurchasedAtMyPages: false,
            canBePurchasedAtB2bPortal: false
        };

        setValue(nextValue);
        onChange(nextValue);
    }, [productType]);

    const handleChange = (prop: keyof ProductSettings) => (ev: React.ChangeEvent<HTMLInputElement>) => {
        value[prop] = ev.target.checked;
        setValue(value);
        onChange(value);
    };

    const render = () => {
        switch (productType) {
            case "race":
                return <VasaloppetFieldset title="Produktinställningar">
                    <FormControlLabel control={<Checkbox color="primary" checked={value.preRaceEntryOpen} onChange={handleChange("preRaceEntryOpen")} />} label="Föranmälan aktiv" />
                    <FormControlLabel control={<Checkbox color="primary" checked={value.raceEntryOpen} onChange={handleChange("raceEntryOpen")} />} label="Anmälan öppnad" />
                    <FormControlLabel control={<Checkbox color="primary" checked={value.raceEntriesCanBeChanged} onChange={handleChange("raceEntriesCanBeChanged")} />} label="Anmälningar kan ändras" />
                    <FormControlLabel control={<Checkbox color="primary" checked={value.raceStartCertificateAvailable} onChange={handleChange("raceStartCertificateAvailable")} />} label="Startbevis tillgängliga" />
                    <FormControlLabel control={<Checkbox color="primary" checked={value.participantCanChangeStartGroup} onChange={handleChange("participantCanChangeStartGroup")} />} label="Deltagare kan byta startled" />
                    <FormControlLabel control={<Checkbox color="primary" checked={value.personalBib} onChange={handleChange("personalBib")} />} label="Personlig nummerlapp kan köpas" />
                    <FormControlLabel control={<Checkbox color="primary" checked={value.openForTeamTags} onChange={handleChange("openForTeamTags")} />} label="Möjligt att ange lagtaggar på anmälan" />
                    <FormControlLabel control={<Checkbox color="primary" checked={value.canBePurchasedAtMyPages} onChange={handleChange("canBePurchasedAtMyPages")} />} label="Kan köpas på mina sidor" />
                    <FormControlLabel control={<Checkbox color="primary" checked={value.canBePurchasedAtB2bPortal} onChange={handleChange("canBePurchasedAtB2bPortal")} />} label="Kan köpas i b2b-portalen" />
                </VasaloppetFieldset>;
            case "event":
            case "acred":
                return <VasaloppetFieldset title="Produktinställningar">
                    <FormControlLabel control={<Checkbox color="primary" checked={value.raceEntryOpen} onChange={handleChange("raceEntryOpen")} />} label="Anmälan öppnad" />
                    <FormControlLabel control={<Checkbox color="primary" checked={value.raceEntriesCanBeChanged} onChange={handleChange("raceEntriesCanBeChanged")} />} label="Anmälningar kan ändras" />
                    <FormControlLabel control={<Checkbox color="primary" checked={value.requiresSex} onChange={handleChange("requiresSex")} />} label="Kräver kön" />
                    <FormControlLabel control={<Checkbox color="primary" checked={value.requiresDateOfBirth} onChange={handleChange("requiresDateOfBirth")} />} label="Kräver födelsedatum" />
                    <FormControlLabel control={<Checkbox color="primary" checked={value.requiresNationality} onChange={handleChange("requiresNationality")} />} label="Kräver nationalitet" />
                    <FormControlLabel control={<Checkbox color="primary" checked={value.requiresAddress} onChange={handleChange("requiresAddress")} />} label="Kräver gatuadress" />
                    <FormControlLabel control={<Checkbox color="primary" checked={value.requiresCountry} onChange={handleChange("requiresCountry")} />} label="Kräver land" />
                    <FormControlLabel control={<Checkbox color="primary" checked={value.canBePurchasedAtMyPages} onChange={handleChange("canBePurchasedAtMyPages")} />} label="Kan köpas på mina sidor" />
                    <FormControlLabel control={<Checkbox color="primary" checked={value.canBePurchasedAtB2bPortal} onChange={handleChange("canBePurchasedAtB2bPortal")} />} label="Kan köpas i b2b-portalen" />
                </VasaloppetFieldset>;
            default:
                return <VasaloppetFieldset title="Produktinställningar">
                    <FormControlLabel control={<Checkbox color="primary" checked={value.productRequiresSocialSecurityNumber} onChange={handleChange("productRequiresSocialSecurityNumber")} />} label="Kräver personnummer" />
                    <FormControlLabel control={<Checkbox color="primary" checked={value.physicalProduct} onChange={handleChange("physicalProduct")} />} label="Fysisk produkt" />
                    <FormControlLabel control={<Checkbox color="primary" checked={value.canBePurchasedAtMyPages} onChange={handleChange("canBePurchasedAtMyPages")} />} label="Kan köpas på mina sidor" />
                    <FormControlLabel control={<Checkbox color="primary" checked={value.canBePurchasedAtB2bPortal} onChange={handleChange("canBePurchasedAtB2bPortal")} />} label="Kan köpas i b2b-portalen" />
                </VasaloppetFieldset>;
        }
    };

    return render();
};

export default EditProductSettings;
