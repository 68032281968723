import { Card, CardContent, CardHeader, CircularProgress, createStyles, Theme, WithStyles, withStyles } from "@material-ui/core";
import React, { lazy, Suspense, useEffect, useRef, useState } from "react";
import { RouteChildrenProps, withRouter } from "react-router-dom";
import { PickupItemLocation } from "../../model/PickupItemLocation";
import { ApiBackend } from "../../providers/apibackend";
import { LazyExpoView } from "./expo/models";

interface IProps extends RouteChildrenProps<{ id: string }> {
}

const ExpoPickupLocation = (props: IProps & WithStyles) => {
    const [loading, setLoading] = useState(true);
    const [location, setLocation] = useState(null as PickupItemLocation);
    const didMount = useRef<boolean>(false);
    const backend = new ApiBackend();

    // Run on load
    useEffect(() => {
        didMount.current = true;

        const init = async (): Promise<void> => {
            const result = await backend.getPickupItemLocation(props.match.params.id);

            if (didMount.current) {
                setLocation(result);
                setLoading(false);
            }
        };

        init();

        // unmount
        return () => { didMount.current = false };
    }, []);

    const ucFirst = (str: string) => str ? str.charAt(0).toUpperCase() + str.slice(1) : "";

    const onBackToEdit = () => {
        props.history.push(`../manage/${location.id}`);
    };

    const getView = () => {
        if (!location) {
            return null;
        }

        const viewType = location.type.toLowerCase();
        require(`./expo/${viewType}/${ucFirst(viewType)}`);
        const View = lazy(() => import(`./expo/${viewType}/${ucFirst(viewType)}`)) as LazyExpoView;

        return (
            <Suspense fallback={<CircularProgress color="primary" />}>
                <View
                    data={location}
                    onBackToEdit={onBackToEdit}
                />
            </Suspense>
        );
    };

    const render = () => {
        const { classes } = props;
        const cardHeader = (): string => {
            return `${location.type} - ${location.name}`
        };

        return (<>
            {loading &&
                <CircularProgress color="primary" />
            }
            {location &&
                <Card>
                    <CardHeader className={classes.cardHeader} title={cardHeader()} />
                    <CardContent className={classes.form}>
                        {getView()}
                    </CardContent>
                </Card>
            }
        </>);
    }

    return render();
}

const useStyles = ({ palette }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
    root: {

    },
});

export default withRouter(withStyles(useStyles)(ExpoPickupLocation));
