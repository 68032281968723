import { PickupItem, PickupItemStatus } from "../../../model/PickupItem";
import { IBulkPatchPickupItemsRequest, IPatchPickupItemRequest, PatchPickupItemDocumentProps } from "../../../providers/models";
import { orderByCustomValue, SortOrder } from "../../../utilities/sortmodel";

export type PatchablePropertiesWithId = PatchPickupItemDocumentProps & Pick<PickupItem, "id">;

export type PatchRestrictions = {
    status?: PickupItemStatus[];
};

export const getBulkPatchDocument = (ids: string[], item: PatchPickupItemDocumentProps): IBulkPatchPickupItemsRequest => {
    const patchDocument: { propName: keyof PatchPickupItemDocumentProps; value: any; }[] = [];

    for (const prop in item) {
        if (item.hasOwnProperty(prop)) {
            const typedProp = prop as keyof PatchPickupItemDocumentProps;
            const value = item[typedProp];
            patchDocument.push({ propName: typedProp, value: value });
        }
    }

    return {
        itemIds: ids,
        patchDocument: patchDocument
    };
};

export const getPatchDocument = (item: PatchPickupItemDocumentProps): IPatchPickupItemRequest => {
    const patchDocument: { propName: keyof PatchPickupItemDocumentProps; value: any; }[] = [];

    for (const prop in item) {
        if (item.hasOwnProperty(prop)) {
            const typedProp = prop as keyof PatchPickupItemDocumentProps;
            const value = item[typedProp];
            patchDocument.push({ propName: typedProp, value: value });
        }
    }

    return {
        patchDocument: patchDocument
    };
};

export const pickupItemsToPatchPickupItemDocumentProps = (items: PickupItem[]): PatchablePropertiesWithId[] => {
    return items.map(p => {
        return {
            id: p.id,
            status: p.status
        };
    });
};

export const getFilteredPickupItems = (items: PickupItem[], textFilter: string, sortOrder?: SortOrder) => {
    const lowerTextFilter = textFilter.toLowerCase();

    const filteredItems = Array.from(items.values())
        .flat()
        .filter((x) => {
            const publicId = x.publicId?.toLowerCase() ?? "";
            const message = x.message?.toLowerCase() ?? "";
            const metadata: Array<{ key: string; value: string }> = x.metadata ?? [];

            if (publicId.includes(lowerTextFilter) || message.includes(lowerTextFilter)) {
                return true;
            }

            if (lowerTextFilter.startsWith("metadata:")) {
                // Möjliga sökningar:
                // metadata::value
                // metadata:key
                // metadata:key:value

                const parts = lowerTextFilter.split(":");

                // Bred sökning (metadata::value) -> sök alla nycklar och värden
                if (parts.length === 3 && parts[1] === "") {
                    const [, , searchValue] = parts;
                    return metadata.some(
                        (meta) => meta.key.toLowerCase().includes(searchValue) || meta.value.toLowerCase().includes(searchValue)
                    );
                }

                // Exakt nyckel-värde matchning (metadata:key:value)
                if (parts.length === 3) {
                    const [, key, value] = parts;
                    return metadata.some((meta) => meta.key.toLowerCase() === key && meta.value.toLowerCase().includes(value));
                }

                // Sökning endast på nyckel (metadata:key)
                if (parts.length === 2) {
                    const [, searchKey] = parts;
                    return metadata.some((meta) => meta.key.toLowerCase().includes(searchKey));
                }
            }

            return false;
        });

    if (!sortOrder) {
        return filteredItems;
    }

    return orderByCustomValue(filteredItems, (row) => {
        return row.updated ?? row.created;
    }, sortOrder);
};

export const getPickupItemMetadataValueByKey = (item: PickupItem, key: string): string | undefined => {
    return item.metadata?.find(x => x.key === key)?.value;
};
