import { Box, Button, ButtonGroup } from "@material-ui/core";
import React, { useState } from "react";
import { IExpoViewProps } from "../models";
import Engraver from "./Engraver";
import Kontrollen from "./Kontrollen/Kontrollen";

type Mode = "KONTROLLEN" | "ENGRAVER";

const Medaljgravering = (props: IExpoViewProps) => {
    const [mode, setMode] = useState<Mode>("KONTROLLEN");

    const switchMode = () => {
        setMode(mode === "ENGRAVER" ? "KONTROLLEN" : "ENGRAVER");
    };

    const renderView = () => {
        const { data } = props;

        if (mode === "ENGRAVER") {
            return (
                <Engraver data={data} />
            )
        }

        return <Kontrollen data={data} />;
    }

    const render = () => {
        const { data, onBackToEdit } = props;

        const defaultBoxStyle: React.CSSProperties = {
            display: "flex",
            flexDirection: "row",
            gap: "16px",
            marginLeft: "auto",
            marginBottom: 24,
        };
        return (<>
            <Box style={{ marginTop: -8, paddingBottom: 8, display: "flex", flexDirection: "column", width: "100%", overflow: "auto" }}>
                <Box style={defaultBoxStyle}>
                    <ButtonGroup color="secondary">
                        {mode === "KONTROLLEN" && (
                            <Button
                                onClick={onBackToEdit}
                            >
                                Redigera {data.name}
                            </Button>
                        )}
                        <Button
                            onClick={switchMode}
                        >
                            {mode === "KONTROLLEN" ? "Som graverare" : "Som kontrollant"}
                        </Button>
                    </ButtonGroup>
                </Box>
                {renderView()}
            </Box>
        </>);
    };

    return render();
};

export default Medaljgravering;
