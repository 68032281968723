import React, { useRef, useState } from "react";
import { Button, Card, CardActions, CardContent, CardHeader, Grid, IconButton, Theme, Typography, WithStyles, createStyles, withStyles } from "@material-ui/core";
import RefreshIcon from "@material-ui/icons/Refresh";
import { RouteComponentProps, withRouter } from "react-router";
import SearchFilter, { Filters } from "../Common/SearchFilter/SearchFilter";
import { ApiBackend } from "../../providers/apibackend";
import EnhancedTable from "../Common/EnhancedTable/EnhancedTable";
import { IColumnDefinition, ISortModel } from "../Common/EnhancedTable/models";
import { vasaloppetDateTimeGetterFormatter } from "../../utilities/date";
import useDidMountEffect from "../Common/UseDidMountEffect";
import { searchFilterToExportExcelFilter } from "../Common/SearchFilter/utils";
import { IExportExcelRequest } from "../../model/IExportExcelRequest";
import { ArrowForward, OpenInBrowser } from "@material-ui/icons";
import { PickupItemLocation, PickupItemLocations, PickupItemLocationType } from "../../model/PickupItemLocation";
import CreatePickupLocationDialog from "./CreatePickupLocationDialog";

interface ISearchFilter {
    type?: PickupItemLocationType;
    name?: string;
    includeArchived?: boolean
}

type editLocationMode = "in-app" | "new-tab";

const ManagePickups = ({ classes, history }: RouteComponentProps & WithStyles) => {
    const [loading, setLoading] = useState(false);
    const [searchFilter, setSearchFilter] = useState(null as ISearchFilter);
    const [locations, setLocations] = useState(null as PickupItemLocation[]);
    const [sortModel, setSortModel] = useState({ sortBy: "created", sortOrder: "desc" } as ISortModel<PickupItemLocation>);
    const [showCreateNew, setShowCreateNew] = useState(false);

    const busyLoading = useRef<boolean>(false);
    const backend = new ApiBackend();

    const setBusyLoading = (value: boolean): void => {
        busyLoading.current = value;
        setLoading(value);
    };

    useDidMountEffect(() => {
        performSearch();
    }, [searchFilter]);

    const performSearch = async (): Promise<void> => {
        if (busyLoading.current) {
            return;
        }

        setBusyLoading(true);

        try {
            const response = await backend.listPickupLocations(searchFilter);
            setLocations(response?.locations ?? []);
        } finally {
            setBusyLoading(false);
        }
    };

    const handleExport = async () => {
        const filter = searchFilterToExportExcelFilter<ISearchFilter>(searchFilter);

        const request: IExportExcelRequest = {
            entityType: "Ackreditation",
            filter: filter,
            sortProp: sortModel?.sortBy,
            sortDir: sortModel?.sortOrder
        };
        try {
            setLoading(true);
            await backend.exportExcel(request);
        } finally {
            setLoading(false);
        }
    };

    const handleRequestSearchFilterChange = async (searchFilter: ISearchFilter): Promise<void> => {
        setSearchFilter(searchFilter);
    };

    const handleEditLocation = (location: PickupItemLocation, mode: editLocationMode) => (e: React.MouseEvent<unknown>): void => {
        e.stopPropagation();
        switch (mode) {
            case "in-app":
                history.push(`/pickups/manage/${location.id}`);
                break;
            case "new-tab":
                window.open(`/pickups/manage/${location.id}`);
                break;
        }
    };

    const columnDefinitions: IColumnDefinition<PickupItemLocation>[] = [
        {
            renderCell: (row) => {
                return <>
                    <IconButton color="default" size="small"
                        onClick={handleEditLocation(row, "new-tab")}
                    >
                        <OpenInBrowser fontSize="medium" />
                    </IconButton>
                    <IconButton color="default" size="small"
                        onClick={handleEditLocation(row, "in-app")}
                    >
                        <ArrowForward fontSize="medium" />
                    </IconButton>
                </>
            }
        },
        { propName: "type", label: "Typ" },
        { propName: "name", label: "Namn" },

        { propName: "description", label: "Beskrivning" },
        {
            propName: "created",
            label: "Skapad",
            valueFormatter: (row) => vasaloppetDateTimeGetterFormatter(row.created, "---"),
            sortValueGetter: (row) => vasaloppetDateTimeGetterFormatter(row.created, "---")
        },
        {
            propName: "updated",
            label: "Uppdaterad",
            valueFormatter: (row) => vasaloppetDateTimeGetterFormatter(row.updated, "---"),
            sortValueGetter: (row) => vasaloppetDateTimeGetterFormatter(row.updated, "---")
        }
    ];

    const defaultBoxStyle = {
        display: "flex",
        flexDirection: "row",
    };

    const render = () => {

        return (<>
            <SearchFilter<ISearchFilter>
                id="manage-pickup-locations-filter"
                filters={{
                    type: {
                        id: "filter-type",
                        type: "GenericSelect",
                        label: "Typ",
                        size: 4,
                        itemDefinition: {
                            itemValueType: "string",
                            items: PickupItemLocations.map(x => {
                                return { name: x, value: x };
                            })
                        },
                        clearable: true
                    },
                    name: {
                        id: "filter-name",
                        type: "DebouncedText",
                        label: "Namn",
                        size: 4
                    },
                    includeArchived: {
                        id: "filter-includearchived",
                        type: "Checkbox",
                        label: "Inkludera arkiverade",
                        size: 4,
                    }
                }}
                persist={true}
                onInit={(filter: Filters<ISearchFilter>) => {
                    handleRequestSearchFilterChange(filter as unknown as ISearchFilter);
                }}
                onChange={(filter: Filters<ISearchFilter>) => {
                    handleRequestSearchFilterChange(filter as unknown as ISearchFilter);
                }}
            />
            <Grid container className={classes.root} spacing={2}>
                <Grid item xs={12}>
                    <Card>
                        <CardHeader className={classes.cardHeader}
                            title={
                                <>
                                    <Typography variant="h5" style={{ display: "inline" }}>Avhämtningsplatser</Typography>
                                    <RefreshIcon
                                        style={{ display: "inline", verticalAlign: "middle" }}
                                        onClick={performSearch}
                                    />
                                </>
                            }
                        // action={
                        //     <Box {...defaultBoxStyle} style={{ gap: "16px", marginLeft: "auto" }}>
                        //         <div style={{ marginTop: 12, marginRight: 12 }}>
                        //             <GetAppIcon onClick={handleExport} />
                        //         </div>
                        //     </Box>
                        // }
                        />
                        <CardContent>
                            <EnhancedTable<PickupItemLocation>
                                columnDefinitions={columnDefinitions}
                                data={locations ?? []}
                                pageSize={10}
                                paginationMode="client"
                                sortingMode="client"
                                sortModel={sortModel}
                                onSortModelChange={(nextSortModel: ISortModel<PickupItemLocation>) => {
                                    setSortModel(nextSortModel);
                                }}
                                loading={loading}
                                dense
                            />
                        </CardContent>
                        <CardActions>
                            <Button size="small" color="secondary"
                                onClick={() => {
                                    setShowCreateNew(true);
                                }}
                            >
                                Skapa ny
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>
            </Grid>

            {showCreateNew &&
                <CreatePickupLocationDialog
                    onAbort={() => {
                        setShowCreateNew(false);
                    }}
                />
            }
        </>);
    };

    return render();
};

const useStyles = ({ palette, spacing }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
    photo: {
        height: "30px",
        verticalAlign: "middle",
        borderRadius: "10px"
    },
    root: {

    },
    form: {
        "& > *": {
            margin: spacing(1),
            width: "25ch",
        },
        "& label.Mui-focused": {
            color: palette.secondary.main,
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: palette.secondary.main,
        },
    }
});

export default withStyles(useStyles)(withRouter(ManagePickups));
