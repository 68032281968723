import React, { useEffect, useState } from "react";
import { Button, Card, CardContent, CardHeader, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, MenuItem, TextField, WithStyles } from "@material-ui/core";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import ClearIcon from "@material-ui/icons/Clear";
import { ApiBackend } from "../../../providers/apibackend";
import { FormikProps } from "formik";
import { CompanyEntry, Entry, IAdminSaveCompanyEntryRequest } from "../../../model/Entry";
import { B2bCompany, B2bCompanyProduct, B2bCompanyProductExtraField } from "../../../model/B2bCompany";
import withAlert, { IAlertContext } from "../../Common/dialog/Alert";

const EntryCustomProductCustomFieldsCard = (props: FormikProps<Entry> & WithStyles) => {
    const [expanded, setExpanded] = useState<boolean>(true);
    const [open, setOpen] = useState<boolean>(false);
    const [company, setCompany] = useState<B2bCompany>(null);
    const [customProduct, setCustomProduct] = useState<B2bCompanyProduct>(null);
    const [companyEntry, setCompanyEntry] = useState<CompanyEntry>(null);
    const [initialized, setInitialized] = useState<boolean>(false);

    const backend = new ApiBackend();

    useEffect(() => {
        const entry = props.values;

        const init = async () => {
            const companyRes = await backend.getB2bCompany(entry.b2bId);
            setCompany(companyRes);
            const productMatch = companyRes.products?.find(x => x.productId === entry.productId && x.productVariantId === entry.variantId);

            if (!!productMatch) {
                setCustomProduct(productMatch);
                const entryRes = await backend.getB2bCompanyEntry(entry.b2bId, entry.id, productMatch.id);
                setCompanyEntry(entryRes);
            }

            setInitialized(true);
        };

        init();
    }, []);

    const render = () => {
        const { classes } = props;

        if (!initialized || !company || !customProduct || !companyEntry) {
            return null;
        }

        const title = `Extra anmälningsfält för ${company.name}`;

        return (
            <Card>
                <CardHeader
                    title={title}
                    action={<>
                        <Button variant="outlined" color="secondary"
                            onClick={() => {
                                setOpen(true);
                            }}
                        >
                            Ändra svar
                        </Button>
                        <IconButton
                            onClick={() => {
                                setExpanded(!expanded)
                            }}

                        >
                            {expanded ? (
                                <KeyboardArrowUp />
                            ) : (
                                <KeyboardArrowDown />
                            )}
                        </IconButton>
                    </>}
                />
                {expanded && (
                    <CardContent>
                        <Grid container className={classes.root} spacing={2} style={{ width: "100%", gap: "16px" }}>
                            {customProduct.extraFields.map((field, idx) => {
                                return (
                                    <Grid key={idx} item xs={12}>
                                        <CustomField
                                            disabled={true}
                                            field={field}
                                            customValues={companyEntry.customValues}
                                        />
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </CardContent>
                )}
                {open && (
                    <ChangeValuesDialog
                        companyId={company.id}
                        entry={companyEntry}
                        customProduct={customProduct}
                        onAbort={() => {
                            setOpen(false);
                        }}
                        onSave={(nextValue) => {
                            setCompanyEntry(nextValue);
                            setOpen(false);
                        }}
                    />
                )}
            </Card>
        );
    };

    return render();
};

type ChangeValuesDialogProps = {
    companyId: string;
    entry: CompanyEntry;
    customProduct: B2bCompanyProduct;
    onAbort: () => void;
    onSave: (nextValue: CompanyEntry) => void;
}
const ChangeValuesDialog = withAlert((props: ChangeValuesDialogProps & IAlertContext) => {
    const [customValues, setCustomValues] = useState([...props.entry.customValues]);
    const [isSaving, setIsSaving] = useState(false);

    const backend = new ApiBackend();

    const handleSave = async () => {
        const { companyId, entry, customProduct, onSave } = props;

        setIsSaving(true);

        try {
            const saveRequest: IAdminSaveCompanyEntryRequest = {
                companyProductId: customProduct.id,
                productId: customProduct.productId,
                productVariantId: customProduct.productVariantId,
                customFieldValues: customValues
            };

            const nextValue = await backend.saveB2bCompanyEntry(companyId, entry.id, saveRequest);

            if (!nextValue) {
                throw new Error("Something wrong when saving company entry");
            }

            setIsSaving(false);
            onSave(nextValue);
        } catch {
            setIsSaving(false);
            props.showAlert("Fel", "Det gick inte att spara svaren");
        }
    };

    const handleAbort = () => {
        props.onAbort();
    };

    const handleChange = (nextValue: { key: string; value: string; }) => {
        const match = customValues.find(x => x.key === nextValue.key);

        let nextValues: { key: string; value: string; }[] = [];

        if (!match) {
            nextValues = [
                ...customValues,
                nextValue
            ]
        } else {
            nextValues = customValues.map((customValue) => {
                if (customValue.key === nextValue.key) {
                    return {
                        ...customValue,
                        value: nextValue.value,
                    };
                }

                return customValue;
            });
        }

        setCustomValues(nextValues);
    };

    const isPristine = () => {
        return customValues.length === props.entry.customValues.length &&
            JSON.stringify(customValues) === JSON.stringify(props.entry.customValues)
    };

    const render = () => {
        const { customProduct } = props;

        const isSaveDisabled = isPristine() || isSaving;

        return (
            <Dialog
                disableEscapeKeyDown
                fullWidth={true}
                maxWidth="md"
                aria-labelledby="event-selector-button-title"
                open={true}
            >
                <DialogTitle id="event-selector-button-title">
                    Ändra svar
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={4} >
                        {customProduct.extraFields.map((field, idx) => {
                            return (
                                <Grid key={idx} item xs={12}>
                                    <CustomField
                                        disabled={false}
                                        field={field}
                                        customValues={customValues}
                                        onChange={handleChange}
                                    />
                                </Grid>
                            );
                        })}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        type="button"
                        color="secondary"
                        disabled={isSaveDisabled}
                        variant="contained"
                        onClick={handleSave}
                    >
                        Spara
                    </Button>
                    <Button
                        style={{ marginLeft: 10 }}
                        color="secondary"
                        disabled={isSaving}
                        variant="contained"
                        onClick={handleAbort}
                    >
                        Avbryt
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    return render();
});

type CustomFieldProps = {
    disabled: boolean;
    field: B2bCompanyProductExtraField;
    customValues: { key: string; value: string; }[];
    onChange?: (nextValue: { key: string; value: string; }) => void;
}
const CustomField = (props: CustomFieldProps) => {
    const { disabled, field, customValues } = props;
    const formattedValue = customValues.find(x => x.key === field.id)?.value ?? "";

    const commonProps = {
        key: field.id,
        id: field.id,
        name: field.id,
        label: field.name,
        disabled: disabled,
        InputLabelProps: { shrink: true },
        fullWidth: true,
        value: formattedValue || "",
        onChange: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
            setFieldValue(field.id, e.target.value),
    };

    const setFieldValue = (key: string, nextValue: string | null) => {
        const { onChange } = props;
        if (!!onChange) {
            onChange({ key: key, value: nextValue });
        }
    };

    const render = () => {

        if (field.type === "choice") {
            const validValues = JSON.parse(field.validValues) as string[];

            return (
                <TextField
                    {...commonProps}
                    select
                    InputProps={{
                        endAdornment: formattedValue && !disabled ? (
                            <IconButton
                                style={{ padding: 0, marginRight: "1em" }}
                                onClick={() => setFieldValue(field.id, null)}
                            >
                                <ClearIcon />
                            </IconButton>
                        ) : null,
                    }}
                >
                    {validValues.map((x, idx) => (
                        <MenuItem key={idx} value={x}>
                            {x}
                        </MenuItem>
                    ))}
                </TextField>
            );
        }

        return (
            <TextField type="text" {...commonProps} />
        );
    };

    return render();
};

export default EntryCustomProductCustomFieldsCard;
