import { IResponseBase } from "./IResponseBase";
import { PickupItem } from "./PickupItem";

export type PickupItemLocationType = "MEDALJGRAVERING";
export const PickupItemLocations: PickupItemLocationType[] = ["MEDALJGRAVERING"];

export class PickupItemLocation {
    id: string;
    type: PickupItemLocationType;
    name: string;
    description?: string;
    isArchived: boolean;

    created?: Date;
    createdBy?: string;
    updated?: Date;
    updatedBy?: string;

    metadata?: { key: string, value: string }[];
    messageTemplate?: string;
}

export interface ListPickupLocationsResponse {
    locations: PickupItemLocation[];
}

export interface ListPickupItemsResponse {
    items: PickupItem[];
}

export interface IAdminPickupLocationResponse extends IResponseBase {
    location: PickupItemLocation;
}

export interface IAdminPickupItemResponse extends IResponseBase {
    item: PickupItem;
}