import { Box, Grid, MenuItem, WithStyles } from "@material-ui/core";
import { FormikProps } from "formik";
import React from "react";
import { PickupItem, PickupItemStatuses } from "../../../model/PickupItem";
import FormTextField from "../../Common/FormTextField";
import FormSelectField from "../../Common/FormSelectField";
import { PickupItemLocation } from "../../../model/PickupItemLocation";
import FormPickupLocationMessageField from "./FormPickupLocationMessageField";
import FormMetadataField from "../../Common/FormMetadataField";

interface IProps extends FormikProps<PickupItem> {
    location: PickupItemLocation;
}

const CreatPickupItemFormContent = (props: IProps & WithStyles) => {
    const render = (): JSX.Element => {
        const { values, classes, setFieldValue } = props;

        return (
            <Grid container className={classes.root} spacing={2} style={{ width: "100%" }}>
                <Grid item xs={12}>
                    <FormTextField {...props}
                        propName="publicId"
                        label="Public ID"
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormSelectField {...props}
                        propName="status"
                        label="Status"
                        valueGetter={() => values.status ?? ""}
                    >
                        {PickupItemStatuses.map((x, idx) => {
                            return <MenuItem key={idx} value={x}>{x}</MenuItem>
                        })}
                    </FormSelectField>
                </Grid>
                <Grid item xs={12}>
                    <FormMetadataField {...props}
                        label="Metadata"
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormPickupLocationMessageField {...props}
                        label="Meddelande"
                    />
                </Grid>
            </Grid>
        )
    }

    return render();
}

export default CreatPickupItemFormContent;
