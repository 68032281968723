import MenuItem from "@material-ui/core/MenuItem";
import React, { useEffect, useState } from "react";
import { ApiBackend } from "../../providers/apibackend";
import ClearableSelect from "../Common/ClearableSelect";
import { StripeTerminal } from "../../model/StripeTerminal";

class Props {
    initialValue?: string;
    clearable?: boolean;
    terminalSelected: (terminalId?: StripeTerminal) => void;
}

const SelectStripeTerminal = ({ initialValue, clearable, terminalSelected }: Props) => {
    const [terminal, setTerminal] = useState(initialValue ?? "");
    const [terminals, setTerminals] = useState(null as StripeTerminal[]);

    useEffect(() => {
        new ApiBackend().getGeneral("payment/stripe/terminals").then((res: StripeTerminal[]) => {
            res.sort((x, y) => x.name < y.name ? -1 : 1);
            setTerminals(res);
        });
    }, []);

    return (
        <ClearableSelect
            value={terminal}
            clearable={clearable}
            onChange={(ev) => {
                const terminalId = ev.target.value as string;
                const terminal = terminals.find(x => x.id === terminalId);
                setTerminal(terminalId);
                terminalSelected(terminal);
            }}
            onClear={() => {
                setTerminal("");
                terminalSelected(null);
            }}
        >
            {terminals && terminals.map(x => {
                return <MenuItem key={x.id} value={x.id}>{x.name}</MenuItem>
            })}
        </ClearableSelect>
    )
}

export default SelectStripeTerminal;
