import { Box, Button, Card, CardActions, CardContent, CardHeader, TextField, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { PickupItem } from "../../../../model/PickupItem";
import { PickupItemLocation } from "../../../../model/PickupItemLocation";
import { ApiBackend } from "../../../../providers/apibackend";
import { IPatchPickupItemRequest } from "../../../../providers/models";
import { vasaloppetDateTimeGetterFormatter } from "../../../../utilities/date";
import { getFilteredPickupItems } from "../../items/utils";
import ViewPickupItemButton from "../../parts/ViewPickupItemButton";
import { AssignablePickupItemStatus, getCardTitle, getEntryName, getNextStatus, getPreBookedText, getPreviousStatus, isPreBooking, isPrestationsmedalj, statusToNextActionText, statusToText } from "./utils";

type Props = {
	data: PickupItemLocation;
};

const Engraver = (props: Props) => {
	const [items, setItems] = useState<Map<AssignablePickupItemStatus, PickupItem[]> | null>(null);
	const [isLoading, setIsLoading] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	const [textFilter, setTextFilter] = useState("");

	const fetchInterval = 10000;
	const backend = new ApiBackend();

	useEffect(() => {
		fetchData(false);
		const interval = setInterval(() => fetchData(false), fetchInterval);

		return () => clearInterval(interval);
	}, []);

	const fetchData = async (force: boolean) => {
		if (isLoading || isSaving) {
			return;
		}

		const { data } = props;

		try {
			setIsLoading(true);
			const res = await backend.listPickupItems(data.id, { statuses: ["NEW", "IN_PROGRESS", "DONE", "DELIVERED"] });
			if (res && res.items) {
				const itemsMap: Map<AssignablePickupItemStatus, PickupItem[]> = res.items.reduce(
					(entryMap, e) => entryMap.set(e.status, [...(entryMap.get(e.status) || []), e]),
					new Map()
				);
				setItems(itemsMap);
			}
		} finally {
			setIsLoading(false);
		}
	};

	const updateStatusOnItem = async (item: PickupItem, nextStatus: AssignablePickupItemStatus) => {
		const { data } = props;

		try {
			setIsSaving(true);
			const request: IPatchPickupItemRequest = {
				patchDocument: [{ propName: "status", value: nextStatus }],
			};
			const nextItem = await backend.patchPickupItem(data.id, item.id, request);
			if (!!nextItem) {
				await fetchData(true);
			}
		} finally {
			setIsSaving(false);
		}
	};

	const renderItemCardsForStatus = (status: AssignablePickupItemStatus) => {
		if (!items || !items.has(status)) {
			return null;
		}

		const sortOrder = status === "NEW" || status === "IN_PROGRESS" ? "asc" : "desc";

		const statusItems = getFilteredPickupItems(items.get(status), textFilter, sortOrder);

		const boxStyle: React.CSSProperties = {
			display: "flex",
			flexDirection: "column",
			gap: "16px",
		};

		// const prevStatus = getPreviousStatus(status);
		const nextStatus = getNextStatus(status);

		return (
			<Box style={boxStyle}>
				{statusItems.map((x, idx) => {
					const dateToFormat = x.updated ?? x.created;

					return (
						<Card key={x.id}>
							<CardHeader
								title={<Typography variant="h5">{getCardTitle(x)}</Typography>}
								subheader={<Box style={{ display: "flex", gap: 16 }}>
									<Typography variant="caption">
										Senast ändrad: {vasaloppetDateTimeGetterFormatter(dateToFormat)}
									</Typography>
									{isPreBooking(x) &&
										<Typography variant="caption" style={{ fontWeight: "bold", backgroundColor: "rgb(51, 131, 196)", color: "#fff", padding: 2 }}>
											{getPreBookedText(x)}
										</Typography>
									}
									{isPrestationsmedalj(x) &&
										<Typography variant="caption" style={{ fontWeight: "bold", backgroundColor: "rgb(51, 131, 196)", color: "#fff", padding: 2 }}>
											Prestationsmedalj
										</Typography>
									}
								</Box>}
								action={
									<ViewPickupItemButton
										location={props.data}
										item={x}
										onComplete={async () => {
											await fetchData(true);
										}}
										buttonProps={{
											color: "secondary",
											variant: "text",
										}}
									>
										Detaljer
									</ViewPickupItemButton>
								}
							></CardHeader>
							<CardContent>
								<Typography variant="body2">{getEntryName(x)}</Typography>
							</CardContent>
							<CardActions style={{ justifyContent: "center" }}>
								{/* {prevStatus && (
									<Button
										color="secondary"
										variant="text"
										onClick={async () => {
											await updateStatusOnItem(x, prevStatus);
										}}
									>
										Flytta tillbaks till {statusToText(prevStatus)}
									</Button>
								)} */}
								{nextStatus && (
									<Button
										color="secondary"
										variant="outlined"
										onClick={async () => {
											await updateStatusOnItem(x, nextStatus);
										}}
									>
										{statusToNextActionText(status)}
									</Button>
								)}
							</CardActions>
						</Card>
					);
				})}
			</Box>
		);
	};

	const render = () => {
		const boardBoxStyle = {
			display: "flex",
			gap: "16px",
			marginTop: "16px",
		};

		const itemWrapperBoxStyle: React.CSSProperties = {
			flex: "1",
			color: "rgb(51, 131, 196)",
			boxShadow: "rgba(25, 38, 48, 0.13) 1px 1px 8px -2px, inset 0 0 2px rgba(25, 38, 48, 0.32)",
		};

		const itemWrapperHeaderBoxStyle: React.CSSProperties = {
			background: "rgb(51, 131, 196)",
			padding: "16px",
		};

		const itemWrapperSectionBoxStyle: React.CSSProperties = {
			padding: "16px",
		};

		const itemWrapperHeaderTitleBoxStyle: React.CSSProperties = {
			color: "#fff",
			fontWeight: "500",
			letterSpacing: "0.1em",
			textTransform: "uppercase",
		};

		return (
			<Box style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
				<Box>
					<TextField
						label="Filter"
						variant="outlined"
						fullWidth
						value={textFilter}
						onChange={(e) => setTextFilter(e.target.value)}
					/>
				</Box>
				<Box style={boardBoxStyle}>
					{/* <Box style={itemWrapperBoxStyle}>
						<Box style={itemWrapperHeaderBoxStyle}>
							<Typography style={itemWrapperHeaderTitleBoxStyle}>{statusToText("NEW", true)}</Typography>
						</Box>
						<Box style={itemWrapperSectionBoxStyle}>{renderItemCardsForStatus("NEW")}</Box>
					</Box> */}
					<Box style={itemWrapperBoxStyle}>
						<Box style={itemWrapperHeaderBoxStyle}>
							<Typography style={itemWrapperHeaderTitleBoxStyle}>{statusToText("IN_PROGRESS", true)}</Typography>
						</Box>
						<Box style={itemWrapperSectionBoxStyle}>{renderItemCardsForStatus("IN_PROGRESS")}</Box>
					</Box>
					<Box style={itemWrapperBoxStyle}>
						<Box style={itemWrapperHeaderBoxStyle}>
							<Typography style={itemWrapperHeaderTitleBoxStyle}>{statusToText("DONE", true)}</Typography>
						</Box>
						<Box style={itemWrapperSectionBoxStyle}>{renderItemCardsForStatus("DONE")}</Box>
					</Box>
					{/* <Box style={itemWrapperBoxStyle}>
						<Box style={itemWrapperHeaderBoxStyle}>
							<Typography style={itemWrapperHeaderTitleBoxStyle}>{statusToText("DELIVERED", true)}</Typography>
						</Box>
						<Box style={itemWrapperSectionBoxStyle}>{renderItemCardsForStatus("DELIVERED")}</Box>
					</Box> */}
				</Box>
			</Box>
		);
	};

	return render();
};

export default Engraver;
