import React, { useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { ApiBackend } from "../../../providers/apibackend";
import { getBulkPatchDocument, PatchablePropertiesWithId, PatchRestrictions } from "./utils";
import { PatchPickupItemDocumentProps } from "../../../providers/models";
import PatchLocationItemsDialogContent from "./PatchLocationItemsDialogContent";

interface IProps {
    locationId: string;
    items: PatchablePropertiesWithId[];
    patchProperties: (keyof PatchPickupItemDocumentProps)[];

    restrictions?: PatchRestrictions;
    title?: string;

    onComplete: () => void;
    onAbort: () => void;
}

const PatchLocationItemsDialog = (props: IProps) => {
    const [patchEntry, setPatchEntry] = useState({} as Partial<PatchPickupItemDocumentProps>);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);

    const backend = new ApiBackend();

    const handleSave = async () => {
        try {
            setError(false);
            setLoading(true);

            const patchRequest = getBulkPatchDocument(props.items.map(p => p.id), patchEntry as PatchPickupItemDocumentProps);
            await backend.bulkPatchPickupItems(props.locationId, patchRequest);

            setLoading(false);
            props.onComplete();
        } catch {
            setError(true);
            setLoading(false);
        }
    };

    const handlePatchEntryChanged = (nextValue: Partial<PatchPickupItemDocumentProps>) => {
        setError(false);
        setPatchEntry(nextValue);
    };

    const render = () => {
        const isSaveDisabled = !Object.keys(patchEntry).length || loading;
        const isAbortDisabled = loading;

        return (
            <Dialog
                disableEscapeKeyDown
                fullWidth={true}
                maxWidth="lg"
                aria-labelledby="patch-location-items-title"
                open={true}>
                <DialogTitle id="patch-location-items-title">
                    {props.title ? props.title : "Masshantera avhämtningar"}
                </DialogTitle>
                <DialogContent dividers style={{ minHeight: 300 }}>
                    {loading &&
                        <LinearProgress color="secondary" />
                    }
                    {error && (
                        <Alert severity="error">
                            Ett fel uppstod vid försök att spara. Kontrollera att alla fält är korrekt
                            ifyllda och försök igen.
                        </Alert>
                    )}
                    <PatchLocationItemsDialogContent
                        items={props.items}
                        patchProperties={props.patchProperties}
                        restrictions={props.restrictions}
                        onChange={handlePatchEntryChanged}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        disabled={isAbortDisabled}
                        onClick={props.onAbort}
                        color="secondary"
                    >
                        Avbryt
                    </Button>
                    <Button
                        variant="contained"
                        disabled={isSaveDisabled}
                        onClick={handleSave}
                        color="secondary"
                    >
                        Spara
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    return render();
}

export default PatchLocationItemsDialog;
