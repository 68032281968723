import React from "react";
import { Box, Button, Card, CardActions, CardContent, CardHeader, Grid, MenuItem, Tab, Tabs, TextField, WithStyles } from "@material-ui/core";
import { FieldArray, FormikProps } from "formik";
import { B2bCompany } from "../../../model/B2bCompany";
import FormTextField from "../../Common/FormTextField";
import FormCbxField from "../../Common/FormCbxField";
import EditB2bCompanyAdmins from "./EditB2bCompanyAdmins";
import EditB2bCompanyCustomProducts from "./CustomProduct/EditB2bCompanyCustomProducts";
import { createRandomCode } from "../../../utilities/codes";
import FormCountryPickerDropdown from "../../Common/FormCountryPickerDropDown";
import FormSelectField from "../../Common/FormSelectField";
import EditB2bCompanySurveys from "./Surveys/EditB2bCompanySurveys";
import CompanyCategoryPicker from "../../Common/CompanyCategoryPicker";
import { vasaloppetDateTimeGetterFormatter } from "../../../utilities/date";
import VasaloppetFieldset from "../../Common/VasaloppetFieldset";
import TabPanel from "../../Common/TabPanel";
import EditB2bCompanyEntries from "./Entries/EditB2bCompanyEntries";

interface IState {
    activeTab: number;
}

class EditB2bCompanyFormContent extends React.Component<WithStyles & FormikProps<B2bCompany>, IState> {

    constructor(props: WithStyles & FormikProps<B2bCompany>) {
        super(props);
        this.state = { activeTab: 0 };
    }

    render(): JSX.Element {
        const { classes, setFieldValue, values } = this.props;
        const { activeTab } = this.state;

        return (
            <Grid container className={classes.root} spacing={2} style={{ width: '100%' }}>
                <Grid item xs={8}>
                    <FormTextField {...this.props}
                        propName="name"
                        label="Namn"
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormCbxField {...this.props}
                        propName="isArchived"
                        label="Arkiverad"
                    />
                </Grid>
                <Grid item xs={8}>
                    <FormTextField {...this.props}
                        propName="description"
                        label="Beskrivning"
                    />
                </Grid>
                <Grid item xs={4}>
                    <CompanyCategoryPicker
                        initialValue={values.category}
                        strict={false}
                        onChange={(value: string) => {
                            setFieldValue("category", value);
                        }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormTextField {...this.props}
                        propName="orgNumber"
                        label="Organisationsnummer"
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormTextField {...this.props}
                        propName="address"
                        label="Fakturaadress"
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormTextField {...this.props}
                        propName="email"
                        label="E-post faktura"
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormTextField {...this.props}
                        propName="postCode"
                        label="Postnummer"
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormTextField {...this.props}
                        propName="city"
                        label="Postort"
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormCountryPickerDropdown {...this.props}
                        propName="country"
                        label="Land"
                    />
                </Grid>
                <Grid item xs={8}>
                    <FormTextField {...this.props}
                        propName="reference"
                        label="Referens på faktura"
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormSelectField {...this.props}
                        propName="status"
                        label="Fakturering"
                        valueGetter={() => values.status ?? "NOTAPPLIED"}
                    >
                        <MenuItem value="NOTAPPLIED">Ej ansökt</MenuItem>
                        <MenuItem value="APPLIED">Ansökt</MenuItem>
                        <MenuItem value="ACCEPTED">Godkänd</MenuItem>
                        <MenuItem value="DENIED">Nekad</MenuItem>
                        <MenuItem value="NOTINTERESTED">Inte intresserad</MenuItem>
                    </FormSelectField>
                </Grid>
                <Grid item xs={8}>
                    <FormTextField {...this.props}
                        propName="registrationLink"
                        label="Registreringslänk"
                    />
                </Grid>
                <Grid item xs={2}>
                    <FormTextField {...this.props}
                        propName="code"
                        label="Kod"
                    />
                </Grid>
                <Grid item xs={1}>
                    <Button
                        variant="contained"
                        onClick={() => { setFieldValue("code", createRandomCode()) }}>
                        Skapa kod
                    </Button>
                </Grid>
                <Grid item xs={1}>
                    <FormCbxField {...this.props}
                        propName="codeIsActive"
                        label="Kod aktiv"
                    />
                </Grid>
                <Grid item xs={12}>
                    <VasaloppetFieldset title="Inställningar">
                        <FormCbxField {...this.props}
                            propName="enforceCustomProducts"
                            label="Endast anpassade produkter"
                        />
                        <FormCbxField {...this.props}
                            propName="areEntriesSellable"
                            label="Anmälningar kan säljas"
                        />
                        <FormCbxField {...this.props}
                            propName="existsInExternalAccountingSystem"
                            label="Finns i Agresso"
                        />
                        <FormCbxField {...this.props}
                            propName="areEntriesRemovable"
                            label="Företagsadmin kan ta bort platser"
                        />
                    </VasaloppetFieldset>
                </Grid>
                <Grid item xs={3}>
                    <TextField disabled style={{ flex: 1, margin: '0 3px' }} id="standard-basic" value={values.createdBy ?? "---"} label="Skapad av" fullWidth />
                </Grid>
                <Grid item xs={3}>
                    <TextField disabled style={{ flex: 1, margin: '0 3px' }} id="standard-basic" value={vasaloppetDateTimeGetterFormatter(values.created, "---")} label="Skapad" fullWidth />
                </Grid>
                <Grid item xs={3}>
                    <TextField disabled style={{ flex: 1, margin: '0 3px' }} id="standard-basic" value={values.updatedBy ?? "---"} label="Uppdaterad av" fullWidth />
                </Grid>
                <Grid item xs={3}>
                    <TextField disabled style={{ flex: 1, margin: '0 3px' }} id="standard-basic" value={vasaloppetDateTimeGetterFormatter(values.updated, "---")} label="Uppdaterad" fullWidth />
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <FieldArray
                            name="admins"
                            render={arrayHelpers => (
                                <>
                                    <CardHeader
                                        className={classes.cardHeader}
                                        title="Hantera administratörer"
                                    />
                                    <CardContent>
                                        <EditB2bCompanyAdmins {...this.props} />
                                    </CardContent>
                                    <CardActions>
                                        <Box mb={1}>
                                            <Button
                                                variant="contained"
                                                onClick={() => { arrayHelpers.push({}) }}>
                                                Lägg till administratör
                                            </Button>
                                        </Box>
                                    </CardActions>
                                </>
                            )} />
                    </Card>
                </Grid>

                <Grid item xs={12}>
                    <Tabs
                        value={activeTab}
                        className={classes.tabs}
                        onChange={this.handleTabChange}
                    >
                        <Tab label="Anmälningar" />
                        <Tab label="Produkter" />
                        <Tab label="Enkäter" />
                    </Tabs>
                    <TabPanel value={activeTab} index={0}>
                        <EditB2bCompanyEntries companyId={this.props.values.id} companyName={this.props.values.name} />
                    </TabPanel>
                    <TabPanel value={activeTab} index={1}>
                        <EditB2bCompanyCustomProducts {...this.props} />
                    </TabPanel>
                    <TabPanel value={activeTab} index={2}>
                        <EditB2bCompanySurveys {...this.props} />
                    </TabPanel>
                </Grid>
            </Grid>
        );
    }

    private handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        this.setState({ activeTab: newValue });
    };
}

export default EditB2bCompanyFormContent;
