import React, { useEffect, useState } from "react";
import { Button, Card, CardActions, CardContent, CardHeader, Checkbox, createStyles, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, IconButton, LinearProgress, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Theme, WithStyles, withStyles } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { CustomSetting } from "../../model/CustomSetting";
import { ApiBackend } from "../../providers/apibackend";
import withToaster, { IToasterContext } from "../Common/Toaster";
import { vasaloppetDateTimeGetterFormatter } from "../../utilities/date";

const ManageCustomSettings = (props: WithStyles) => {
    const [loading, setLoading] = useState(true);
    const [settings, setSettings] = useState<CustomSetting[]>([]);
    const [editSetting, setEditSetting] = useState<CustomSetting | null>(null);

    const backend = new ApiBackend();

    useEffect(() => {
        fetchConfigs();
    }, []);

    const handleEditClick = (item: CustomSetting) => () => {
        setEditSetting(item);
    };

    const handleAddClick = () => {
        setEditSetting({ setting: null, value: null, type: null, isArchived: false } as CustomSetting);
    };

    const handleAbort = () => {
        setEditSetting(null);
    };

    const handleSave = async () => {
        setEditSetting(null);
        await fetchConfigs();
    };

    const fetchConfigs = async () => {
        setLoading(true);
        const nextValue = await backend.listCustomSettings();
        setSettings(nextValue);
        setLoading(false);
    };

    const formatValue = (value: string): string => {
        return value.length > 400 ? value.slice(0, 400 - 1) + ' ...' : value;
    };

    const render = () => {
        const { classes } = props;

        return (<>
            <Grid container className={classes.root} spacing={2}>
                <Grid item xs={12}>

                    <Card >
                        <CardHeader className={classes.cardHeader} title="Settings" />
                        <CardContent>
                            {loading &&
                                <LinearProgress color="secondary" />
                            }
                            <TableContainer component={Paper}>
                                <Table size="small" aria-label="a dense table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell>Nyckel</StyledTableCell>
                                            <StyledTableCell>Typ</StyledTableCell>
                                            <StyledTableCell>Ändrad</StyledTableCell>
                                            <StyledTableCell>Ändad av</StyledTableCell>
                                            <StyledTableCell>Värde</StyledTableCell>
                                            <StyledTableCell>Arkiverad</StyledTableCell>
                                            <StyledTableCell></StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {settings.map((item) =>
                                            <TableRow key={item.setting}>
                                                <StyledTableCell>{item.setting}</StyledTableCell>
                                                <StyledTableCell>{item.type}</StyledTableCell>
                                                <StyledTableCell>{vasaloppetDateTimeGetterFormatter(item.updated)}</StyledTableCell>
                                                <StyledTableCell>{item.updatedBy}</StyledTableCell>
                                                <StyledTableCell>{formatValue(item.value)}</StyledTableCell>
                                                <StyledTableCell>{item.isArchived ? "JA" : "NEJ"}</StyledTableCell>
                                                <StyledTableCell align="right">
                                                    <IconButton color="default" size="small"
                                                        disabled={loading}
                                                        onClick={handleEditClick(item)}
                                                    >
                                                        <EditIcon fontSize="medium" />
                                                    </IconButton>
                                                </StyledTableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </CardContent>
                        <CardActions>
                            <Button size="small" color="secondary"
                                disabled={loading}
                                onClick={handleAddClick}
                            >
                                Lägg till
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>
            </Grid>
            {editSetting &&
                <EditDialog
                    editItem={editSetting}
                    existingItems={settings}
                    onAbort={handleAbort}
                    onSave={handleSave}
                />
            }
        </>);
    };

    return render();
};

type EditDialogProps = {
    editItem: CustomSetting;
    existingItems: CustomSetting[];
    onAbort: () => void;
    onSave: () => void;
}

const EditDialog = withToaster((props: EditDialogProps & IToasterContext) => {
    const [setting, setSetting] = useState<string | null>(props.editItem.setting);
    const [settingType, setSettingType] = useState<string | null>(props.editItem.type);
    const [value, setValue] = useState<string | null>(props.editItem.value);
    const [isArchived, setIsArchived] = useState<boolean>(props.editItem.isArchived);
    const [loading, setLoading] = useState(false);

    const backend = new ApiBackend();

    const handleSave = async () => {
        setLoading(true);
        const saveResult = await backend.addOrUpdateCustomSetting({
            setting: setting,
            type: settingType,
            value: value,
            isArchived: isArchived
        } as CustomSetting);
        setLoading(false);

        if (saveResult) {
            props.onSave();
        }

        const { showToast } = props;
        showToast("Någonting gick fel när vi försökte spara inställningen", "error")
    };

    const handleAbort = () => {
        props.onAbort();
    };

    const render = () => {
        const { editItem } = props;

        const isUpdate = !!editItem.setting;
        const title = isUpdate ? `Redigera konfiguration för ${setting}` : "Skapa ny konfiguration";
        const canSave = !!setting && value;

        return (
            <Dialog
                disableEscapeKeyDown
                fullWidth={true}
                maxWidth="md"
                aria-labelledby="manage-custom-settings-title"
                open={true}
            >
                <DialogTitle id="manage-custom-settings-title">
                    {title}
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                style={{ flex: 1 }}
                                value={setting ?? ""}
                                fullWidth
                                label="Nyckel"
                                InputLabelProps={{ shrink: true }}
                                disabled={isUpdate}
                                onChange={(e) => {
                                    setSetting(e.target.value);
                                }}
                            >
                            </TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                style={{ flex: 1 }}
                                value={settingType ?? ""}
                                fullWidth
                                label="Typ"
                                InputLabelProps={{ shrink: true }}
                                onChange={(e) => {
                                    setSettingType(e.target.value);
                                }}
                            >
                            </TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox color="primary"
                                        checked={isArchived}
                                        onChange={(e) => {
                                            setIsArchived(e.target.checked);
                                        }}
                                    />
                                }
                                label="Arkiverad" />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                style={{ flex: 1 }}
                                minRows={10}
                                maxRows={20}
                                multiline={true}
                                value={value ?? ""}
                                fullWidth
                                label="Värde"
                                InputLabelProps={{ shrink: true }}
                                onChange={(e) => {
                                    setValue(e.target.value);
                                }}
                            >
                            </TextField>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        type="button"
                        color="secondary"
                        disabled={loading || !canSave}
                        variant="contained"
                        onClick={handleSave}
                    >
                        Spara
                    </Button>
                    <Button
                        style={{ marginLeft: 10 }}
                        color="secondary"
                        disabled={loading}
                        variant="contained"
                        onClick={handleAbort}
                    >
                        Avbryt
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    return render();
});

const useStyles = ({ palette }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    }
});

const tableHeadStyles = ({ palette, spacing }: Theme) => createStyles({
    head: {
        background: palette.primary.main,
        color: palette.primary.contrastText,
    }
});

const StyledTableCell = withStyles(tableHeadStyles)(TableCell);

export default withStyles(useStyles)(ManageCustomSettings);
