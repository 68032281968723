import { PickupItem, PickupItemStatus } from "../../../../model/PickupItem";
import { Subset } from "../../../../typings/GlobalExtensions";
import { getPickupItemMetadataValueByKey } from "../../items/utils";

export type AssignablePickupItemStatus = Subset<PickupItemStatus, "NEW" | "IN_PROGRESS" | "DONE" | "DELIVERED">;

export const getNextStatus = (status: AssignablePickupItemStatus): AssignablePickupItemStatus | null => {
    switch (status) {
        case "NEW":
            return "IN_PROGRESS";
        case "IN_PROGRESS":
            return "DONE";
        case "DONE":
            return "DELIVERED";
        default:
            return null;
    }
};

export const getPreviousStatus = (status: AssignablePickupItemStatus): AssignablePickupItemStatus | null => {
    switch (status) {
        case "IN_PROGRESS":
            return "NEW";
        case "DONE":
            return "IN_PROGRESS";
        case "DELIVERED":
            return "DONE";
        default:
            return null;
    }
};

export const statusToText = (status: PickupItemStatus, plural?: boolean) => {
    switch (status) {
        case "NEW":
            return plural ? "Nya / New" : "Ny / New";
        case "IN_PROGRESS":
            return plural ? "Behandlas / In progress" : "Behandlas / In progress";
        case "DONE":
            return plural ? "Färdiga / Done" : "Färdig / Done";
        case "DELIVERED":
            return plural ? "Levererade / Delivered" : "Levererad / Delivered";
        case "CANCELED":
            return plural ? "Avbrutna / Cancelled" : "Avbruten / Cancelled";
        case "NOT_DELIVERED":
            return plural ? "Inte levererade / Not delivered" : "Inte levererad / Not delivered";
        default:
            return null;
    }
};

export const statusToNextActionText = (status: PickupItemStatus) => {
    switch (status) {
        case "NEW":
            return "Påbörja / Start";
        case "IN_PROGRESS":
            return "Färdig / Done";
        case "DONE":
            return "Levererad / Delivered";
        default:
            return null;
    }
};

export const getCardTitle = (item: PickupItem): string => {
    const variantName = getPickupItemMetadataValueByKey(item, "variantName");

    if (!variantName) {
        return item.publicId;
    }

    return `${item.publicId} - ${variantName}`;
};

export const getEntryName = (item: PickupItem): string => {
    const firstName = getPickupItemMetadataValueByKey(item, "firstName");
    const lastName = getPickupItemMetadataValueByKey(item, "lastName");

    if (!firstName || !lastName) {
        return "";
    }

    return `${firstName} ${lastName}`;
};

export const isPreBooking = (item: PickupItem): boolean => {
    const isPreBooked = getPickupItemMetadataValueByKey(item, "isPreBooked");

    if (!isPreBooked || isPreBooked === "false") {
        return false;
    }

    return true;
};

export const isPrestationsmedalj = (item: PickupItem): boolean => {
    if (!item.message) {
        return false;
    }

    return item.message.toLowerCase().indexOf("prestationsmedalj") > -1;
};

export const getPreBookedText = (item: PickupItem): string => {
    const isPreBooked = getPickupItemMetadataValueByKey(item, "isPreBooked");

    if (!isPreBooked || isPreBooked === "false") {
        return "";
    }

    return "Förbokning / Prebooking";
};
