import { Grid, MenuItem, WithStyles } from "@material-ui/core";
import { FormikProps } from "formik";
import React from "react";
import { PickupItem, PickupItemStatuses } from "../../../model/PickupItem";
import FormSelectField from "../../Common/FormSelectField";
import { statusToText } from "../expo/medaljgravering/utils";
import PickupItemInformation from "./PickupItemInformation";

interface IProps extends FormikProps<PickupItem> {
	disabled?: boolean;
}

const EditPickupItemFormContent = (props: IProps & WithStyles) => {
	const { disabled, values, classes, initialValues } = props;

	return (
		<Grid container className={classes.root} spacing={4} style={{ width: "100%" }}>
			{!disabled &&
				<Grid item xs={12}>
					<FormSelectField {...props} propName="status" label="Status" valueGetter={() => values.status ?? ""}>
						{PickupItemStatuses.map((x, idx) => {
							return (
								<MenuItem key={idx} value={x}>
									{statusToText(x, false)}
								</MenuItem>
							);
						})}
					</FormSelectField>
				</Grid>
			}
			<Grid item xs={12}>
				<PickupItemInformation item={initialValues} />
			</Grid>
		</Grid>
	);
};

export default EditPickupItemFormContent;
