/**
 * Vasaloppet Mina Sidor
 * Author: Peter Löfås, peter@lofas.se
 */

import React, { Fragment } from 'react'
import { Card, CardHeader, CardContent, Theme, withStyles, createStyles, Grid, LinearProgress, Typography, Button, TextField, FormControlLabel, Checkbox, WithStyles, FormControl } from '@material-ui/core';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { ApiBackend } from '../../providers/apibackend';
import { AddOnSummaryItem, EnervitOrderItem } from '../../model/Order';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { DataGrid, GridColDef as ColDef, GridValueFormatterParams as ValueFormatterParams } from '@material-ui/data-grid';
import RefreshIcon from '@material-ui/icons/Refresh';
import ManageOrder from './ManageOrder';
import ProductList from '../Common/ProductList/ProductList';
import { DATE_FORMAT, FILE_FORMAT, vasaloppetDateGetterFormatter, vasaloppetDateTimeGetterFormatter, vasaloppetMoment } from '../../utilities/date';
import { IExportExcelRequest } from '../../model/IExportExcelRequest';
import EventIdFilter from '../Common/SearchFilter/EventIdFilter';

interface State {
    loading: boolean;
    view: "Overview" | "Summary" | "Order";
    orders: EnervitOrderItem[];
    viewOrder: string;
    selectedRace: string;
    fromDate: Date;
    toDate: Date;
    selectedEvent: string;
    selectedEventName: string;
    summary: AddOnSummaryItem[];
    overview: AddOnSummaryItem[];
}

class ListEnervitOrders extends React.Component<RouteComponentProps & WithStyles, State> {
    private readonly api: ApiBackend = new ApiBackend();

    constructor(props: RouteComponentProps & WithStyles) {
        super(props);
        this.state = {
            loading: false,
            orders: [],
            viewOrder: null,
            selectedRace: null as string,
            fromDate: new Date(new Date().getFullYear(), 0, 1),
            toDate: new Date(9999, 11, 31),
            selectedEvent: null as string,
            selectedEventName: null as string,
            view: "Overview",
            summary: null,
            overview: null
        };
    }

    componentDidMount() {
        this.refresh();
    }

    render() {
        const { classes } = this.props;
        const { viewOrder } = this.state;

        return <>
            <Grid container className={classes.root} spacing={2}>
                <Grid item xs={12}>
                    <Card>
                        <CardHeader className={classes.cardHeader}
                            title={
                                <Typography variant="h5" style={{ display: "inline" }}>
                                    Inställningar
                                </Typography>
                            }
                        />
                        <CardContent>
                            <Grid container className={classes.root} spacing={2}>
                                <Grid item xs={12}>
                                    <b>Visa</b>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControlLabel label="Översikt"
                                        control={
                                            <Checkbox checked={this.state.view === "Overview"}
                                                onChange={(ev) => {
                                                    this.setState({ view: "Overview" }, () => {
                                                        this.refresh();
                                                    })
                                                }}
                                            />
                                        }
                                    />
                                    <FormControlLabel label="Sammanställning"
                                        control={
                                            <Checkbox checked={this.state.view === "Summary"}
                                                onChange={(ev) => {
                                                    this.setState({ view: "Summary" }, () => {
                                                        this.refresh();
                                                    })
                                                }}
                                            />
                                        }
                                    />
                                    <FormControlLabel label="Enskilda orders"
                                        control={
                                            <Checkbox checked={this.state.view === "Order"}
                                                onChange={(ev) => {
                                                    this.setState({ view: "Order" }, () => {
                                                        this.refresh();
                                                    })
                                                }}
                                            />
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    {this.renderViewFilter()}
                                </Grid>
                                <Grid item xs={2}>
                                    <Button variant="text" color="secondary"
                                        disabled={this.state.loading}
                                        onClick={this.exportExcel}
                                    >
                                        Excelexportera
                                    </Button>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>

                </Grid>

                <Grid item xs={12}>
                    {this.renderContentCard()}
                </Grid>
            </Grid>
            {viewOrder && (
                <ManageOrder
                    orderId={viewOrder}
                    close={() => {
                        this.setState({ viewOrder: null });
                    }}
                />
            )}
        </>;
    }

    private renderViewFilter = () => {
        const { classes } = this.props;
        const { view, selectedEvent } = this.state;

        switch (view) {
            case "Overview":
                return (
                    <Grid container className={classes.root} spacing={2}>
                        <FormControl fullWidth>
                            <EventIdFilter
                                label="Evenemang"
                                useWildCardSelect={false}
                                clearable={false}
                                initialValue={selectedEvent}
                                onChange={(nextValue, eventName) => {
                                    this.setState({ selectedEvent: nextValue, selectedEventName: eventName }, () => {
                                        this.refresh();
                                    })
                                }}
                            />
                        </FormControl>
                    </Grid>
                );
            case "Summary":
                return (
                    <Grid container className={classes.root} spacing={2}>
                        <Grid item xs={3}><b>Från datum</b></Grid>
                        <Grid item xs={3}><b>Till datum</b></Grid>
                        <Grid item xs={6}></Grid>

                        <Grid item xs={3}><TextField type="date"
                            value={vasaloppetDateGetterFormatter(this.state.fromDate)}
                            onChange={(ev) => {
                                this.setState({ fromDate: vasaloppetMoment(ev.target.value).toDate() }, () => {
                                    this.refresh();
                                });
                            }}
                        />
                        </Grid>
                        <Grid item xs={3}><TextField type="date"
                            value={vasaloppetDateGetterFormatter(this.state.toDate)}
                            onChange={(ev) => {
                                this.setState({ toDate: vasaloppetMoment(ev.target.value).toDate() }, () => {
                                    this.refresh();
                                });
                            }}
                        />
                        </Grid>
                        <Grid item xs={6}></Grid>
                    </Grid>
                );
            case "Order":
                return (
                    <Grid container className={classes.root} spacing={2}>
                        <Grid item xs={3}><b>Från datum</b></Grid>
                        <Grid item xs={3}><b>Till datum</b></Grid>
                        <Grid item xs={4}><b>Lopp</b></Grid>

                        <Grid item xs={3}><TextField type="date"
                            value={vasaloppetDateGetterFormatter(this.state.fromDate)}
                            onChange={(ev) => {
                                this.setState({ fromDate: vasaloppetMoment(ev.target.value).toDate() }, () => {
                                    this.refresh();
                                });
                            }}
                        />
                        </Grid>
                        <Grid item xs={3}><TextField type="date"
                            value={vasaloppetDateGetterFormatter(this.state.toDate)}
                            onChange={(ev) => {
                                this.setState({ toDate: vasaloppetMoment(ev.target.value).toDate() }, () => {
                                    this.refresh();
                                });
                            }}
                        />
                        </Grid>
                        <Grid item xs={4}>
                            <ProductList
                                initialValue={this.state.selectedRace}
                                productTypes={["race", "event"]}
                                onChange={(evt) => {
                                    this.setState({ selectedRace: evt.products[0].id }, () => {
                                        this.refresh();
                                    });
                                }}
                            />
                        </Grid>
                    </Grid>
                );
            default:
                return null;
        }
    };

    private renderContentCard = () => {
        const { classes } = this.props;
        const { view, overview, summary, orders, loading } = this.state;

        const Wrapper = (props: { children: React.ReactNode }) => {
            const { children } = props;

            return (
                <Card>
                    <CardHeader className={classes.cardHeader}
                        title={<>
                            <Typography variant="h5" style={{ display: "inline" }}>
                                Beställningar enervit
                            </Typography>
                            <RefreshIcon
                                style={{ display: "inline", verticalAlign: "middle", cursor: "pointer" }}
                                onClick={this.refresh}
                            />
                        </>}
                    />
                    <CardContent>
                        {this.state.loading &&
                            <LinearProgress color="secondary" />
                        }
                        {children}
                    </CardContent>
                </Card>
            );
        };

        let content: JSX.Element = null;

        switch (view) {
            case "Overview":
                content = (
                    <Grid container spacing={2}>
                        <Grid item xs={6}><b>Produkt</b></Grid>
                        <Grid item xs={6}><b>Antal sålda</b></Grid>
                        {!!overview && <>
                            {overview.map((x, idx) => {
                                return <Fragment key={idx}>
                                    <Grid item xs={6}>{x.productName}</Grid>
                                    <Grid item xs={6}>{x.numberOfSold}st</Grid>

                                </Fragment>
                            })}
                            <Grid item xs={6}><b>TOTALT:</b></Grid>
                            <Grid item xs={6}><b>{overview.reduce((prev, cur) => prev + cur.numberOfSold, 0)}st</b></Grid>
                        </>}

                    </Grid>
                );
                break;
            case "Summary":
                content = (
                    <Grid container spacing={2}>
                        <Grid item xs={6}><b>Produkt</b></Grid>
                        <Grid item xs={2}><b>Antal sålda</b></Grid>
                        <Grid item xs={4}><b>Totalt</b></Grid>
                        {!!summary && <>
                            {summary.map((x, idx) => {
                                return <Fragment key={idx}>
                                    <Grid item xs={6}>{x.productName}</Grid>
                                    <Grid item xs={2}>{x.numberOfSold}st</Grid>
                                    <Grid item xs={4}>{x.price * x.numberOfSold}kr</Grid>
                                </Fragment>
                            })}
                            <Grid item xs={6}><b>SUMMA:</b></Grid>
                            <Grid item xs={2}><b>{summary.reduce((prev, cur) => prev + cur.numberOfSold, 0)}st</b></Grid>
                            <Grid item xs={4}><b>{summary.reduce((prev, cur) => prev + cur.numberOfSold * cur.price, 0)}kr</b></Grid>
                        </>}

                    </Grid>
                );
                break;
            case "Order":
                const columns: ColDef[] = [
                    {
                        field: "orderPublicId", headerName: "OrderID", width: 150,
                        renderCell: (params: ValueFormatterParams) => {
                            return <Typography style={{ textDecoration: "underline", cursor: "pointer" }} onClick={this.openOrder(params.row.orderId)}>{params.row.orderPublicId}</Typography>
                        }
                    },
                    { field: "participant", headerName: "Deltagare", width: 250, valueGetter: (params) => { return params.row.RaceInfo?.firstName + " " + params.row.RaceInfo?.lastName } },
                    { field: "email", headerName: "Epost", width: 250, valueGetter: (params) => { return params.row.RaceInfo?.email } },
                    { field: "phone", headerName: "Tel", width: 250, valueGetter: (params) => { return params.row.RaceInfo?.phone } },
                    { field: "productName", headerName: "Produkt", minWidth: 270, flex: 1, valueGetter: (params) => { return params.row.productName } },
                    { field: "created", headerName: "Skapad", width: 170, valueGetter: (params) => { return vasaloppetDateTimeGetterFormatter(params.row.created) } }
                ];

                content = !!orders ? (
                    <DataGrid
                        checkboxSelection
                        autoHeight={true}
                        loading={loading}
                        rows={orders ?? []}
                        columns={columns}
                        pageSize={100}
                        rowsPerPageOptions={[100]}
                    />
                ) : null;
                break;
            default:
                content = null;
                break;
        }

        return <Wrapper>{content}</Wrapper>;
    };

    private refresh = async () => {
        const { view, fromDate, toDate, selectedRace, selectedEvent } = this.state;

        this.setState({ loading: true });

        let apiCall: () => Promise<void>;

        switch (view) {
            case "Overview":
                let overview: AddOnSummaryItem[] = [];
                if (!!selectedEvent) {
                    overview = await this.api.listEnervitOverview(selectedEvent);
                }
                this.setState({ overview: overview });
                break;
            case "Summary":
                apiCall = async () => {
                    const summary = await this.api.listEnervitSummary(vasaloppetDateGetterFormatter(fromDate), vasaloppetDateGetterFormatter(toDate));
                    this.setState({ summary: summary });
                };
                break;
            case "Order":
                apiCall = async () => {
                    let orders: EnervitOrderItem[] = [];
                    if (!!selectedRace) {
                        orders = await this.api.listEnervitOrders(selectedRace, vasaloppetDateGetterFormatter(fromDate), vasaloppetDateGetterFormatter(toDate));
                    }

                    this.setState({ orders: orders });
                };
                break;
            default:
                break;
        }

        try {
            if (!!apiCall) {
                await apiCall();
            }
        } finally {
            this.setState({ loading: false });
        }
    };

    private openOrder = (orderId: string) => () => {
        this.setState({ viewOrder: orderId });
    };

    private exportExcel = () => {
        if (this.state.view === "Overview") {
            this.renderXLSXOverview();
        } else if (this.state.view === "Summary") {
            this.renderXLSXSummary();
        } else {
            this.renderXLSX();
        }
    };

    private renderXLSXOverview = async () => {
        const { overview, selectedEventName } = this.state;

        const wb = XLSX.utils.book_new();
        wb.Props = {
            Title: "Enervitöversikt",
            Subject: vasaloppetMoment().format(DATE_FORMAT),
            Author: "Vasaloppet",
            CreatedDate: new Date()
        };

        wb.SheetNames.push("Översikt");

        const data = [
            ['Vasaloppsföreningen Sälen-Mora'],
            [],
            [`Enervit ${selectedEventName}`],
            [],
            [],
            [],
            ["Lopp", "Antal sålda tjänster"]
        ];
        let totalNum = 0;
        for (let i = 0; i < overview.length; i++) {
            const row = overview[i];
            totalNum += row.numberOfSold;
            data.push(
                [
                    row.productName,
                    `${row.numberOfSold}`
                ]
            );
        }

        data.push(["Totalt antal beställningar", `${totalNum}`]);

        const ws = XLSX.utils.aoa_to_sheet(data);

        wb.Sheets["Översikt"] = ws;

        const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
        saveAs(new Blob([this.s2ab(wbout)], { type: "application/octet-stream" }), `enervitöversikt_${selectedEventName}.xlsx`);
    };

    private renderXLSXSummary = async () => {
        const wb = XLSX.utils.book_new();
        wb.Props = {
            Title: "Enervitsummering",
            Subject: vasaloppetMoment().format(DATE_FORMAT),
            Author: "Vasaloppet",
            CreatedDate: new Date()
        };

        wb.SheetNames.push("Utlägg");

        const data = [['Vasaloppsföreningen Sälen-Mora'],
        [],
        ['Enervit ' + vasaloppetMoment(this.state.fromDate).format(FILE_FORMAT) + "-" + vasaloppetMoment(this.state.toDate).format(FILE_FORMAT)],
        [],
        ['Redovisning till:'],
        ['Kostnad Enervit support Vasaloppet 600kr, Öppet Spår 90K 650kr, Vasaloppet 45/Öppet Spår måndag 45 300kr'],
        ['Från: Vasaloppet, Rasmus Ericson, rasmus.ericson@vasaloppet.se'],
        [],
        [],
        ["Lopp", "Antal sålda tjänster", "Värde"]];
        let totalNum = 0;
        let totalSum = 0;
        for (let i = 0; i < this.state.summary.length; i++) {
            const row = this.state.summary[i];
            totalNum += row.numberOfSold;
            totalSum += (row.numberOfSold * row.price);
            data.push(
                [
                    row.productName,
                    row.numberOfSold + '',
                    (row.price * row.numberOfSold) + 'kr'
                ]
            );
        }

        data.push(["Summa att betala till Enervit", totalNum + '', totalSum + "kr"]);
        data.push(["konto 8431-9,53 063 191-0"]);

        const ws = XLSX.utils.aoa_to_sheet(data);

        wb.Sheets["Utlägg"] = ws;

        const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
        saveAs(new Blob([this.s2ab(wbout)], { type: "application/octet-stream" }), 'enervitbeställingar_' + vasaloppetMoment(this.state.fromDate).format(FILE_FORMAT) + "-" + vasaloppetMoment(this.state.toDate).format(FILE_FORMAT) + '.xlsx');
    };

    private renderXLSX = async () => {
        const request: IExportExcelRequest = {
            entityType: "EnervitOrder",
            filter: [
                {
                    propertyName: "fromDate",
                    propertyValue: vasaloppetDateGetterFormatter(this.state.fromDate)
                }, {
                    propertyName: "toDate",
                    propertyValue: vasaloppetDateGetterFormatter(this.state.toDate)
                }, {
                    propertyName: "raceProducts",
                    propertyValue: [
                        this.state.selectedRace
                    ]
                }
            ]
        };
        const fileName = 'enervitbeställingar_' + vasaloppetMoment(this.state.fromDate).format(FILE_FORMAT) + "-" + vasaloppetMoment(this.state.toDate).format(FILE_FORMAT) + '.xlsx';
        await this.api.exportExcel(request, fileName);
    };

    private s2ab = (s: any) => {
        const buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
        const view = new Uint8Array(buf);  //create uint8array as viewer
        for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF; //convert to octet
        return buf;
    };
}

const useStyles = ({ palette, spacing }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
    form: {

        '& > *': {
            margin: spacing(1),
            width: '25ch',
        },
        '& label.Mui-focused': {
            color: palette.secondary.main,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: palette.secondary.main,
        },
    }
}
);

export default withRouter(withStyles(useStyles)(ListEnervitOrders));
