import { Button, ButtonProps } from "@material-ui/core";
import React, { useState } from "react";
import { PickupItem } from "../../../model/PickupItem";
import { PickupItemLocation } from "../../../model/PickupItemLocation";
import PickupItemDialog from "../items/PickupItemDialog";

interface IProps {
	location: PickupItemLocation;
	item: PickupItem;
	buttonProps?: ButtonProps;
	children?: React.ReactNode;
	onComplete: () => void;
}

const ViewPickupItemButton = (props: IProps) => {
	const { location, item, buttonProps, children, onComplete } = props;
	const [open, setOpen] = useState(false);

	return (
		<>
			<Button onClick={() => setOpen(true)} {...buttonProps}>
				{children}
			</Button>
			{open && (
				<PickupItemDialog
					locationId={location.id}
					mode="VIEW"
					item={item}
					onComplete={() => {
						onComplete();
						setOpen(false);
					}}
					onAbort={() => setOpen(false)}
				/>
			)}
		</>
	);
};

export default ViewPickupItemButton;
