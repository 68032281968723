import React, { createContext, useState } from "react";
import { POSSetting } from "../model/POSSetting";
import { StripeTerminal } from "../model/StripeTerminal";

export interface IExpoContext {
    isExpoLayoutMode: boolean;
    isExpoMode: boolean;
    userPosSettings: POSSetting | null;
    userStripeTerminal: StripeTerminal | null;
    setUserPosSetting(value?: POSSetting): void;
    setUserStripeTerminal(value?: StripeTerminal): void;
    setPosLayout(value: boolean): void;
}

interface IProps {
    children: string | JSX.Element | JSX.Element[] | (() => JSX.Element)
}

const POS_STORAGE_KEY = "act_as_pos";
const STRIPE_STORAGE_KEY = "stripe_terminal";
const POS_LAYOUT_STORAGE_KEY = "use_pos_layout";

const getLocalStorageValue = <T extends unknown>(key: string): T | null => {
    let value = localStorage.getItem(key) ?? "";

    if (!!value) {
        return JSON.parse(value);
    }

    return null;
}

const setLocalStorageValue = <T extends unknown>(key: string, value?: T): void => {
    if (!!value) {
        localStorage.setItem(key, JSON.stringify(value));
    } else {
        localStorage.removeItem(key);
    }
}

export const getLocalStoragePosSetting = (): POSSetting | null => {
    return getLocalStorageValue<POSSetting>(POS_STORAGE_KEY);
};

const ExpoContext = createContext<IExpoContext>({} as IExpoContext);

const ExpoContextProvider = ({ children }: IProps): JSX.Element => {
    const [posSettings, setPosSettings] = useState(getLocalStorageValue<POSSetting>(POS_STORAGE_KEY));
    const [stripeTerminal, setStripeTerminal] = useState(getLocalStorageValue<StripeTerminal>(STRIPE_STORAGE_KEY));
    const [isPosLayout, setIsPosLayout] = useState(getLocalStorageValue<boolean>(POS_LAYOUT_STORAGE_KEY) ?? false);

    const setUserPosSetting = (value?: POSSetting): void => {
        setPosSettings(value);
        setLocalStorageValue(POS_STORAGE_KEY, value);
    };
    const setUserStripeTerminal = (value?: StripeTerminal): void => {
        setStripeTerminal(value);
        setLocalStorageValue(STRIPE_STORAGE_KEY, value);
    };

    const setPosLayout = (value: boolean): void => {
        setIsPosLayout(value);
        setLocalStorageValue(POS_LAYOUT_STORAGE_KEY, value);
    };

    const providerValues: IExpoContext = {
        isExpoLayoutMode: !!isPosLayout,
        isExpoMode: !!posSettings,
        userPosSettings: posSettings,
        userStripeTerminal: stripeTerminal,
        setUserPosSetting: setUserPosSetting,
        setPosLayout: setPosLayout,
        setUserStripeTerminal: setUserStripeTerminal
    }

    return (
        <ExpoContext.Provider value={providerValues} >
            {children}
        </ExpoContext.Provider>
    )
};

export default ExpoContext;
export { ExpoContextProvider };
