import * as yup from "yup";

const mandatoryMessage = "Obligatoriskt";

export const editPickupLocationValidationSchema = yup
    .object()
    .shape(
        {
            "type": yup
                .string()
                .required(mandatoryMessage),
            "name": yup
                .string()
                .required(mandatoryMessage)
        }
    );
