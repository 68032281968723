import { Button, Card, CardActions, CardContent, CardHeader, CircularProgress, createStyles, Tab, Tabs, Theme, WithStyles, withStyles } from '@material-ui/core';
import { Formik } from 'formik';
import React from 'react';
import { RouteChildrenProps, withRouter } from 'react-router-dom';
import { Person } from '../../../model/Person';
import { ApiBackend } from '../../../providers/apibackend';
import FirebaseContext from '../../../providers/Firebase/context';
import withAlert, { IAlertContext } from '../../Common/dialog/Alert';
import DirtyPageChecker from '../../Common/DirtyPageChecker';
import SplitButton from '../../Common/SplitButton/SplitButton';
import EditPersonFormContent from './EditPersonFormContent';
import MergePersonSearch from './MergePersonSearch';
import PersonEntries from './PersonEntries';
import validationSchema from './PersonValidationSchema';
import PersonOrders from './PersonOrders';
import TabPanel from '../../Common/TabPanel';
import EntrySeedingCard from '../Entry/EntrySeedingCard';
import PersonCounters from './PersonCounters';

interface IProps extends RouteChildrenProps<{ id: string }> {
}

interface IState {
    loading: boolean;
    person: Person,
    mergePerson: boolean;
    activeTab: number;
}

class EditPerson extends React.Component<WithStyles & IProps & IAlertContext, IState> {
    static contextType = FirebaseContext;

    private readonly api: ApiBackend;

    constructor(props: WithStyles & IProps & IAlertContext) {
        super(props);
        this.state = { person: null, loading: true, mergePerson: false, activeTab: 0 };
        this.api = new ApiBackend();
        this.loadPerson();
    }


    private handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        this.setState({ activeTab: newValue });
    };

    render() {
        const { classes } = this.props;
        const { person, loading, activeTab } = this.state;
        const isEditPersonMode = !!this.state.person?.id;

        const cardHeader = (): string => {
            if (person.firstName && person.lastName) {
                return `Hantera ${person.firstName} ${person.lastName}`;
            }

            return `Hantera ${person.vasaId}`
        };

        return (
            <>
                {loading &&
                    <CircularProgress color="primary" />
                }
                {person &&
                    <Formik
                        initialValues={person}
                        validationSchema={validationSchema}
                        onReset={(values): void => this.setState({ person: values })}
                        onSubmit={async (values, { resetForm }) => {
                            const person = await this.api.savePerson(values);
                            resetForm({ values: person });
                        }}
                    >
                        {formik => {
                            const { dirty, handleSubmit, handleReset, isSubmitting } = formik;

                            const isFormDisabled = this.isInitialEmptyPerson();
                            const isSaveDisabled = !dirty || isSubmitting || isFormDisabled;

                            return (
                                <DirtyPageChecker isDirty={dirty} getTitle={this.getTitle} {...this.props}>
                                    <form autoComplete="off" onSubmit={handleSubmit}>
                                        <Card>
                                            <CardHeader className={classes.cardHeader} title={cardHeader()} />
                                            <CardContent className={classes.form}>
                                                <EditPersonFormContent isEditMode={isEditPersonMode} {...formik} classes={classes} />

                                                {isEditPersonMode && <>
                                                    <Tabs
                                                        value={activeTab}
                                                        className={classes.tabs}
                                                        onChange={this.handleTabChange}
                                                    >
                                                        <Tab label="Anmälningar" />
                                                        <Tab label="Ordrar" />
                                                        <Tab label="Resultat" />
                                                        <Tab label="Räknare" />
                                                    </Tabs>
                                                    <TabPanel value={activeTab} index={0}>
                                                        <PersonEntries
                                                            isModalMode={false}
                                                            isFormDisabled={isFormDisabled}
                                                            isPersonDirty={dirty}
                                                            personId={this.props.match.params.id}
                                                            personEmail={formik.values.email}
                                                        />
                                                    </TabPanel>
                                                    <TabPanel value={activeTab} index={1}>
                                                        <PersonOrders
                                                            isPersonDirty={dirty}
                                                            person={person}
                                                        />
                                                    </TabPanel>

                                                    <TabPanel value={activeTab} index={2}>
                                                        <EntrySeedingCard person={person} alwaysExpanded={true} title="Resultat" />
                                                    </TabPanel>
                                                    <TabPanel value={activeTab} index={3}>
                                                        <PersonCounters
                                                            isPersonDirty={dirty}
                                                            person={person}
                                                        />
                                                    </TabPanel>
                                                </>}

                                                {isEditPersonMode && !this.state.mergePerson && !isFormDisabled &&
                                                    <Button color="secondary"
                                                        onClick={() => {
                                                            if (dirty) {
                                                                this.props.showAlert("Personen har osparade ändringar!", "Spara för att slå samman person.");
                                                                return;
                                                            }
                                                            this.setState({ mergePerson: true })
                                                        }}>
                                                        Slå samman person med...
                                                    </Button>
                                                }
                                                {isEditPersonMode && this.state.mergePerson &&
                                                    <MergePersonSearch
                                                        sourcePerson={this.state.person}
                                                        onClose={() => { }}
                                                    />
                                                }
                                            </CardContent>
                                            <CardActions>
                                                {!isFormDisabled &&
                                                    <SplitButton disabled={isFormDisabled}>
                                                        <Button type="submit" color="secondary" disabled={isSaveDisabled} variant="contained">Spara</Button>
                                                        <Button type="submit" color="secondary" disabled={isFormDisabled} variant="text">Forcera Spara</Button>
                                                    </SplitButton>
                                                }
                                                <Button style={{ marginLeft: 10 }} color="secondary" disabled={!dirty} variant="contained" onClick={handleReset}>Avbryt</Button>
                                            </CardActions>
                                        </Card>
                                    </form>
                                </DirtyPageChecker>
                            )
                        }}
                    </Formik>
                }
            </>
        )
    }

    private isInitialEmptyPerson = () => {
        const { person } = this.state;

        return person.id && (!person.firstName || !person.lastName);
    };

    private loadPerson = async (): Promise<void> => {
        const person = await this.api.getPerson(this.props.match.params.id);
        this.setState({
            loading: false,
            person: person
        });
    };

    private getTitle = (): string | undefined => {
        const { person } = this.state;

        if (person?.firstName && person?.lastName) {
            return `Kundkort: ${person.firstName} ${person.lastName}`;
        }
    };
}

const useStyles = ({ palette, spacing }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
    photo: {
        height: '30px',
        verticalAlign: 'middle',
        borderRadius: '10px'
    },
    root: {

    },
    form: {
        '& > *': {
            margin: spacing(1)
        },
        '& label.Mui-focused': {
            color: palette.secondary.main,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: palette.secondary.main,
        },
    }
});

export default withRouter(withAlert(withStyles(useStyles)(EditPerson)));
