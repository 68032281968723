export type PickupItemStatus = "NEW" | "IN_PROGRESS" | "DONE" | "DELIVERED" | "NOT_DELIVERED" | "CANCELED";
export const PickupItemStatuses: PickupItemStatus[] = ["NEW", "IN_PROGRESS", "DONE", "DELIVERED", "NOT_DELIVERED", "CANCELED"];

export class PickupItem {
    id: string;
    locationId: string;
    publicId: string;
    status: PickupItemStatus;

    created?: Date;
    createdBy?: string;
    updated?: Date;
    updatedBy?: string;

    metadata?: { key: string, value: string }[];
    message?: string;
}
