import { SortOrder } from "../../../utilities/sortmodel";
import { ComparatorType, IColumnDefinition, Sticky } from "./models";

const getCustomComparator = <T>(
    order: SortOrder,
    comparator: ComparatorType<T>
): (
    a: T,
    b: T,
) => number => {
    return order === "asc"
        ? (a, b) => comparator(a, b)
        : (a, b) => -comparator(a, b);
};

const stableSort = <T>(array: readonly T[], comparator: ComparatorType<T>) => {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
};

const stickyFirstCol: React.CSSProperties = {
    position: "sticky",
    left: 0,
    zIndex: 1,
    backgroundColor: "#fff",
    borderRight: "1px solid rgba(224, 224, 224, 1)",
    borderBottom: "1px solid rgba(224, 224, 224, 1)"
};

const stickyLastCol: React.CSSProperties = {
    position: "sticky",
    right: 0,
    zIndex: 1,
    backgroundColor: "#fff",
    borderLeft: "1px solid rgba(224, 224, 224, 1)",
    borderBottom: "1px solid rgba(224, 224, 224, 1)"
};

const getCellStyle = <T>(idx: number, sticky: Sticky, columnDefinitions: IColumnDefinition<T>[]): React.CSSProperties => {
    if (sticky === "left" && idx === 0) {
        return { ...stickyFirstCol };
    }

    if (sticky === "right" && idx === columnDefinitions.length - 1) {
        return { ...stickyLastCol };
    }

    return {};
};

const getRowsPerPageOptions = (maxRows?: number) => {
    if (!maxRows) {
        return [5, 10, 25, 100];
    }

    const max = Math.max(100, maxRows);
    if (max <= 100) {
        return [5, 10, 25, 100];
    }

    return [5, 10, 25, 100, max];
};

export {
    // descendingComparator,
    // getComparator,
    getCustomComparator,
    stableSort,
    getCellStyle,
    getRowsPerPageOptions
};

