import React, { useState } from "react";
import { PatchRestrictions, PatchablePropertiesWithId } from "./utils";
import { Grid } from "@material-ui/core";
import { PatchPickupItemDocumentProps } from "../../../providers/models";
import useDidMountEffect from "../../Common/UseDidMountEffect";
import StatusRenderer from "./PropertyRenderers/StatusRenderer";
import { PickupItemStatus } from "../../../model/PickupItem";


interface IProps {
    items: PatchablePropertiesWithId[];
    patchProperties: (keyof PatchPickupItemDocumentProps)[];

    restrictions?: PatchRestrictions;

    onChange: (nextValue: Partial<PatchPickupItemDocumentProps>) => void;
}

const PatchLocationItemsDialogContent = (props: IProps) => {
    const [patchItem, setPatchItem] = useState({} as PatchPickupItemDocumentProps);

    useDidMountEffect(() => {
        props.onChange(patchItem);
    }, [patchItem]);

    const handleChange = (propName: keyof PatchPickupItemDocumentProps, nextValue: any) => {
        const nextItem = { ...patchItem };
        nextItem[propName] = nextValue;
        setPatchItem(nextItem);
    };

    const getRenderer = (propName: keyof PatchPickupItemDocumentProps, key: number) => {

        switch (propName) {
            case "status":
                return (
                    <StatusRenderer
                        key={key}
                        defaultValues={props.items.map(x => x.status)}
                        restrictedStatuses={props.restrictions?.status}
                        onChange={(nextValue: PickupItemStatus) => {
                            handleChange(propName, nextValue);
                        }}
                    />
                );

            default:
                return null;
        }
    };

    const render = () => {

        return (
            <Grid container spacing={2}>
                {props.patchProperties.map(getRenderer)}
            </Grid>
        );
    }

    return render();
}

export default PatchLocationItemsDialogContent;
