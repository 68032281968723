import { useState } from "react";
import { ListEntry } from "../../../../model/Entry";
import { ApiBackend } from "../../../../providers/apibackend";
import React from "react";
import SearchFilter, { Filters } from "../../../Common/SearchFilter/SearchFilter";
import { ListEntriesParameters } from "../../../../providers/models";
import useDidMountEffect from "../../../Common/UseDidMountEffect";
import EnhancedTable from "../../../Common/EnhancedTable/EnhancedTable";
import { IColumnDefinition } from "../../../Common/EnhancedTable/models";
import { vasaloppetDateTimeGetterFormatter } from "../../../../utilities/date";
import { Card, CardContent, CardHeader, createStyles, Grid, IconButton, Theme, withStyles, WithStyles } from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import ReceiptIcon from "@material-ui/icons/Receipt";
import EditEntryDialog from "../../../Entries/Entry/EditEntryDialog";
import ManageOrder from "../../../Orders/ManageOrder";
import VasaloppetTooltip from "../../../Common/VasaloppetTooltip";
import withConfirm, { IConfirmContext } from "../../../Common/dialog/Confirm";

type EditB2bCompanyEntriesProps = {
    companyId: string;
    companyName: string;
}
type SearchFilterType = Pick<ListEntriesParameters, "eventId" | "productVariant">;

const EditB2bCompanyEntries = (props: EditB2bCompanyEntriesProps & WithStyles & IConfirmContext) => {
    const [searchFilter, setSearchFitler] = useState<Filters<SearchFilterType>>(null);
    const [loading, setLoading] = useState(false);
    const [entries, setEntries] = useState<ListEntry[]>([]);
    const [editEntryId, setEditEntryId] = useState<string | null>(null);
    const [editOrderId, setEditOrderId] = useState<string | null>(null);

    const maxRows = 500;
    const backend = new ApiBackend();

    useDidMountEffect(() => {
        performSearch();
    }, [searchFilter]);

    const performSearch = async () => {
        const { companyId } = props;

        setLoading(true);

        const entriesResponse = await backend.listEntries({
            company: companyId,
            eventId: searchFilter.eventId as string,
            productVariant: searchFilter.productVariant as string[],
            status: "ACTIVE",
            begin: 0,
            limit: 500
        });
        setEntries(entriesResponse?.entries);
        setLoading(false);
    };

    const handleEditOrderClick = async (row: ListEntry) => {
        const entry = await backend.getEntry(row.id);
        setEditOrderId(entry.orderId);
    };

    const handleRemoveEntryClick = async (row: ListEntry) => {
        const { companyId, showConfirm } = props;
        const confirmResult = await showConfirm(`Är du säker på att du vill ta bort ${row.firstName} ${row.lastName}`);

        if (!confirmResult) {
            return;
        }

        const removeResult = await backend.removeB2bCompanyEntry(companyId, row.id);
        if (removeResult) {
            await performSearch();
        }
    };

    const isEmptyEntry = (row: ListEntry): boolean => !row.firstName && !row.lastName;

    const columnDefinitions: IColumnDefinition<ListEntry>[] = [
        { propName: "lastName", label: "Efternamn" },
        { propName: "firstName", label: "Förnamn" },
        { propName: "eventName", label: "Lopp" },
        { propName: "bibtext", label: "Startnummer" },
        { propName: "startgroupName", label: "Startled" },
        { propName: "vasaId", label: "VASA-ID" },
        { propName: "itemPrice", label: "Pris" },
        { propName: "clubName", label: "Klubb" },
        {
            propName: "orderDate",
            label: "Orderdatum",
            valueFormatter: (row) => vasaloppetDateTimeGetterFormatter(row.orderDate, "---"),
            sortValueGetter: (row) => vasaloppetDateTimeGetterFormatter(row.orderDate, "---")
        },
        {
            propName: "status",
            label: "Status",
            valueFormatter: (row) => isEmptyEntry(row) ? "Ledig plats" : row.status,
            sortValueGetter: (row) => isEmptyEntry(row) ? "Ledig plats" : row.status
        },
        {
            renderCell: (row) => {
                const canBeEdited = !isEmptyEntry(row);

                return (<>
                    <IconButton color="default" size="small"
                        onClick={async () => {
                            await handleEditOrderClick(row);
                        }}
                    >
                        <VasaloppetTooltip title="Visa order">
                            <ReceiptIcon fontSize="medium" />
                        </VasaloppetTooltip>

                    </IconButton>
                    {canBeEdited && (<>
                        <IconButton color="default" size="small"
                            onClick={() => {
                                setEditEntryId(row.id);
                            }}
                        >
                            <VasaloppetTooltip title="Hantera anmälan">
                                <Edit fontSize="medium" />
                            </VasaloppetTooltip>
                        </IconButton>
                        <IconButton color="default" size="small"
                            onClick={async () => {
                                await handleRemoveEntryClick(row);
                            }}
                        >
                            <VasaloppetTooltip title="Ta bort anmälan">
                                <DeleteIcon fontSize="medium" />
                            </VasaloppetTooltip>

                        </IconButton>
                    </>)}
                </>);
            }
        },
    ];

    const render = () => {
        const { companyId, companyName, classes } = props;

        return (<>
            <Card>
                <CardHeader
                    className={classes.cardHeader}
                    title={`Aktiva anmälningar kopplade till ${companyName}`}
                />

                <CardContent>
                    <>
                        <SearchFilter<SearchFilterType>
                            id={`company-entries-filter-${companyId}`}
                            filters={{
                                "eventId": {
                                    id: "filter-event-period",
                                    type: "EventId",
                                    label: "Evenemang",
                                    size: 4
                                },
                                "productVariant": {
                                    id: "filter-productVariant",
                                    type: "ProductList",
                                    label: "Lopp",
                                    size: 4,
                                    productTypes: ["race"],
                                    useVariants: true,
                                    multiple: true
                                },
                            }}
                            persist={true}
                            onInit={(nextValue: Filters<SearchFilterType>) => {
                                setSearchFitler(nextValue);
                            }}
                            onChange={(nextValue: Filters<SearchFilterType>) => {
                                setSearchFitler(nextValue);
                            }}
                        />
                        <Grid container className={classes.root} spacing={2}>
                            <Grid item xs={12}>
                                <EnhancedTable<ListEntry>
                                    columnDefinitions={columnDefinitions}
                                    data={entries ?? []}
                                    pageSize={10}
                                    maxRows={maxRows}
                                    paginationMode="client"
                                    sortingMode="client"
                                    loading={loading}
                                    dense
                                    sticky="right"
                                />
                            </Grid>
                        </Grid>
                    </>
                </CardContent>
            </Card>
            {editEntryId &&
                <EditEntryDialog
                    entryId={editEntryId}
                    onAbortEdit={() => {
                        setEditEntryId(null);
                    }}
                    onSave={async () => {
                        setEditEntryId(null);
                        await performSearch();
                    }}
                />
            }
            {editOrderId &&
                <ManageOrder orderId={editOrderId}
                    close={async () => {
                        setEditOrderId(null);
                        await performSearch();
                    }}
                />
            }
        </>);
    };

    return render();
};

const useStyles = ({ palette }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    }
});

export default withStyles(useStyles)(withConfirm(EditB2bCompanyEntries));
