/**
 * Vasaloppet Mina Sidor
 * Author: Peter Löfås, peter@lofas.se
 */

import React, { ChangeEvent, Fragment } from 'react'
import { withRouter, RouteComponentProps, Link } from 'react-router-dom';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ManageEvents from './Events/ManageEvents';
import ManagePaymentChannels from './Orders/ManagePaymentChannels';
import ManageUsers from './Users/ManageUsers';
import ManageCronJobs from './ManageCronJobs';
import ManagePOSPlaces from './ManagePOSPlaces';
import ManageDiplomas from './Events/ManageDiplomas';
import ManageCustomSettings from './CustomSetting/ManageCustomSettings';

interface State {
   curTab: any;
}

class ManageSettings extends React.Component<RouteComponentProps, State> {
   constructor(props: RouteComponentProps) {
      super(props);
      this.state = { curTab: 0 };
   }

   changeTab = (ev: any, tab: number) => {
      this.setState({ curTab: tab });
   };

   a11yProps(index: any) {
      return {
         id: `simple-tab-${index}`,
         key: `simple-tab-${index}`,
         'aria-controls': `simple-tabpanel-${index}`,
      };
   }

   render() {
      return (
         <Fragment>
            <Tabs value={this.state.curTab} onChange={this.changeTab} style={{ marginBottom: 5 }}>
               <Tab label="Evenemang" {...this.a11yProps(0)} />
               <Tab label="Betalsätt" {...this.a11yProps(1)} />
               <Tab label="Användare" {...this.a11yProps(2)} />
               <Tab label="Schemalagda jobb" {...this.a11yProps(3)} />
               <Tab label="POS Terminaler" {...this.a11yProps(4)} />
               <Tab label="Diplom" {...this.a11yProps(5)} />
               <Tab label="Settings" {...this.a11yProps(6)} />
            </Tabs>
            {this.state.curTab == 0 &&
               <ManageEvents />
            }
            {this.state.curTab == 1 &&
               <ManagePaymentChannels />
            }
            {this.state.curTab == 2 &&
               <ManageUsers />
            }
            {this.state.curTab == 3 &&
               <ManageCronJobs />
            }
            {this.state.curTab == 4 &&
               <ManagePOSPlaces />
            }
            {this.state.curTab == 5 &&
               <ManageDiplomas />
            }
            {this.state.curTab == 6 &&
               <ManageCustomSettings />
            }
         </Fragment>
      );
   }
}

export default withRouter(ManageSettings);
