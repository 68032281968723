import { Grid, MenuItem } from "@material-ui/core";
import { FormikProps } from "formik";
import React from "react";
import { Person } from "../../../model/Person";
import { ApiBackend } from "../../../providers/apibackend";
import FormCbxField from "../../Common/FormCbxField";
import FormCountryPickerDropdown from "../../Common/FormCountryPickerDropDown";
import FormDateField from "../../Common/FormDateField";
import FormSelectField from "../../Common/FormSelectField";
import FormTextField from "../../Common/FormTextField";

interface IProps extends FormikProps<Person> {
    readonly?: boolean;
    allowEditVasaId?: boolean;
}

class RenderPersonFields extends React.Component<IProps> {
    private previousPostCodeValue: string | undefined;
    private timeout: number;
    private readonly apiBackend: ApiBackend;

    constructor(props: IProps) {
        super(props);

        this.apiBackend = new ApiBackend();
    }

    private getPostCode = () => {
        const { values, setFieldValue } = this.props;
        this.previousPostCodeValue = values.postCode;
        this.apiBackend.getPostCode(values.postCode)
            .then(result => {
                if (result) {
                    setFieldValue("city", result.locality);
                    setFieldValue("county", result.county);
                }
            });
    }

    private shouldGetPostCode(): boolean {
        const { values, touched } = this.props;
        if (!touched.postCode) {
            return false;
        }

        if (values.country !== "SWE") {
            return false;
        }

        const trimmedValue = values?.postCode?.replace(/\s/g, "");
        if (isNaN(trimmedValue as any) || trimmedValue.length !== 5) {
            return false;
        }

        return !this.previousPostCodeValue || this.previousPostCodeValue !== values.postCode;
    }

    render(): JSX.Element {
        const { values, allowEditVasaId, readonly, setFieldValue,setFieldTouched } = this.props;

        if (this.shouldGetPostCode()) {
            if (this.timeout) {
                clearTimeout(this.timeout);
            }
            this.timeout = window.setTimeout(() => {
                this.getPostCode();
            }, 400);
        }

        return (<>
            <Grid item xs={6}>
                <FormTextField {...this.props}
                    disabled={readonly || !allowEditVasaId}
                    propName="vasaId"
                    label="Vasa ID"
                />
            </Grid>
            <Grid item xs={6}>
                <FormTextField {...this.props}
                    disabled
                    propName="externalId"
                    label="PersonID (SportsPro)"
                />
            </Grid>
            <Grid item xs={6}>
                <FormTextField {...this.props}
                    propName="firstName"
                    label="Förnamn"
                    disabled={readonly}
                />
            </Grid>
            <Grid item xs={6}>
                <FormTextField {...this.props}
                    propName="lastName"
                    label="Efternamn"
                    disabled={readonly}
                />
            </Grid>
            <Grid item xs={6}>
                <FormTextField {...this.props}
                    propName="email"
                    label="E-post"
                    disabled={readonly}
                />
            </Grid>
            <Grid item xs={6}>
                <FormTextField {...this.props}
                    propName="mobileNumber"
                    label="Mobilnr."
                    disabled={readonly}
                />
            </Grid>
            <Grid item xs={3}>
                <FormDateField {...this.props}
                    disabled={readonly}
                    propName="birthDay"
                    label="Födelsedatum"
                    inputProps={{ min: '1800-01-01', max: '9999-12-31' }}
                    valueFormatter={() => values.birthDay ?? ''}
                    onChange={() => (e: any) => {
                        let nexValue = e.target.value;
                        if (!nexValue) {
                            nexValue = null;
                        }
                        setFieldValue("birthDay", nexValue);
                    }}
                />
            </Grid>
            <Grid item xs={3}>
                <FormSelectField {...this.props}
                    propName="gender"
                    label="Kön"
                    disabled={readonly}
                >
                    <MenuItem value="male">Man</MenuItem>
                    <MenuItem value="female">Kvinna</MenuItem>
                </FormSelectField>
            </Grid>
            <Grid item xs={6}>
                <FormCountryPickerDropdown {...this.props}
                    propName="nationality"
                    label="Nationalitet"
                    disabled={readonly}
                >
                </FormCountryPickerDropdown>
            </Grid>
            <Grid item xs={12}>
                <FormTextField {...this.props}
                    propName="address"
                    label="Adress"
                    disabled={readonly}
                />
            </Grid>
            <Grid item xs={12}>
                <FormTextField {...this.props}
                    propName="address_line2"
                    disabled={readonly}
                />
            </Grid>
            <Grid item xs={6}>
                <FormTextField {...this.props}
                    propName="postCode"
                    label="Postnummer"
                    disabled={readonly}
                />
            </Grid>
            <Grid item xs={6}>
                <FormTextField {...this.props}
                    propName="city"
                    label="Ort"
                    disabled={readonly}
                />
            </Grid>
            <Grid item xs={6}>
                <FormTextField {...this.props}
                    propName="county"
                    label="Län"
                    disabled={readonly}
                />
            </Grid>
            <Grid item xs={6}>
                <FormCountryPickerDropdown {...this.props}
                    propName="country"
                    label="Land"
                    disabled={readonly}
                >
                </FormCountryPickerDropdown>
            </Grid>
            <Grid item xs={4}>
                <FormTextField {...this.props}
                    propName="veteranId"
                    label="Veteran-ID"
                    disabled={readonly}
                />
            </Grid>
            <Grid item xs={4}>
                <FormTextField {...this.props}
                    propName="veteranYear"
                    label="Veteran År"
                    disabled={readonly}
                />
            </Grid>
            <Grid item xs={4}>
                <FormTextField {...this.props}
                    propName="fisCode"
                    label="FIS-Kod"
                    disabled={readonly}
                />
            </Grid>
            <Grid item xs={3}>
                <FormCbxField {...this.props}
                    propName="deceased"
                    label="Är avliden"
                    disabled={readonly}
                />
            </Grid>
            <Grid item xs={9}>
                <FormCbxField {...this.props}
                    propName="excludeWeb"
                    label="Anonym på webben?"
                    disabled={readonly}
                />
            </Grid>
            <Grid item xs={3}>
                <FormCbxField {...this.props}
                    propName="noCommercial"
                    label="Ingen reklam"
                    disabled={readonly}
                />
            </Grid>
            <Grid item xs={3}>
                <FormCbxField {...this.props}
                    propName="noDataToThirdParty"
                    label="Dela inte data med 3:e part"
                    disabled={readonly}
                />
            </Grid>
            <Grid item xs={6}>
                <FormCbxField {...this.props}
                    propName="noMailExceptConfirmation"
                    label="Ingen epost förutom orderrelaterad"
                    disabled={readonly}
                />
            </Grid>
            <Grid item xs={12}>
              <FormSelectField {...this.props}
                    valueGetter={() => { return this.props && this.props.values.forcedCommunicationLanguage ?  this.props.values.forcedCommunicationLanguage : "none"}}
                    onChange={() => (e) => {
                        const val = e.target.value == "none" ? null : e.target.value;
                        setFieldValue("forcedCommunicationLanguage", val);
                        setFieldTouched("forcedCommunicationLanguage", true);
                    }}
                    propName="forcedCommunicationLanguage"
                    label="Forcera kommunication på språk"
                    disabled={readonly}
                >
                    <MenuItem value="none">Använd standard</MenuItem>
                    <MenuItem value="sv">Svenska</MenuItem>
                    <MenuItem value="en">Engelska</MenuItem>
                </FormSelectField>
            </Grid>
        </>)
    }
}

export default RenderPersonFields;
