import React from "react";

import { Divider, Grid, Typography } from "@material-ui/core";
import { PickupItem } from "../../../model/PickupItem";
import { vasaloppetDateTimeGetterFormatter } from "../../../utilities/date";
import { statusToText } from "../expo/medaljgravering/utils";

const translations: Record<string, string> = {
	id: "ID",
	locationId: "Plats ID",
	publicId: "Publikt ID",
	status: "Status",
	created: "Skapad",
	createdBy: "Skapad av",
	updated: "Uppdaterad",
	updatedBy: "Uppdaterad av",
	metadata: "Metadata",
	message: "Meddelande",
};

const isDateKey = (key: string) => ["created", "updated"].includes(key);
const formatKeyValue = (key: string, value: any) => {
	if (isDateKey(key)) {
		return vasaloppetDateTimeGetterFormatter(value);
	}

	if (key === "status") {
		return statusToText(value, false);
	}

	return String(value);
};

const PickupItemInformation = ({ item }: { item: PickupItem }) => {
	const { metadata, message, ...rest } = item;

	return (
		<Grid container spacing={4}>
			{metadata && metadata.length > 0 && (
				<Grid item xs={12}>
					<Typography variant="h5" style={{ marginBottom: "0.5em" }}>
						{translations.metadata}
					</Typography>

					<Grid container spacing={2}>
						{metadata.map(({ key, value }) => (
							<React.Fragment key={key}>
								<Grid item xs={4}>
									<Typography variant="body1" style={{ fontWeight: "bold" }}>
										{key}
									</Typography>
								</Grid>
								<Grid item xs={8}>
									<Typography variant="body1">{value}</Typography>
								</Grid>
							</React.Fragment>
						))}
					</Grid>
					<Divider style={{ marginTop: "1em" }} />
				</Grid>
			)}

			{message && (
				<Grid item xs={12}>
					<Typography variant="h5" style={{ marginBottom: "0.5em" }}>
						{translations.message}
					</Typography>
					<Typography variant="body1">{message}</Typography>
					<Divider style={{ marginTop: "1em" }} />
				</Grid>
			)}

			<Grid item xs={12}>
				<Typography variant="h5" style={{ marginBottom: "0.5em" }}>
					Övrig information
				</Typography>
				<Grid container spacing={2}>
					{Object.entries(rest).map(
						([key, value]) =>
							value !== undefined && (
								<React.Fragment key={key}>
									<Grid item xs={4}>
										<Typography variant="body1" style={{ fontWeight: "bold" }}>
											{translations[key] || key}:
										</Typography>
									</Grid>
									<Grid item xs={8}>
										{value && <Typography variant="body1">{formatKeyValue(key, value)}</Typography>}
									</Grid>
								</React.Fragment>
							)
					)}
				</Grid>
			</Grid>
		</Grid>
	);
};

export default PickupItemInformation;
