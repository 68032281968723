import React, { useEffect, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, Typography } from "@material-ui/core";
import EventIdFilter from "../../../../Common/SearchFilter/EventIdFilter";

interface IChangeEvent {
    isComplete: boolean;
    data?: ISearchFilterEvent
}

type Props = {
    buttonLabel: string;
    onInit: (data: IChangeEvent) => void;
    onChange: (data: IChangeEvent) => void;
};

export interface ISearchFilterEvent {
    eventId: string;
    eventName: string;
}

const localStorageKey = "EventSelectorButton-filter"
const saveSearchFilter = (value: ISearchFilterEvent): void => {
    localStorage.setItem(localStorageKey, JSON.stringify(value));
};

const getSearchFilter = (): ISearchFilterEvent | undefined => {
    const filter = localStorage.getItem(localStorageKey);

    if (filter) {
        return JSON.parse(filter);
    }
};

const EventSelectorButton = (props: Props) => {
    const initialFilter = getSearchFilter();
    const [searchFilterEvent, setSearchFilterEvent] = useState<ISearchFilterEvent>(initialFilter);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        props.onInit({
            isComplete: !!searchFilterEvent,
            data: !!searchFilterEvent ? searchFilterEvent : undefined
        });
    }, []);

    const handleSave = () => {
        saveSearchFilter(searchFilterEvent);
        setOpen(false);
        props.onChange({
            isComplete: !!searchFilterEvent,
            data: !!searchFilterEvent ? searchFilterEvent : undefined
        });
    };

    const handleAbort = () => {
        setSearchFilterEvent(getSearchFilter());
        setOpen(false);
    };

    const render = () => {

        return (
            <>
                <Button color="secondary" variant="text" onClick={() => setOpen(true)}>
                    {props.buttonLabel}
                </Button>
                {
                    open && (
                        <Dialog
                            disableEscapeKeyDown
                            fullWidth={true}
                            maxWidth="md"
                            aria-labelledby="event-selector-button-title"
                            open={true}
                        >
                            <DialogTitle id="event-selector-button-title">
                                Välj Event
                            </DialogTitle>
                            <DialogContent dividers>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        {initialFilter &&
                                            <Typography variant="body2">Nuvarande event: {initialFilter.eventName}</Typography>
                                        }
                                    </Grid>

                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <EventIdFilter
                                                label="Välj event"
                                                useWildCardSelect={false}
                                                clearable={true}
                                                initialValue={searchFilterEvent?.eventId ?? ""}
                                                onChange={(eventId, eventName) => {
                                                    setSearchFilterEvent({
                                                        ...searchFilterEvent,
                                                        eventId: eventId,
                                                        eventName: eventName
                                                    });
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    type="button"
                                    color="secondary"
                                    disabled={!searchFilterEvent?.eventId}
                                    variant="contained"
                                    onClick={handleSave}
                                >
                                    Spara
                                </Button>
                                <Button
                                    style={{ marginLeft: 10 }}
                                    color="secondary"
                                    variant="contained"
                                    onClick={handleAbort}
                                >
                                    Avbryt
                                </Button>
                            </DialogActions>
                        </Dialog>
                    )
                }
            </>
        );
    };

    return render();
};

export default EventSelectorButton;
