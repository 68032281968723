import { Box, Checkbox, TableCell, TableHead, TableRow, TableSortLabel, WithStyles } from "@material-ui/core";
import React from "react";
import { IColumnDefinition, Sticky } from "./models";
import { getCellStyle } from "./utils";
import { SortOrder } from "../../../utilities/sortmodel";

export interface EnhancedTableHeadProps<T> {
    columnDefinitions: IColumnDefinition<T>[];
    sortOrder: SortOrder;
    sortBy: keyof T;
    sticky: Sticky;
    selectable: boolean;
    numSelected: number;
    selectableCount: number;

    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof T) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const EnhancedTableHead = <T extends unknown>(props: EnhancedTableHeadProps<T> & WithStyles) => {
    const { classes, sortOrder, sortBy, columnDefinitions, selectable, numSelected, selectableCount, onRequestSort, onSelectAllClick } = props;
    const createSortHandler = (property: keyof T) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };

    const key = (def: IColumnDefinition<T>, idx: number): string | number => {
        if (def.propName) {
            return `${def.propName as string}_${idx}`;
        }

        return idx;
    };

    const renderCell = (columnDefinition: IColumnDefinition<T>, idx: number): JSX.Element => {
        if (columnDefinition.sortable === false) {
            return (
                <TableCell
                    style={getCellStyle(idx, props.sticky, columnDefinitions)}
                    key={key(columnDefinition, idx)}
                >
                    {columnDefinition.propName && (
                        <Box component="span" style={{ whiteSpace: "nowrap" }}>
                            {columnDefinition.label}
                        </Box>
                    )}
                </TableCell>
            );
        } else {
            return (
                <TableCell
                    style={getCellStyle(idx, props.sticky, columnDefinitions)}
                    key={key(columnDefinition, idx)}
                    align={columnDefinition.align ?? "left"}
                    sortDirection={sortBy === columnDefinition.propName ? sortOrder : false}
                >
                    {columnDefinition.propName && (
                        <TableSortLabel
                            active={sortBy === columnDefinition.propName}
                            direction={sortBy === columnDefinition.propName ? sortOrder : "asc"}
                            onClick={createSortHandler(columnDefinition.propName)}
                        >
                            <Box component="span" style={{ whiteSpace: "nowrap" }}>
                                {columnDefinition.label}
                                {sortBy === columnDefinition.propName && sortOrder ? (
                                    <span className={classes.visuallyHidden}>
                                        {sortOrder === "desc" ? "sorted descending" : "sorted ascending"}
                                    </span>
                                ) : null}
                            </Box>
                        </TableSortLabel>
                    )}
                </TableCell>
            );
        }
    };

    return (
        <TableHead>
            <TableRow>
                {selectable &&
                    <TableCell padding="checkbox">
                        <Checkbox
                            color="primary"
                            indeterminate={numSelected > 0 && numSelected < selectableCount}
                            checked={selectableCount > 0 && numSelected === selectableCount}
                            onChange={onSelectAllClick}
                            inputProps={{
                                'aria-label': 'select all desserts',
                            }}
                        />
                    </TableCell>
                }

                {columnDefinitions.map((columnDefinition, idx) => (
                    renderCell(columnDefinition, idx)
                ))}
            </TableRow>
        </TableHead>
    );
}

export default EnhancedTableHead;
