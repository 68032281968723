import React, { useEffect, useState } from "react";
import { Button, ButtonGroup, Grid } from "@material-ui/core";
import { Entry, EntryTransfer } from "../../../../model/Entry";
import { vasaloppetDateTimeGetterFormatter } from "../../../../utilities/date";
import { Alert } from "@material-ui/lab";
import { ApiBackend } from "../../../../providers/apibackend";
import EditEntryDialog from "../EditEntryDialog";

interface IProps {
    entry: Entry;
    entryTransfer: EntryTransfer;
}

const EntryTransferCard = ({ entry, entryTransfer }: IProps) => {
    const [targetEntryId, setTargetEntryId] = useState(null as string);
    const [showDialog, setShowDialog] = useState(false);

    const isSeller = entryTransfer.entryId === entry.id;
    const isBuyer = !isSeller && (entryTransfer.entryCodeCreated === entry.usedEntryCode || entryTransfer.targetEntryId === entry.id);
    const backend = new ApiBackend();

    useEffect(() => {
        fetchTargetEntry();
    }, []);

    const fetchTargetEntry = async () => {
        let entryMatch: string;

        if (isSeller && !!entryTransfer.entryCodeCreated) {
            const entriesResult = await backend.listEntries({ code: entryTransfer.entryCodeCreated, noCount: true });
            if (entriesResult?.entries && entriesResult.entries.length > 0) {
                entryMatch = entriesResult.entries[0].id;
            }
        } else if (isSeller && !!entryTransfer.targetEntryId) {
            entryMatch = entryTransfer.targetEntryId;
        } else if (isBuyer) {
            entryMatch = entryTransfer.entryId;
        }

        if (!!entryMatch) {
            setTargetEntryId(entryMatch);
        }
    };

    const formatSeller = () => {
        return isSeller ? <strong>{entryTransfer.actionInitiatedBy}</strong> : entryTransfer.actionInitiatedBy;
    };

    const formatBuyer = () => {
        return isBuyer ? <strong>{entryTransfer.receiverEmail}</strong> : entryTransfer.receiverEmail;
    };

    const renderAlertContent = () => {
        if (isSeller) {
            const isCompleteSale = (!!entryTransfer.entryCodeCreated || entryTransfer.targetEntryId);

            if (isCompleteSale) {
                return (
                    <Grid item xs={12}>
                        <Alert severity="info">
                            <strong>
                                Den här anmälan har överlåtits till {entryTransfer.receiverEmail}
                            </strong>
                        </Alert>
                    </Grid>
                );
            }

            return (
                <Grid item xs={12}>
                    <Alert severity="info">
                        <strong>
                            Den här anmälan har en pågående överlåtelse till {entryTransfer.receiverEmail}
                        </strong>
                    </Alert>
                </Grid>
            );
        }

        if (isBuyer) {
            return (
                <Grid item xs={12}>
                    <Alert severity="info">
                        <strong>
                            Den här anmälan har överlåtits från {entryTransfer.actionInitiatedBy}
                        </strong>
                    </Alert>
                </Grid>
            );
        }

        return null;
    };

    const render = () => {

        return (<>
            <Grid container spacing={2} style={{ border: "1px solid #ccc", marginTop: "1em", width: "100%" }} >
                {renderAlertContent()}
                <Grid item xs={3}>Säljare: </Grid>
                <Grid item xs={9}>{formatSeller()}</Grid>
                <Grid item xs={3}>Köpare: </Grid>
                <Grid item xs={9}>{formatBuyer()}</Grid>
                <Grid item xs={3}>Kod: </Grid>
                <Grid item xs={9}>{entryTransfer.entryCodeCreated}</Grid>
                <Grid item xs={3}>Status: </Grid>
                <Grid item xs={9}>{entryTransfer.status}</Grid>
                <Grid item xs={3}>Skapad: </Grid>
                <Grid item xs={9}>{vasaloppetDateTimeGetterFormatter(entryTransfer.created)}</Grid>
                {targetEntryId &&
                    <Grid item xs={12}>
                        <ButtonGroup color="secondary">
                            <Button
                                onClick={() => {
                                    setShowDialog(true);
                                }}
                            >
                                Visa kopplad anmälan
                            </Button>
                        </ButtonGroup>
                    </Grid>
                }
            </Grid>
            {showDialog && targetEntryId &&
                <EditEntryDialog
                    entryId={targetEntryId}
                    onAbortEdit={() => {
                        setShowDialog(false);
                    }}
                />
            }
        </>)
    };

    return render();
};

export default EntryTransferCard;
