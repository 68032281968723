import { Sex } from "./CommonTypes";

export class B2bCompany {
    id: string;
    name: string;
    description?: string;
    orgNumber: string;
    address: string;
    postCode: string;
    city: string;
    country: string;
    email: string;
    reference: string;
    status: string;
    isArchived: boolean;
    enforceCustomProducts: boolean;
    code?: string;
    codeIsActive: boolean;
    registrationLink?: string;
    areEntriesSellable: boolean;
    existsInExternalAccountingSystem: boolean;
    areEntriesRemovable: boolean;
    category?: string;
    created?: Date;
    createdBy?: string;
    updated?: Date;
    updatedBy?: string;
    admins?: B2bCompanyAdmin[];
    products?: B2bCompanyProduct[];
    surveys?: B2bCompanySurvey[];
}

export class B2bCompanyAdmin {
    email: string;
    firstName: string;
    lastName: string;
    mobilePhone: string;
}

export class B2bCompanyProduct {
    id: string;
    productId: string;
    productVariantId: string;
    productVariantPriceGroupId: string;
    name: string;
    description: string;
    enforcedStartGroupId?: string;
    isActive: boolean;
    validFrom?: string;
    validTo?: string;
    maxNumRegistrations?: number;
    numRegistred?: number;
    extraFields?: B2bCompanyProductExtraField[];
    aggregationGroup?: string;
    aggregationGroupNumRegistered?: number;

    b2bInvoice: boolean;
    b2bInvoicePriceGroupId?: string;
    b2bInvoiceCreated?: Date;
    b2bInvoiceCreatedBy?: string;
    b2bInvoiceExternalRef?: string;
}

export class B2bCompanyProductExtraField {
    id: string;
    productId: string;
    productVariantId: string;
    productVariantPriceGroupId: string;
    name: string;
    type: string;
    required: boolean;
    validValues?: string;
    description?: string;
    useAsTeamInEntry?: boolean;
}

export const CompanySurveyTypes = ["UNSPECIFIED", "RELAY_POOL"];
export type CompanySurveyType = typeof CompanySurveyTypes[number];
export const CompanySurveyTypesLookup: { [key: string]: string } = {
    "UNSPECIFIED": "Ospecificerad",
    "RELAY_POOL": "Stafettlag"
}

export class B2bCompanySurvey {
    id: string;
    companyProductId?: string;
    type: CompanySurveyType;
    name: string;
    description: string;
    requiresLogin: boolean;
    canParticipantsUnregister: boolean;
    validTo?: string;
    published: boolean;
    isArchived: boolean;
    lastModified: Date;
    questions?: B2bCompanySurveyQuestion[];
    participants?: B2bCompanySurveyParticipant[];
}

export type CompanySurveyQuestionType = "choice" | "text";
export class B2bCompanySurveyQuestion {
    id: string;
    type: CompanySurveyQuestionType;
    name: string;
    description?: string;
    validValues?: string[];
    required: boolean;
}

export type CompanySurveyParticipantStatus = "REGISTERED" | "UNREGISTERED";
export class B2bCompanySurveyParticipant {
    id: string;
    surveyId: string;
    personId?: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    address: string;
    postalCode: string;
    city: string;
    country: string;
    nationality: string;
    dateOfBirthYear: number;
    dateOfBirthMonth: number;
    dateOfBirthDay: number;
    sex: Sex;
    answer: string;
    status: CompanySurveyParticipantStatus;
    created: Date;
    updated: Date;
}

export type B2bCompanyCreate = Pick<B2bCompany,
    "name" | "description" | "orgNumber" | "address" | "postCode" | "city" | "country" | "email" | "reference"
>;