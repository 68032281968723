import { Box, Grid, MenuItem, WithStyles } from "@material-ui/core";
import { FormikProps } from "formik";
import React from "react";
import FormCbxField from "../Common/FormCbxField";
import FormTextField from "../Common/FormTextField";
import { PickupItemLocation, PickupItemLocations } from "../../model/PickupItemLocation";
import { EditMode } from "./models";
import FormSelectField from "../Common/FormSelectField";
import FormMetadataField from "../Common/FormMetadataField";

interface IProps extends FormikProps<PickupItemLocation> {
    mode: EditMode;
}

const EditPickupLocationFormContent = (props: IProps & WithStyles) => {
    const render = (): JSX.Element => {
        const { values, classes } = props;

        return (
            <Grid container className={classes.root} spacing={2} style={{ width: "100%" }}>
                <Grid item xs={12}>
                    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                        {props.mode === "edit" &&
                            <FormCbxField {...props}
                                propName={"isArchived"}
                                label="Arkiverad"
                            />
                        }
                    </Box>
                </Grid>
                <Grid item xs={4}>
                    <FormSelectField {...props}
                        propName="type"
                        label="Typ"
                        valueGetter={() => values.type ?? ""}
                    >
                        {PickupItemLocations.map((x, idx) => {
                            return <MenuItem key={idx} value={x}>{x}</MenuItem>
                        })}
                    </FormSelectField>
                </Grid>
                <Grid item xs={8}>
                    <FormTextField {...props}
                        propName="name"
                        label="Namn"
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormTextField {...props}
                        propName="description"
                        label="Beskrivning"
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormMetadataField {...props}
                        label="Metadata"
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormTextField {...props}
                        propName="messageTemplate"
                        label="Meddelandemall (snabbmeddelanden)"
                        multiline
                        minRows={2}
                        maxRows={5}
                    />
                </Grid>
            </Grid>
        )
    }

    return render();
}

export default EditPickupLocationFormContent;
