import React, { useState } from "react";
import { FormControl, Grid, InputLabel, MenuItem } from "@material-ui/core";
import { PickupItemStatus, PickupItemStatuses } from "../../../../model/PickupItem";
import useDidMountEffect from "../../../Common/UseDidMountEffect";
import ClearableSelect from "../../../Common/ClearableSelect";

interface IProps {
    restrictedStatuses?: PickupItemStatus[];
    defaultValues?: PickupItemStatus[];
    onChange: (nextValue: PickupItemStatus) => void;
}

const initialValue = (statuses?: PickupItemStatus[]): PickupItemStatus | "" => {
    if (!statuses) {
        return "";
    }

    return statuses.every(x => x === statuses[0]) ? statuses[0] : "";
};

const StatusRenderer = (props: IProps) => {
    const [value, setValue] = useState(initialValue(props.defaultValues));

    useDidMountEffect(() => {
        props.onChange(value as PickupItemStatus);
    }, [value]);

    const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
        setValue(evt.target.value as PickupItemStatus);
    };

    const render = () => {
        const statuses = props.restrictedStatuses ? props.restrictedStatuses : PickupItemStatuses;

        return (
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <InputLabel shrink>Status</InputLabel>
                    <ClearableSelect
                        value={value}
                        clearable={false}
                        onChange={handleChange}
                    >
                        {statuses.map((x, idx) => {
                            return <MenuItem key={idx} value={x}>{x}</MenuItem>;
                        })}
                    </ClearableSelect>
                </FormControl>
            </Grid>
        );
    }

    return render();
}

export default StatusRenderer;
