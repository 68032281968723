import React from "react";
import { Button, Box, Card, CardContent, CardHeader, Popover, WithStyles, FormControlLabel, Checkbox } from "@material-ui/core";
import { FieldArray, FieldArrayRenderProps, FormikProps } from "formik";
import { B2bCompany, B2bCompanyProduct } from "../../../../model/B2bCompany";
import SelectProductPopover from "../Popovers/SelectProductPopover";
import EditB2bCompanyCustomProduct from "./EditB2bCompanyCustomProduct";
import { ApiBackend } from "../../../../providers/apibackend";
import { Product } from "../../../../model/Product";
import { CheckBox } from "@material-ui/icons";

interface IState {
	popupOpen: boolean;
	anchorEl: HTMLButtonElement;
	sourceProducts: Product[];
	includeInactive: boolean;
}

class EditB2bCompanyCustomProducts extends React.Component<WithStyles & FormikProps<B2bCompany>, IState> {
	private readonly api: ApiBackend;

	constructor(props: WithStyles & FormikProps<B2bCompany>) {
		super(props);
		this.state = {
			popupOpen: false,
			anchorEl: undefined,
			sourceProducts: [],
			includeInactive: false
		};
		this.api = new ApiBackend();
	}

	async componentDidMount(): Promise<void> {
		this.updateAllSourceProducts();
	}

	render(): JSX.Element {
		const { popupOpen, anchorEl, includeInactive } = this.state;
		const { classes } = this.props;
		const { products } = this.props.values;

		const aggregationGroupValues = [...products.map(x => x.aggregationGroup).filter(Boolean).sort()];
		const uniqueValues = aggregationGroupValues.reduce((acc, curr) => {
			if (!acc.includes(curr))
				acc.push(curr);
			return acc;
		}, []);

		const defaultBoxStyle = {
			display: "flex",
			flexDirection: "row",
		};

		return (this.state.sourceProducts &&
			<Card>
				<FieldArray
					name="products"
					render={arrayHelpers => (
						<>
							<CardHeader
								className={classes.cardHeader}
								title="Hantera produkter"
								action={
									<Box {...defaultBoxStyle} style={{ gap: "16px", marginLeft: "auto", marginTop: 8, marginRight: 8 }}>
										<FormControlLabel
											label="Visa även inaktiva produkter"
											control={
												<Checkbox style={{ color: "#fff" }}
													checked={includeInactive}
													onChange={(ev) => {
														this.setState({ includeInactive: ev.target.checked });
													}}
												/>
											}
										/>
										<Button color="primary" variant="text"
											onClick={this.handleAddProduct}
										>
											Lägg till produkt
										</Button>
										<Popover
											open={popupOpen}
											anchorEl={anchorEl}
											onClose={this.handleAddProductClose}
											anchorOrigin={{
												vertical: 'top',
												horizontal: 'center',
											}}
											transformOrigin={{
												vertical: 'bottom',
												horizontal: 'center',
											}}
											PaperProps={{
												style: {
													width: "500px"
												}
											}}>
											<SelectProductPopover
												onChange={(product) => this.handleOnPopoverChange(product, arrayHelpers)}
											/>
										</Popover>
									</Box>
								}
							/>
							<CardContent>
								{products.map((product, idx) => {
									const sourceProduct = this.state.sourceProducts.find((sourceProduct) => sourceProduct.id === product.productId);
									const showItem = includeInactive || !includeInactive && product.isActive;
									return sourceProduct && showItem && <EditB2bCompanyCustomProduct {...this.props}
										key={idx}
										idx={idx}
										product={product}
										sourceProduct={sourceProduct}
										aggregationGroupValues={uniqueValues}
										arrayHelpers={arrayHelpers}
										onDelete={(idx: number) => this.handleOnDeleteProduct(idx, arrayHelpers)}
										onSave={(product: B2bCompanyProduct) => this.handleOnSaveProduct(idx, product, arrayHelpers)}
									/>
								})}
							</CardContent>
						</>
					)} />
			</Card>
		)
	}

	private handleOnDeleteProduct = (idx: number, arrayHelpers: FieldArrayRenderProps) => {
		arrayHelpers.remove(idx);
	};

	private handleOnSaveProduct = (idx: number, product: B2bCompanyProduct, arrayHelpers: FieldArrayRenderProps) => {
		arrayHelpers.replace(idx, product);
	}

	private handleAddProduct = (e: any) => {
		this.setState({ popupOpen: true, anchorEl: e.currentTarget });
	};

	private handleAddProductClose = () => {
		this.setState({ popupOpen: false, anchorEl: undefined });
	};

	private handleOnPopoverChange = async (product: B2bCompanyProduct, arrayHelpers: FieldArrayRenderProps) => {
		const sourceProductExists = this.state.sourceProducts.some((p) => p.id == product.productId);
		if (!sourceProductExists) {
			await this.addSourceProduct(product.productId);
		}

		this.handleAddProductClose();
		arrayHelpers.push(product);
	};

	private updateAllSourceProducts = async (): Promise<void> => {
		const sourceProductIds = this.props.values.products.map((product) => product.productId);
		const uniqueProductIds = sourceProductIds.filter((v, i, a) => a.indexOf(v) === i);
		const uniqueSourceProducts: Product[] = await Promise.all(uniqueProductIds.map(async (productId): Promise<Product> => {
			return await this.api.getProduct(productId);
		}));
		this.setState({
			sourceProducts: uniqueSourceProducts
		});
	}

	private addSourceProduct = async (productId: string): Promise<void> => {
		const newSourceProduct = await this.api.getProduct(productId);
		this.setState(prevState => {
			return {
				...prevState,
				sourceProducts: [
					...prevState.sourceProducts,
					newSourceProduct
				]
			}
		});
	}
}

export default EditB2bCompanyCustomProducts;