import React, { useRef } from "react";
import { useState } from "react";
import { PickupItemLocation } from "../../../../../model/PickupItemLocation";
import { Box, Typography } from "@material-ui/core";
import EventSelectorButton, { ISearchFilterEvent } from "./EventSelectorButton";
import SearchEntry, { SearchEntryRef } from "./parts/SearchEntry";
import useDidMountEffect from "../../../../Common/UseDidMountEffect";
import { IListEngravingEntry } from "../../../../../model/ListEngravingEntry";
import ConfirmEngravingDialog from "./parts/ConfirmEngravingDialog";

type Props = {
    data: PickupItemLocation;
};

const Kontrollen = (props: Props) => {
    const [searchFilterEvent, setSearchFilterEvent] = useState<ISearchFilterEvent>(null);
    const [entry, setEntry] = useState<IListEngravingEntry>(null);

    const searchEntryRef = useRef<SearchEntryRef>(null);

    useDidMountEffect(() => {
        if (searchEntryRef.current) {
            searchEntryRef.current.reset();
        }
    }, [searchFilterEvent]);

    const render = () => {

        if (!searchFilterEvent?.eventId) {
            return (
                <Box>
                    <Typography variant="h5">Välj event innan du kan söka anmälningar</Typography>
                    <EventSelectorButton
                        buttonLabel="Välj Event"
                        onInit={(value) => {
                            setSearchFilterEvent(value.isComplete ? value.data : null);
                        }}
                        onChange={(value) => {
                            setSearchFilterEvent(value.isComplete ? value.data : null);
                        }}
                    />
                </Box>
            );
        }

        const containerBoxStyle: React.CSSProperties = {
            display: "flex",
            gap: "16px",
            marginTop: "16px",
            flexDirection: "column"
        };

        return (
            <Box style={containerBoxStyle}>
                <Box style={{ display: "flex", justifyContent: "center" }}>
                    <Typography variant="h5">
                        {searchFilterEvent.eventName}
                    </Typography>
                    <EventSelectorButton
                        buttonLabel="Byt event"
                        onInit={(value) => {
                            setSearchFilterEvent(value.isComplete ? value.data : null);
                        }}
                        onChange={(value) => {
                            setSearchFilterEvent(value.isComplete ? value.data : null);
                        }}
                    />
                </Box>
                <Box style={{ display: "flex", justifyContent: "center" }}>
                    <SearchEntry
                        ref={searchEntryRef}
                        searchFilterEvent={searchFilterEvent}
                        onChange={(nextValue) => {
                            setEntry(nextValue);
                        }}
                    />
                    {/* {getStepContent()} */}
                </Box>
                {entry &&
                    <ConfirmEngravingDialog
                        entry={entry}
                        pickupItemLocation={props.data}
                        onAbort={() => {
                            setEntry(null);
                        }}
                        onComplete={() => {
                            setEntry(null);
                            if (searchEntryRef.current) {
                                searchEntryRef.current.reset();
                            }
                        }}
                    />
                }
            </Box>
        );
    };

    return render();
};

export default Kontrollen;
