import { Button, ButtonGroup, Card, CardContent, CardHeader, createStyles, Dialog, DialogActions, DialogContent, DialogTitle, Grid, LinearProgress, TextField, Theme, Typography, withStyles, WithStyles } from '@material-ui/core';
import React, { Fragment } from 'react';
import { Order } from '../../model/Order';
import { ApiBackend } from '../../providers/apibackend';
import GetAppIcon from '@material-ui/icons/GetApp';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import Check from '@material-ui/icons/CheckCircle';
import Failed from '@material-ui/icons/Cancel';
import Modal from '@material-ui/core/Modal/Modal';
import EmailIcon from '@material-ui/icons/Email';
import { OrderPayment } from '../../model/Payment';
import ManageOrderContact from './ManageOrderContact';
import OrderMessages from './OrderMessages';
import ManageOrderItems from './ManageOrderItems';
import ManageOrderEditItems from './ManageOrderEditItems/ManageOrderEditItems';
import { vasaloppetDateTimeGetterFormatter } from '../../utilities/date';
import AddPaymentDialog from './AddPaymentDialog';
import CompanyTypography from '../Common/CompanyTypography';
import OrderPaymentDialog from './Payments/OrderPaymentDialog';
import { getPaymentDiff, getTotalAmountPaid, isEqualWithTolerance } from './Payments/utils';
import ManageOrderCompanyMetadata from './ManageOrderCompanyMetadata/ManageOrderCompanyMetadata';
import CreateAllowanceDialog from './CreateAllowanceDialog/CreateAllowanceDialog';

interface Props {
    orderId: string;
    close: () => void;
}
interface State {
    currentOrderId: string;
    order: Order;
    haveChanges: boolean;
    saving: boolean;
    payments: OrderPayment[];
    addPayment: boolean;
    payCardSwish: boolean;
    isLoadingPaymentHistory: boolean;
    changeOrder: boolean;
    showCreateAllowance: boolean;
}

class ManageOrder extends React.Component<Props & WithStyles, State> {
    private readonly api: ApiBackend = new ApiBackend();;

    constructor(props: Props & WithStyles) {
        super(props);
        this.state = {
            currentOrderId: this.props.orderId,
            order: null,
            haveChanges: false,
            saving: false,
            addPayment: false,
            payCardSwish: false,
            payments: null,
            isLoadingPaymentHistory: true,
            changeOrder: false,
            showCreateAllowance: false
        };
    }

    handleNavigateToOrder = async (publicOrderId: string) => {
        const nextOrder = await this.api.retreiveOrderByPublicId(publicOrderId);
        this.setState({ currentOrderId: nextOrder.id, payments: null, isLoadingPaymentHistory: true }, () => {
            this.updateData();
        });
    };

    handleClose = () => {
        this.props.close();
    };

    componentDidMount() {
        this.updateData();
    }

    async save() {
        let newOrder = await this.api.updateOrderItems(this.state.currentOrderId, this.state.order.items);
        this.setState({ order: newOrder });
    }

    updateData() {
        this.api.retreiveOrder(this.state.currentOrderId).then((order) => {
            this.setState({ order: order });
        });

        this.api.getOrderPaymentHistory(this.state.currentOrderId).then((payments) => {
            this.setState({ payments: payments, isLoadingPaymentHistory: false });
        });
    }

    downloadPdf = () => {
        window.location.href = ApiBackend.getPdfReceiptUrl(this.state.currentOrderId);
    };

    sendOrderConfirmation = () => {
        this.api.resendOrderconfirmation(this.state.currentOrderId).then(() => {
            alert("Orderbekräftelse skickad till kunden");
            this.updateData();
        });
    };

    sendPaymentLink = () => {
        this.api.sendOrderPaymentRequest(this.state.currentOrderId).then(() => {
            alert("Betallänk skickad till kund");
            this.updateData();
        });
    };

    renderPaymentDiff() {
        if (this.state.payments && this.state.order) {
            const totalAmountPayed = getTotalAmountPaid(this.state.order);

            if (!isEqualWithTolerance(totalAmountPayed, this.state.order.totalSum)) {
                return <>
                    <Grid item xs={12} style={{ background: 'red', color: 'white' }}>
                        <Typography variant="h5"><b>Betalt belopp stämmer inte</b></Typography>
                        <Typography variant="h5"><b>Ordersumma:</b> {this.state.order.totalSum}kr</Typography>
                        <Typography variant="h5"><b>Betalt:</b> {+(totalAmountPayed)}kr</Typography>
                        <Typography variant="h5"><b>Diff:</b> {getPaymentDiff(this.state.order)}kr</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant='contained' onClick={async (ev) => {
                            this.setState({ isLoadingPaymentHistory: true });

                            const beloppsreglering = await this.api.getBeloppsregleringPointOfSale();
                            const sum = this.state.order.totalSum - totalAmountPayed;
                            await this.api.addOrderPayment({
                                orderId: this.state.order.id,
                                paymentType: sum > 0 ? "PAYMENT" : "REVERSAL", amount: Math.abs(sum), pointOfSaleId: beloppsreglering.id, description: ApiBackend.getCurrentUserName() + ": Beloppsreglering"
                            })
                            let payments = await this.api.getOrderPaymentHistory(this.state.currentOrderId);
                            this.setState({ payments: payments, isLoadingPaymentHistory: false });
                        }}>Reglera med beloppsreglering</Button>
                    </Grid>
                </>;
            }
        }
        return null;
    }

    render() {
        const { classes } = this.props;
        const o = this.state.order;

        return <Dialog disableEnforceFocus
            maxWidth="lg"
            aria-labelledby="confirmation-dialog-title"
            open={true}
        >

            <DialogTitle id="confirmation-dialog-title">{this.state.order == null ? "Laddar" : "Order: " + o.publicId}</DialogTitle>
            <DialogContent dividers>
                {this.state.saving ? <Modal
                    open={true}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    <CircularProgress color="primary" />
                </Modal> : null}
                {this.state.order == null ? <LinearProgress color="primary" /> :
                    <Grid container spacing={2}>
                        <Grid item xs={2}>OrderId</Grid>
                        <Grid item xs={10}><b>{o.publicId}</b> / {o.id}</Grid>
                        <Grid item xs={2}>Skapad</Grid>
                        <Grid item xs={10}><b>{vasaloppetDateTimeGetterFormatter(o.OrderDate, "", true)}</b></Grid>
                        <Grid item xs={2}>Uppdaterad</Grid>
                        <Grid item xs={10}><b>{vasaloppetDateTimeGetterFormatter(o.LastUpdate, "", true)}</b></Grid>
                        <Grid item xs={2}>Status</Grid>
                        <Grid item xs={10}><b>{Order.translateStatus(o.Status)}</b></Grid>
                        <Grid item xs={2}>Företag</Grid>
                        <Grid item xs={10}>
                            <CompanyTypography b2bId={o.b2bId} style={{ fontWeight: "bold" }} />
                        </Grid>

                        <Grid item xs={12}>Länk till betal/kvittosida</Grid>
                        <Grid item xs={8}>
                            <TextField disabled value={ApiBackend.config.checkoutUrl + "checkout/" + o.id} fullWidth />
                        </Grid>
                        <Grid item xs={4}>
                            <ButtonGroup color="secondary" variant="text">
                                <Button onClick={() => { navigator.clipboard.writeText(ApiBackend.config.checkoutUrl + "checkout/" + o.id); }}>Kopiera</Button>
                                <Button onClick={() => { window.open(ApiBackend.config.checkoutUrl + "checkout/" + o.id, "_blank") }}>Öppna i nytt fönster</Button>
                            </ButtonGroup>
                        </Grid>
                        <Grid item xs={12}>
                            <ButtonGroup color="secondary" variant="text">
                                <Button onClick={this.downloadPdf}><GetAppIcon /> Ladda ner orderbekräftelse (PDF)</Button>
                                <Button onClick={this.sendOrderConfirmation}><EmailIcon /> Skicka orderbekräftelse via mail/sms</Button>
                                <Button disabled={o.Status == "Completed" && o.totalSum == 0} onClick={this.sendPaymentLink}><EmailIcon /> Skicka betallänk via mail</Button>
                                <Button onClick={() => {
                                    this.setState({ showCreateAllowance: true });
                                }}
                                >
                                    Friskvårdskvitto
                                </Button>
                            </ButtonGroup>
                        </Grid>
                        <Grid item xs={6}>
                            <ManageOrderContact order={this.state.order} dataChanged={() => { this.updateData(); }} />
                        </Grid>
                        <Grid item xs={6}>
                            <OrderMessages order={this.state.order} orderId={this.state.order.id} />
                        </Grid>
                        <Grid item xs={12}>
                            <ManageOrderItems
                                order={this.state.order}
                                onForceSave={async () => {
                                    return this.save();
                                }}
                                onNavigateToOrder={this.handleNavigateToOrder}
                                onChange={() => {
                                    this.updateData();
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button color="secondary" onClick={() => { this.setState({ changeOrder: true }) }}>Ändra beställning</Button>
                        </Grid>

                        {this.renderPaymentDiff()}

                        <Grid item xs={12}>
                            <ManageOrderCompanyMetadata order={this.state.order} />
                        </Grid>

                        <Grid item xs={12}>
                            <Card>
                                <CardHeader className={classes.cardHeader} title={<Fragment>Transaktioner</Fragment>} />
                                <CardContent>
                                    {this.state.isLoadingPaymentHistory &&
                                        <CircularProgress color="secondary" />
                                    }
                                    {this.state.payments &&
                                        <Grid container spacing={2} style={{ fontSize: '0.9em' }}>
                                            <Grid item xs={2}><b>Datum</b></Grid>
                                            <Grid item xs={2}><b>Beskrivning</b></Grid>
                                            <Grid item xs={1}><b>Typ</b></Grid>
                                            <Grid item xs={1}><b>Betalmedel</b></Grid>
                                            <Grid item xs={1}><b>Belopp</b></Grid>
                                            <Grid item xs={2}><b>ID</b></Grid>
                                            <Grid item xs={2}><b>Betalkanal</b></Grid>
                                            <Grid item xs={1}><b>Status</b></Grid>
                                            {this.state.payments.map((item, idx) => {
                                                return <Fragment key={idx}>
                                                    <Grid item xs={2}>{vasaloppetDateTimeGetterFormatter(item.created, "", true)}</Grid>
                                                    <Grid item xs={2}>{item.description}</Grid>
                                                    <Grid item xs={1}>{item.operation}</Grid>
                                                    <Grid item xs={1}>{item.instrument}</Grid>
                                                    <Grid item xs={1}>{item.amount}kr</Grid>
                                                    <Grid item xs={2}>{item.id}</Grid>
                                                    <Grid item xs={2}>{item.pointOfSale ? item.pointOfSale.name : ""}</Grid>
                                                    <Grid item xs={1}>
                                                        {item.pointOfSale && !item.pointOfSale.id && ((!item.status && item.operation=="Capture") || (item.operation == "Capture" &&  !item.instrument)) &&
                                                        <>
                                                            <Button onClick={ async () => {
                                                                let api = new ApiBackend();
                                                                await api.verifyPaymentOfOrder(this.props.orderId);
                                                                this.updateData();
                                                            }}>Kontrollera status</Button>
                                                        </>
                                                        }
                                                        {this.renderPaymentStatus(item.status)}
                                                    </Grid>
                                                </Fragment>
                                            })}
                                        </Grid>
                                    }
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12}>
                            <ButtonGroup color="secondary">
                                <Button variant="text"
                                    onClick={() => {
                                        this.setState({ addPayment: true });
                                    }}
                                >
                                    Lägg till betalning
                                </Button>
                                <Button variant="text"
                                    onClick={() => {
                                        this.setState({ payCardSwish: true });
                                    }}
                                >
                                    Betala med Kort/Swish
                                </Button>
                            </ButtonGroup>
                        </Grid>
                    </Grid>}

                {this.state.changeOrder &&
                    this.renderEditOrderItems()
                }
                {this.state.addPayment &&
                    this.renderAddPaymentDialog()
                }
                {this.state.payCardSwish &&
                    <OrderPaymentDialog
                        orderId={this.state.order.id}
                        onAbort={() => {
                            this.setState({ payCardSwish: false });
                        }}
                        onComplete={() => {
                            this.setState({ payCardSwish: false });
                            this.updateData();
                        }}
                    />
                }
                {this.state.showCreateAllowance &&
                    <CreateAllowanceDialog
                        mode="ORDER"
                        order={this.state.order}
                        onComplete={() => {
                            this.setState({ showCreateAllowance: false });
                        }}
                        onAbort={() => {
                            this.setState({ showCreateAllowance: false });
                        }}
                    />
                }
            </DialogContent>
            <DialogActions>
                <Button variant="contained" autoFocus onClick={this.handleClose} color="primary">
                    Stäng
                </Button>
            </DialogActions>
        </Dialog>;
    }

    renderAddPaymentDialog() {
        return <Dialog
            disableEscapeKeyDown
            maxWidth="lg"
            aria-labelledby="confirmation-dialog-title"
            open={true}
        >
            <DialogTitle id="confirmation-dialog-title">Lägg till betalning</DialogTitle>
            <DialogContent>
                <AddPaymentDialog close={() => {
                    this.updateData();
                    this.setState({ addPayment: false });
                }}
                    order={this.state.order}
                />
            </DialogContent>
        </Dialog>;
    }

    renderEditOrderItems() {
        return <Dialog
            disableEscapeKeyDown
            maxWidth="lg"
            aria-labelledby="confirmation-dialog-title"
            open={true}
        >
            <DialogTitle id="confirmation-dialog-title">
                Ändra beställning
            </DialogTitle>
            <DialogContent dividers>
                <ManageOrderEditItems
                    order={this.state.order}
                    onSaved={() => {
                        this.setState({ changeOrder: false });
                        this.updateData();
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="primary"
                    onClick={async () => {
                        this.updateData();
                        this.setState({ changeOrder: false })
                    }}
                >
                    Avbryt
                </Button>
            </DialogActions>
        </Dialog>;
    }

    renderPaymentStatus(status: string) {
        if (status == "Completed") {
            return <Check style={{ color: 'darkgreen' }} />;
        }
        else if (status == "Failed") {
            return <Failed style={{ color: 'darkred' }} />;
        }
        else {
            return <div>{status}</div>
        }
    }
}


const useStyles = ({ palette, spacing }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
    form: {
        '& > *': {
            margin: spacing(1),
            width: '25ch',
        },
        '& label.Mui-focused': {
            color: palette.secondary.main,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: palette.secondary.main,
        },
    }
}
);

export default withStyles(useStyles)(ManageOrder);
