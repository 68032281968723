import { GridSortModel as SortModel } from "@material-ui/data-grid";

export type SortOrder = "asc" | "desc";

type SortParams = {
    sortProp: string;
    sortDir: "asc" | "desc";
}

const sortModelEquals = (a: SortModel, b: SortModel) => {
    if (!a && !b) {
        return true;
    }

    if (!a || !b) {
        return false;
    }

    if (a.length !== b.length) {
        return false;
    }

    if (a.length === 0 || b.length === 0) {
        return false;
    }

    const aFirst = a[0];
    const bFirst = b[0];

    return aFirst.field === bFirst.field && aFirst.sort === bFirst.sort;
};

const sortModelToSortParams = (sortModel: SortModel): SortParams => {
    let sortProp: string;
    let sortDir: "asc" | "desc";

    if (sortModel && sortModel.length > 0) {
        const item = sortModel[0];
        sortProp = item.field;
        sortDir = item.sort;
    }

    return {
        sortProp: sortProp,
        sortDir: sortDir
    };
};

const descendingComparator = <T extends unknown>(a: T, b: T, orderBy: keyof T, valueGetter?: (row: T) => any) => {
    const aPropValue = valueGetter ? valueGetter(a) : a[orderBy];
    const bPropValue = valueGetter ? valueGetter(b) : b[orderBy];

    if (!aPropValue && !bPropValue) {
        return 0;
    }

    if (!bPropValue) {
        return -1;
    }

    if (!aPropValue) {
        return 1;
    }

    if (bPropValue < aPropValue) {
        return -1;
    }

    if (bPropValue > aPropValue) {
        return 1;
    }

    return 0;
};

const getComparator = <T>(
    order: SortOrder,
    orderBy: keyof T,
    valueGetter?: (row: T) => any
): (
    a: T,
    b: T,
) => number => {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy, valueGetter)
        : (a, b) => -descendingComparator(a, b, orderBy, valueGetter);
};

const orderBy = <T extends unknown>(items: T[], sortProp: keyof T, sortOrder: SortOrder = "asc"): T[] => {
    const comparator = getComparator<T>(sortOrder, sortProp);
    return items.sort(comparator);
};

const orderByCustomValue = <T extends unknown>(items: T[], valueGetter: (row: T) => any, sortOrder: SortOrder = "asc"): T[] => {
    const comparator = getComparator<T>(sortOrder, null, valueGetter);
    return items.sort(comparator);
};

export { sortModelEquals, sortModelToSortParams, getComparator, orderBy, orderByCustomValue };
