import FormControl from "@material-ui/core/FormControl/FormControl";
import InputLabel from "@material-ui/core/InputLabel/InputLabel";
import React, { useEffect, useState } from "react";
import { Entry } from "../../../model/Entry";
import { ApiBackend } from "../../../providers/apibackend";
import CompanySelect from "../../Common/CompanySelect";
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, LinearProgress, TextField } from "@material-ui/core";
import { B2bCompany } from "../../../model/B2bCompany";
import { Alert } from "@material-ui/lab";
import withConfirm, { IConfirmContext } from "../../Common/dialog/Confirm";

interface IProps {
	entry: Entry;
	disabled: boolean;
	onChange: () => void;
}

const SetEntryB2b = ({ entry, disabled, onChange }: IProps) => {
	const [initialized, setInitialized] = useState<boolean>(false);
	const [company, setCompany] = useState<B2bCompany>(null);
	const [showDialog, setShowDialog] = useState<boolean>(false);

	const backend = new ApiBackend();

	useEffect(() => {
		init();
	}, []);

	const init = async () => {
		await fetchAndSetCompany(entry.b2bId);
		setInitialized(true);
	};

	const fetchAndSetCompany = async (companyId?: string) => {
		if (!!companyId) {
			const company = await backend.getB2bCompany(companyId);
			setCompany(company);
		} else {
			setCompany(null);
		}
	}

	const hasCustomProductMatchForEntry = (b2bCompany?: B2bCompany): boolean => {
		return !!b2bCompany?.products?.some(x => x.productId === entry.productId && x.productVariantId === entry.variantId);
	};

	const render = () => {
		if (!initialized) {
			return null;
		}

		let label = "Företagskoppling";

		if (!!entry.b2bId && entry.b2bCustomProduct) {
			label += " (Anpassad produkt)";
		}

		return (<>
			<FormControl fullWidth style={{ flex: 1, margin: "0 3px", minWidth: "280px" }}>
				<TextField
					autoComplete="off"
					disabled
					label={label}
					value={company?.name ?? ""}
					fullWidth
					InputLabelProps={{
						shrink: true,
					}}
					InputProps={{
						endAdornment: (
							<Button color="secondary" variant="text"
								disabled={disabled}
								onClick={() => {
									setShowDialog(true);
								}}>
								Ändra
							</Button>
						)
					}}
				/>
			</FormControl>
			{showDialog &&
				<SetEntryB2bDialog
					entryId={entry.id}
					initCompanyName={company?.name}
					initB2bId={entry.b2bId}
					initB2bCustomProduct={!!entry.b2bCustomProduct}
					initCanChangeB2bCustomProduct={hasCustomProductMatchForEntry(company)}
					hasCustomProductMatchForEntry={hasCustomProductMatchForEntry}
					onAbort={() => {
						setShowDialog(false);
					}}
					onChange={async (b2bId?: string) => {
						await fetchAndSetCompany(b2bId);
						setShowDialog(false);
						onChange();
					}}
				/>
			}
		</>);
	};

	return render();
};

interface IDialogProps {
	entryId: string;
	initB2bId: string | null;
	initB2bCustomProduct: boolean;
	initCompanyName: string;
	initCanChangeB2bCustomProduct: boolean;
	hasCustomProductMatchForEntry: (b2bCompany?: B2bCompany) => boolean;
	onAbort: () => void;
	onChange: (b2bId?: string) => void;
}

const SetEntryB2bDialog = withConfirm(({ entryId, initCompanyName, initB2bId, initB2bCustomProduct, initCanChangeB2bCustomProduct, hasCustomProductMatchForEntry, onAbort, onChange, showConfirm }: IDialogProps & IConfirmContext) => {
	const [saving, setSaving] = useState(false);
	const [b2bId, setB2bId] = useState<string | null>(initB2bId);
	const [b2bCustomProduct, setB2bCustomProduct] = useState<boolean>(initB2bCustomProduct);
	const [canChangeB2bCustomProduct, setCanChangeB2bCustomProduct] = useState<boolean>(initCanChangeB2bCustomProduct);

	const backend = new ApiBackend();

	const handleChange = (nextValue: B2bCompany | null): void => {
		setB2bId(nextValue?.id);

		const companyHasProductMatch = hasCustomProductMatchForEntry(nextValue);
		setB2bCustomProduct(b2bCustomProduct && companyHasProductMatch);
		setCanChangeB2bCustomProduct(companyHasProductMatch);
	};

	const handleSave = async (): Promise<void> => {
		if (b2bId && canChangeB2bCustomProduct && !b2bCustomProduct) {
			const confirmResult = await showConfirm("Företaget har en anpassad produkt för detta lopp. Är du säker på att du inte vill koppla anmälan till den anpassade produkten?");
			if (!confirmResult) {
				return;
			}
		}

		setSaving(true);
		try {
			const res = await backend.setEntryB2b(entryId, b2bId, b2bCustomProduct ? b2bId : null);
			if (res) {
				onChange(b2bId);
			}
		} finally {
			setSaving(false);
		}
	};

	const hasChanges = (): boolean => {
		return initB2bId !== b2bId || initB2bCustomProduct !== b2bCustomProduct;
	};

	const render = () => {
		const isSaveDisabled = !hasChanges() || saving;
		const cbxLabel = b2bId && !canChangeB2bCustomProduct ? "Anpassad produkt (Saknas)" : "Anpassad produkt";

		return (
			<Dialog
				disableEscapeKeyDown
				fullWidth={true}
				maxWidth="sm"
				aria-labelledby="set-entry-b2b-title"
				open={true}>
				<DialogTitle id="set-entry-b2b-title">
					Ändra företagskoppling
				</DialogTitle>
				<DialogContent dividers style={{ minHeight: 300 }}>
					{saving &&
						<LinearProgress color="secondary" />
					}
					<Grid container spacing={2}>
						{initCompanyName &&
							<Grid item xs={12} style={{ marginBottom: 10 }}>
								<Alert severity="info">
									Ursprunglig företagskoppling: {initCompanyName} {initB2bCustomProduct ? " (Anpassad produkt)" : ""}
								</Alert>
							</Grid>
						}
						<Grid item xs={12}>
							<FormControl fullWidth>
								<InputLabel shrink style={{ lineHeight: 0 }}>
									Ny företagskoppling
								</InputLabel>
								<CompanySelect
									clearable={true}
									includeArchived={false}
									initialValue={b2bId}
									style={{ height: 36, marginTop: 8 }}
									onClear={() => {
										handleChange(null);
									}}
									onChange={(e: any, company: B2bCompany) => {
										handleChange(company);
									}}
								/>
							</FormControl>
						</Grid>
						<Grid item xs={12}>
							<FormControlLabel
								control={
									<Checkbox color="primary"
										disabled={!canChangeB2bCustomProduct}
										checked={b2bCustomProduct}
										onChange={(ev) => {
											setB2bCustomProduct(ev.target.checked)
										}}
									/>
								}
								label={cbxLabel}
							/>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button
						variant="contained"
						disabled={saving}
						onClick={onAbort}
						color="secondary">
						Avbryt
					</Button>
					<Button
						variant="contained"
						disabled={isSaveDisabled}
						onClick={handleSave}
						color="secondary">
						Spara
					</Button>
				</DialogActions>
			</Dialog>
		);
	};

	return render();
});

export default SetEntryB2b;
