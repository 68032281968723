import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	LinearProgress,
	Theme,
	WithStyles,
	createStyles,
	withStyles
} from "@material-ui/core";
import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import uuidv4 from "react-uuid";
import { PickupItem } from "../../../model/PickupItem";
import { PickupItemLocation } from "../../../model/PickupItemLocation";
import { ApiBackend } from "../../../providers/apibackend";
import { IPatchPickupItemRequest } from "../../../providers/models";
import { statusToText } from "../expo/medaljgravering/utils";
import CreatPickupItemFormContent from "./CreatPickupItemFormContent";
import EditPickupItemFormContent from "./EditPickupItemFormContent";
import { editPickupItemValidationSchema } from "./EditPickupitemValidationSchema";

interface BaseProps {
	locationId: string;
	mode: "CREATE" | "EDIT_STATUS" | "VIEW";
	onComplete: () => void;
	onAbort: () => void;
}

interface CreateProps extends BaseProps {
	mode: "CREATE";
	item?: never;
}

interface EditProps extends BaseProps {
	mode: "EDIT_STATUS";
	item: PickupItem;
}

interface ViewProps extends BaseProps {
	mode: "VIEW";
	item: PickupItem;
}

type Props = CreateProps | EditProps | ViewProps;

const PickupItemDialog = (props: Props & WithStyles) => {
	const { mode } = props;
	const [location, setLocation] = useState<PickupItemLocation>(null as PickupItemLocation);
	const [item, setItem] = useState<PickupItem>(mode !== "CREATE" ? props.item : (null as PickupItem));
	const [loading, setLoading] = useState(true);
	const didMount = useRef<boolean>(false);
	const backend = new ApiBackend();
	const formId = `create-pickup-item-form-${uuidv4()}`;

	// Run on load
	useEffect(() => {
		didMount.current = true;

		const init = async () => {
			if (mode === "CREATE") {
				const nextValue = new PickupItem();
				nextValue.status = "NEW";
				if (didMount.current) {
					setItem(nextValue);
				}
			}

			const nextLocation = await backend.getPickupItemLocation(props.locationId);
			if (didMount.current) {
				setLocation(nextLocation);
				setLoading(false);
			}
		};

		init();

		// unmount
		return () => {
			didMount.current = false;
		};
	}, []);

	const save = async (values: PickupItem): Promise<PickupItem> => {
		try {
			if (!didMount.current) return values;

			setLoading(true);

			if (mode === "CREATE") {
				return await backend.createPickupItem(props.locationId, values);
			}

			const request: IPatchPickupItemRequest = {
				patchDocument: [{ propName: "status", value: values.status }],
			};

			return await backend.patchPickupItem(props.locationId, values.id, request);
		} finally {
			if (didMount.current) setLoading(false);
		}
	};

	const handleOnSaveSuccessful = (values: PickupItem) => {
		props.onComplete();
	};

	const renderCreate = () => {
		const { classes, onAbort } = props;

		return (
			<>
				<DialogTitle id="edit-pickup-item-title">Lägg till avhämtning</DialogTitle>
				<Formik
					initialValues={item}
					validationSchema={editPickupItemValidationSchema}
					onReset={(values): void => {
						setItem(values);
						onAbort();
					}}
					onSubmit={async (values, { resetForm }) => {
						if (!didMount.current) return;

						const result = await save(values);

						if (result && didMount.current) {
							resetForm({ values: result });
							handleOnSaveSuccessful(result);
						}
					}}
				>
					{(formik) => {
						const { isValid, dirty, handleSubmit, handleReset, isSubmitting } = formik;
						const isSaveDisabled = !dirty || !isValid || isSubmitting;

						return (
							<>
								<DialogContent dividers>
									{loading && <LinearProgress color="secondary" />}
									{location && (
										<form id={formId} autoComplete="off" onSubmit={handleSubmit}>
											<CreatPickupItemFormContent location={location} {...formik} classes={classes} />
										</form>
									)}
								</DialogContent>
								<DialogActions>
									<Button form={formId} type="submit" color="secondary" disabled={isSaveDisabled} variant="contained">
										Spara
									</Button>
									<Button style={{ marginLeft: 10 }} color="secondary" variant="contained" onClick={handleReset}>
										{dirty ? "Avbryt" : "Stäng"}
									</Button>
								</DialogActions>
							</>
						);
					}}
				</Formik>
			</>
		);
	};

	const renderEditStatus = () => {
		const { classes, onAbort } = props;

		return (
			<>
				<DialogTitle id="edit-pickup-item-title">
					Redigera avhämtning {item.publicId} ({statusToText(item.status, false)})
				</DialogTitle>
				<Formik
					initialValues={item}
					validationSchema={editPickupItemValidationSchema}
					onReset={(values): void => {
						setItem(values);
						onAbort();
					}}
					onSubmit={async (values, { resetForm }) => {
						const result = await save(values);

						if (result) {
							resetForm({ values: result });
							handleOnSaveSuccessful(result);
						}
					}}
				>
					{(formik) => {
						const { isValid, dirty, handleSubmit, handleReset, isSubmitting } = formik;
						const isSaveDisabled = !dirty || !isValid || isSubmitting || mode === "VIEW";

						return (
							<>
								<DialogContent dividers>
									{loading && <LinearProgress color="secondary" />}
									{location && (
										<form id={formId} autoComplete="off" onSubmit={handleSubmit}>
											<EditPickupItemFormContent disabled={mode === "VIEW"} {...formik} classes={classes} />
										</form>
									)}
								</DialogContent>
								<DialogActions>
									{mode !== "VIEW" &&
										<Button form={formId} type="submit" color="secondary" disabled={isSaveDisabled} variant="contained">
											Spara
										</Button>
									}
									<Button style={{ marginLeft: 10 }} color="secondary" variant="contained" onClick={handleReset}>
										{dirty ? "Avbryt" : "Stäng"}
									</Button>
								</DialogActions>
							</>
						);
					}}
				</Formik>
			</>
		);
	};

	const render = () => {
		return (
			<Dialog disableEscapeKeyDown fullWidth={true} maxWidth="lg" aria-labelledby="edit-pickup-item-title" open={true}>
				{mode === "CREATE" && renderCreate()}
				{mode === "EDIT_STATUS" || mode === "VIEW" && renderEditStatus()}
			</Dialog>
		);
	};

	return render();
};

const useStyles = ({ palette, spacing }: Theme) =>
	createStyles({
		cardHeader: {
			background: palette.secondary.main,
			color: palette.secondary.contrastText,
			padding: 3,
		},
		photo: {
			height: "30px",
			verticalAlign: "middle",
			borderRadius: "10px",
		},
		root: {},
		form: {
			"& > *": {
				margin: spacing(1),
				width: "25ch",
			},
			"& label.Mui-focused": {
				color: palette.secondary.main,
			},
			"& .MuiInput-underline:after": {
				borderBottomColor: palette.secondary.main,
			},
		},
	});

export default withStyles(useStyles)(PickupItemDialog);
