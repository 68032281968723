import React, { useState } from "react";
import { Card, CardContent, CardHeader, IconButton } from "@material-ui/core";
import { Entry } from "../../../../model/Entry";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import EntryTransferCard from "./EntryTransferCard";
import { orderBy } from "../../../../utilities/sortmodel";

interface IProps {
    entry: Entry;
}

const EntryTransfersCard = ({ entry }: IProps) => {
    const [expanded, setExpanded] = useState(true);

    const render = () => {
        const { entryTransfers } = entry;

        return (
            <Card>
                <CardHeader
                    title="Försäljning"
                    action={
                        <IconButton
                            onClick={() => {
                                setExpanded(!expanded);
                            }}
                        >
                            {expanded ? (
                                <KeyboardArrowUp />
                            ) : (
                                <KeyboardArrowDown />
                            )}
                        </IconButton>
                    }
                />
                {expanded &&
                    <CardContent>
                        {orderBy(entryTransfers, "created").map((entryTransfer, idx) => {
                            return (
                                <EntryTransferCard key={idx} entry={entry} entryTransfer={entryTransfer} />
                            );
                        })}
                    </CardContent>
                }
            </Card>
        )
    };

    return render();
};

export default EntryTransfersCard;
