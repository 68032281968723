import React from "react";
import { Button, Box, Card, CardContent, CardHeader, WithStyles, CircularProgress, FormControlLabel, Checkbox } from "@material-ui/core";
import { FieldArray, FieldArrayRenderProps, FormikProps } from "formik";
import { B2bCompany, B2bCompanySurvey } from "../../../../model/B2bCompany";
import EditB2bCompanySurvey from "./EditB2bCompanySurvey";
import { Product } from "../../../../model/Product";
import { ApiBackend } from "../../../../providers/apibackend";

export type RaceRelayProduct = {
	productId: string;
	variantIds: string[];
}

interface IState {
	sourceRelayProducts: RaceRelayProduct[];
	loading: boolean;
	showArchived: boolean;
}

class EditB2bCompanySurveys extends React.Component<WithStyles & FormikProps<B2bCompany>, IState> {
	state: IState;

	private readonly api: ApiBackend;

	constructor(props: WithStyles & FormikProps<B2bCompany>) {
		super(props);

		this.api = new ApiBackend();
		this.state = {
			loading: true,
			sourceRelayProducts: [],
			showArchived: false
		}
	}

	async componentDidMount(): Promise<void> {
		await this.loadSourceProducts();
	}

	render(): JSX.Element {
		const { classes } = this.props;
		const { surveys } = this.props.values;
		const { loading, sourceRelayProducts, showArchived } = this.state;

		const defaultBoxStyle = {
			display: "flex",
			flexDirection: "row",
		};

		return (
			<Card>
				<FieldArray
					name="surveys"
					render={arrayHelpers => (
						<>
							<CardHeader
								className={classes.cardHeader}
								title="Hantera enkäter"
								action={
									<Box {...defaultBoxStyle} style={{ gap: "16px", marginLeft: "auto", marginTop: 8, marginRight: 8 }}>
										<FormControlLabel
											label="Visa även arkiverade enkäter"
											control={
												<Checkbox style={{ color: "#fff" }}
													checked={showArchived}
													onChange={(ev) => {
														this.setState({ showArchived: ev.target.checked });
													}}
												/>
											}
										/>
										<Button color="primary" variant="text"
											disabled={loading}
											onClick={() => this.handleAddSurvey(arrayHelpers)}
										>
											Lägg till enkät
										</Button>
									</Box>
								}
							/>
							<CardContent>
								{loading &&
									<CircularProgress color="secondary" />
								}
								{surveys && surveys.map((survey, idx) => {
									const showItem = showArchived || !showArchived && !survey.isArchived;
									return showItem && <EditB2bCompanySurvey {...this.props}
										key={idx}
										idx={idx}
										survey={survey}
										sourceRelayProducts={sourceRelayProducts}
										onDelete={(idx: number) => this.handleOnDeleteSurvey(idx, arrayHelpers)}
										onSave={(survey: B2bCompanySurvey) => this.handleOnSaveSurvey(idx, survey, arrayHelpers)}
									/>
								})}
							</CardContent>
						</>
					)} />
			</Card>
		)
	}

	private handleOnDeleteSurvey = (idx: number, arrayHelpers: FieldArrayRenderProps) => {
		arrayHelpers.remove(idx);
	};

	private handleOnSaveSurvey = (idx: number, survey: B2bCompanySurvey, arrayHelpers: FieldArrayRenderProps) => {
		arrayHelpers.replace(idx, survey);
	}

	private handleAddSurvey = (arrayHelpers: FieldArrayRenderProps) => {
		const emptySurvey = {
			companyProductId: null,
			type: "UNSPECIFIED",
			name: "",
			description: "",
			requiresLogin: false,
			canParticipantsUnregister: false,
			validTo: null,
			isArchived: false,
			published: false,
			questions: []
		} as B2bCompanySurvey;
		arrayHelpers.push(emptySurvey);
	};

	private loadSourceProducts = async (): Promise<void> => {
		const { values } = this.props;
		const { products: companyProducts } = values;

		const work: Promise<Product>[] = companyProducts.map(x => {
			return this.api.getProduct(x.productId);
		});

		const sourceProducts = await Promise.all(work);

		const mapper = (product: Product): RaceRelayProduct | false => {
			if (product?.type !== "race") {
				return false;
			}

			const relayVariants = product.variants?.filter(v => v.productVariantRaceData?.type === "relay");
			if (!relayVariants || relayVariants.length === 0) {
				return false;
			}

			return {
				productId: product.id,
				variantIds: relayVariants.map(v => v.Id)
			}
		};

		this.setState({
			loading: false,
			sourceRelayProducts: sourceProducts.map(mapper).filter(Boolean) as RaceRelayProduct[]
		});
	};
}

export default EditB2bCompanySurveys;