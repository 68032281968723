import React from "react";
import { useState } from "react";
import { Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, TextField, Typography } from "@material-ui/core";
import { ApiBackend } from "../../../../../../providers/apibackend";
import { IListEngravingEntry } from "../../../../../../model/ListEngravingEntry";
import { PickupItemLocation } from "../../../../../../model/PickupItemLocation";
import { PickupItem } from "../../../../../../model/PickupItem";
import withToaster, { IToasterContext } from "../../../../../Common/Toaster";
import { Alert } from "@material-ui/lab";

export type ConfirmEngravingDialogProps = {
    pickupItemLocation: PickupItemLocation;
    entry: IListEngravingEntry;
    onAbort: () => void;
    onComplete: () => void;
};

const ConfirmEngravingDialog = (props: ConfirmEngravingDialogProps & IToasterContext) => {
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");

    const backend = new ApiBackend();

    const handleSave = async () => {
        const { entry, onComplete, showToast } = props;
        try {
            setLoading(true);

            const item = new PickupItem();
            item.publicId = entry.bibtext;
            item.status = "IN_PROGRESS"; // Default to IN_PROGRESS instead of NEW
            item.message = message;
            item.metadata = [
                { key: "entryId", value: entry.id },
                { key: "firstName", value: entry.firstName },
                { key: "lastName", value: entry.lastName },
                { key: "productName", value: entry.productName },
                { key: "variantName", value: entry.variantName },
                { key: "isPreBooked", value: entry.isPreBooked ? "true" : "false" },
            ];

            // Deliver if prebooked and not delivered
            if (entry.isPreBooked && !entry.isDelivered) {
                const deliverResult = await backend.deliverOrderItem(entry.engravingOrderId, entry.engravingOrderItemId);
                if (!deliverResult) {
                    throw new Error("Could not deliver");
                }
            }

            // Create item
            const createdItem = await backend.createPickupItem(props.pickupItemLocation.id, item);
            if (!createdItem) {
                throw new Error("Could not create item");
            }

            onComplete();
        } catch {
            showToast("Någonting gick fel när vi försökte lägga beställning till graveraren", "error");
        } finally {
            setLoading(false);
        }
    };

    const handleAbort = () => {
        props.onAbort();
    };

    const renderAlertContent = () => {
        const { entry } = props;

        if (!entry.isPreBooked) {
            return (
                <Alert severity="info">Denna medaljgravering är inte förbokad. Ta betalt i POS-klienten och skicka därefter till graveraren.</Alert>
            );
        }

        if (entry.isDelivered) {
            return (
                <Alert severity="warning">Denna medaljgravering är förbokad, men redan utlämnad.</Alert>
            );
        } else {
            return (
                <Alert severity="info">Denna medaljgravering är förbokad.</Alert>
            );
        }
    };

    const renderMessageField = () => {
        const boxStyle: React.CSSProperties = { display: "flex", flexDirection: "column", gap: 5 };

        return (
            <FormControl fullWidth>
                <Box style={boxStyle}>
                    {renderChips()}
                    <TextField
                        style={{ flex: 1 }}
                        minRows={3}
                        maxRows={5}
                        multiline={true}
                        value={message ?? ""}
                        fullWidth
                        onChange={(e) => {
                            setMessage(e.target.value);
                        }}
                    >
                    </TextField>
                </Box>

            </FormControl>
        );
    };

    const handleChipClick = (templateText: string) => {
        const currentValue = message ?? "";
        let prefix = "";
        if (currentValue) {
            prefix = "\n";
        }

        const nextValue = currentValue + prefix + templateText;
        setMessage(nextValue);
    };

    const renderChips = () => {
        const { pickupItemLocation } = props;

        if (!pickupItemLocation?.messageTemplate) {
            return null;
        }

        const parts = pickupItemLocation.messageTemplate.split("\n");

        const outerBoxStyle = { display: "flex", gap: 5 };
        const chipBoxStyle = { display: "flex", flexFlow: "row wrap", gap: 5 };

        return (
            <Box style={outerBoxStyle}>
                <Box style={{ lineHeight: "32px" }}>
                    <Typography variant="caption">Snabbknappar</Typography>
                </Box>
                <Box style={chipBoxStyle}>
                    {parts.map((x, idx) => {
                        return (
                            <Chip key={idx} label={x} color="secondary"
                                onClick={() => {
                                    handleChipClick(x);
                                }}
                            >
                            </Chip>
                        )
                    })}
                </Box>
            </Box>
        );
    };

    const render = () => {
        const { entry } = props;

        const canSave = !!entry.bibtext;

        return (
            <Dialog
                disableEscapeKeyDown
                fullWidth={true}
                maxWidth="md"
                aria-labelledby="event-selector-button-title"
                open={true}
            >
                <DialogTitle id="event-selector-button-title">
                    Granska beställning och skicka till graverare
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            {renderAlertContent()}
                        </Grid>

                        {!canSave &&
                            <Grid item xs={12}>
                                <Alert severity="error">Anmälan saknar startnummer och kan inte skickas till graveraren.</Alert>
                            </Grid>
                        }

                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={3}>
                                    <Typography variant="body2">Startnummer</Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography variant="body2">{entry.bibtext}</Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography variant="body2">Förnamn</Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography variant="body2">{entry.firstName}</Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography variant="body2">Efternamn</Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography variant="body2">{entry.lastName}</Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography variant="body2">Lopp</Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography variant="body2">{entry.productName}</Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography variant="body2">Klass</Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography variant="body2">{entry.variantName}</Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography variant="body2">Förbokad</Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography variant="body2">{entry.isPreBooked ? "Ja" : "Nej"}</Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography variant="body2">Meddelande till graveraren</Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    {renderMessageField()}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        type="button"
                        color="secondary"
                        disabled={loading || !canSave}
                        variant="contained"
                        onClick={handleSave}
                    >
                        Skicka till graverare
                    </Button>
                    <Button
                        style={{ marginLeft: 10 }}
                        color="secondary"
                        disabled={loading}
                        variant="contained"
                        onClick={handleAbort}
                    >
                        Avbryt
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    return render();
};

export default withToaster(ConfirmEngravingDialog);
