import { Box, Button, Card, CardActions, CardContent, CardHeader, createStyles, Grid, Theme, Typography, WithStyles, withStyles } from "@material-ui/core";
import RefreshIcon from "@material-ui/icons/Refresh";
import React, { useRef, useState } from "react";
import { PickupItem, PickupItemStatus, PickupItemStatuses } from "../../../model/PickupItem";
import { ApiBackend } from "../../../providers/apibackend";
import { ListPickupITemsParameters } from "../../../providers/models";
import { vasaloppetDateTimeGetterFormatter } from "../../../utilities/date";
import EnhancedTable from "../../Common/EnhancedTable/EnhancedTable";
import { IColumnDefinition, ISortModel } from "../../Common/EnhancedTable/models";
import SearchFilter, { Filters } from "../../Common/SearchFilter/SearchFilter";
import useDidMountEffect from "../../Common/UseDidMountEffect";
import PatchLocationItemsDialog from "./PatchLocationItemsDialog";
import PickupItemDialog from "./PickupItemDialog";
import { pickupItemsToPatchPickupItemDocumentProps } from "./utils";

type Props = { locationId: string; } & WithStyles;

interface ISearchFilter {
    publicId?: string;
    status?: PickupItemStatus;
}

const PickupLocationItemsTable = (props: Props & WithStyles) => {
    const [loading, setLoading] = useState(true);
    const [searchFilter, setSearchFilter] = useState(null as ISearchFilter);
    const [items, setItems] = useState(null as PickupItem[]);
    const [sortModel, setSortModel] = useState({ sortBy: "created", sortOrder: "desc" } as ISortModel<PickupItem>);
    const [selected, setSelected] = React.useState<PickupItem[]>([]);
    const [showPatch, setShowPatch] = useState(false);
    const [addItem, setAddItem] = useState(false);

    const busyLoading = useRef<boolean>(false);
    const backend = new ApiBackend();

    const setBusyLoading = (value: boolean): void => {
        busyLoading.current = value;
        setLoading(value);
    };

    useDidMountEffect(() => {
        performSearch();
    }, [searchFilter]);

    const performSearch = async (): Promise<void> => {
        if (busyLoading.current) {
            return;
        }

        setBusyLoading(true);

        try {
            const params: ListPickupITemsParameters = {
                publicId: searchFilter.publicId,
                statuses: [searchFilter.status]
            };

            const response = await backend.listPickupItems(props.locationId, params);
            setItems(response?.items ?? []);
        } finally {
            setBusyLoading(false);
        }
    };

    const handleRequestSearchFilterChange = async (searchFilter: ISearchFilter): Promise<void> => {
        setSearchFilter(searchFilter);
    };

    const columnDefinitions: IColumnDefinition<PickupItem>[] = [
        { propName: "publicId", label: "Public ID" },
        { propName: "status", label: "Status" },
        { propName: "message", label: "Meddelande" },
        {
            propName: "created",
            label: "Skapad",
            valueFormatter: (row) => vasaloppetDateTimeGetterFormatter(row.created, "---"),
            sortValueGetter: (row) => vasaloppetDateTimeGetterFormatter(row.created, "---")
        },
        {
            propName: "updated",
            label: "Uppdaterad",
            valueFormatter: (row) => vasaloppetDateTimeGetterFormatter(row.updated, "---"),
            sortValueGetter: (row) => vasaloppetDateTimeGetterFormatter(row.updated, "---")
        }
    ];

    const render = () => {
        const { classes } = props;
        const isPatchDisabled = !selected || selected.length === 0;

        const defaultBoxStyle = {
            display: "flex",
            flexDirection: "row",
        };

        return (<>
            <SearchFilter<ISearchFilter>
                id="manage-pickup-items-filter"
                filters={{
                    status: {
                        id: "filter-status",
                        type: "GenericSelect",
                        label: "Status",
                        size: 4,
                        itemDefinition: {
                            itemValueType: "string",
                            items: PickupItemStatuses.map(x => {
                                return { name: x, value: x };
                            })
                        },
                        clearable: true
                    },
                    publicId: {
                        id: "filter-publicId",
                        type: "DebouncedText",
                        label: "Public ID",
                        size: 4
                    },
                }}
                persist={true}
                onInit={(filter: Filters<ISearchFilter>) => {
                    handleRequestSearchFilterChange(filter as unknown as ISearchFilter);
                }}
                onChange={(filter: Filters<ISearchFilter>) => {
                    handleRequestSearchFilterChange(filter as unknown as ISearchFilter);
                }}
            />
            <Grid container className={classes.root} spacing={2}>
                <Grid item xs={12}>
                    <Card>
                        <CardHeader className={classes.cardHeader}
                            title={
                                <>
                                    <Typography variant="h5" style={{ display: "inline" }}>Avhämtningar</Typography>
                                    <RefreshIcon
                                        style={{ display: "inline", verticalAlign: "middle" }}
                                        onClick={performSearch}
                                    />
                                </>
                            }
                            action={
                                <Box {...defaultBoxStyle} style={{ gap: "16px", marginLeft: "auto", marginTop: 8, marginRight: 8 }}>
                                    <Button color="primary" variant="text"
                                        onClick={async () => {
                                            setAddItem(true);
                                        }}>Lägg till avhämtning
                                    </Button>
                                </Box>
                            }
                        />
                        <CardContent>
                            <EnhancedTable<PickupItem>
                                columnDefinitions={columnDefinitions}
                                data={items ?? []}
                                pageSize={10}
                                paginationMode="client"
                                sortingMode="client"
                                sortModel={sortModel}
                                onSortModelChange={(nextSortModel: ISortModel<PickupItem>) => {
                                    setSortModel(nextSortModel);
                                }}
                                loading={loading}
                                dense
                                selectable
                                onSelectChanged={(rows: PickupItem[]) => {
                                    setSelected(rows);
                                }}
                            />
                        </CardContent>
                        <CardActions>
                            <Button size="small" color="secondary"
                                disabled={isPatchDisabled}
                                onClick={() => {
                                    setShowPatch(true);
                                }}
                            >
                                Ändra status för markerade
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>
            </Grid>

            {showPatch &&
                <PatchLocationItemsDialog
                    locationId={props.locationId}
                    items={pickupItemsToPatchPickupItemDocumentProps(selected)}
                    patchProperties={["status"]}
                    title="Masshantera status för avhämtningar"
                    onComplete={() => {
                        setShowPatch(false);
                        performSearch();
                    }}
                    onAbort={() => {
                        setShowPatch(false);
                    }}
                />
            }

            {addItem &&
                <PickupItemDialog
                    mode="CREATE"
                    locationId={props.locationId}
                    onAbort={() => {
                        setAddItem(false);
                    }}
                    onComplete={() => {
                        setAddItem(false);
                        performSearch();
                    }}
                />
            }
        </>);
    };

    return render();
};

const useStyles = ({ palette, spacing }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
    photo: {
        height: "30px",
        verticalAlign: "middle",
        borderRadius: "10px"
    },
    root: {

    },
    form: {
        "& > *": {
            margin: spacing(1),
            width: "25ch",
        },
        "& label.Mui-focused": {
            color: palette.secondary.main,
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: palette.secondary.main,
        },
    }
});

export default withStyles(useStyles)(PickupLocationItemsTable);
