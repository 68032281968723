import { Box, Button, Card, CardActions, CardContent, CardHeader, CircularProgress, Theme, WithStyles, createStyles, withStyles } from "@material-ui/core";
import { Formik, FormikProps, FormikState } from "formik";
import React from "react";
import { RouteChildrenProps, withRouter } from "react-router-dom";
import { Club } from "../../../model/Club";
import { Entry } from "../../../model/Entry";
import { Person } from "../../../model/Person";
import { Product } from "../../../model/Product";
import FirebaseContext from "../../../providers/Firebase/context";
import { ApiBackend } from "../../../providers/apibackend";
import DirtyPageChecker from "../../Common/DirtyPageChecker";
import SplitButton from "../../Common/SplitButton/SplitButton";
import EditPersonDialog from "../Person/EditPersonDialog";
import EditEntryFormContent from "./EditEntryFormContent";
import validationSchema from "./EntryValidationSchema";
import SetEntryB2b from "./SetEntryB2b";
import EditEntryActions from "./EditEntryActions/EditEntryActions";
import PersonSearchDialog from "../Person/PersonSearchDialog";
import { copyValuesFromPersonToEntry } from "../../../utilities/EntryHelpers";
import withConfirm, { IConfirmContext } from "../../Common/dialog/Confirm";

interface IProps extends RouteChildrenProps<{ id: string }> {
}

interface IState {
    loading: boolean;
    entry: Entry;
    person: Person;
    validClubs: Club[];
    entryProduct: Product;
    showPerson: boolean;
    showSearchChangePerson: boolean;
    showCreateChangePerson: boolean;
}

class EditEntry extends React.Component<WithStyles & IConfirmContext & IProps, IState> {
    static contextType = FirebaseContext;

    private readonly api: ApiBackend;

    constructor(props: WithStyles & IConfirmContext & IProps) {
        super(props);
        this.state = { entry: null, person: null, validClubs: null, entryProduct: null, showPerson: false, showSearchChangePerson: false, showCreateChangePerson: false, loading: true };
        this.api = new ApiBackend();
        this.loadEntry(this.props.match.params.id);
    }

    render() {
        const { classes } = this.props;
        const { person, showPerson, showSearchChangePerson, showCreateChangePerson, loading, validClubs } = this.state;

        const defaultBoxStyle = {
            display: "flex",
            flexDirection: "row",
        };

        return (
            <>
                {loading &&
                    <CircularProgress color="primary" />
                }
                {this.state.entry &&
                    <Formik
                        initialValues={this.state.entry}
                        validationSchema={validationSchema}
                        onSubmit={async (values, { resetForm }) => {
                            const res = await this.api.saveEntry(values);

                            if (res) {
                                const nextValue = await this.loadEntry(this.props.match.params.id);
                                resetForm({ values: nextValue });
                            }
                        }}
                    >
                        {formik => {
                            const { isValid, dirty, handleSubmit, resetForm, handleReset, isSubmitting } = formik;
                            const isSaveDisabled = !dirty || !isValid || isSubmitting;

                            return (<>
                                <DirtyPageChecker isDirty={dirty} getTitle={this.pageTitle} {...this.props}>
                                    <form autoComplete="off" onSubmit={handleSubmit}>
                                        <Card>
                                            <CardHeader
                                                title={this.cardHeader()}
                                                action={
                                                    <Box {...defaultBoxStyle} style={{ gap: "16px", marginLeft: "auto" }}>
                                                        <SetEntryB2b
                                                            entry={formik.values}
                                                            disabled={dirty}
                                                            onChange={async () => {
                                                                const nextValue = await this.loadEntry(this.props.match.params.id);
                                                                resetForm({ values: nextValue });
                                                            }}
                                                        />
                                                    </Box>
                                                }
                                            />
                                            <CardContent className={classes.form}>
                                                <Box style={{ marginTop: -8, paddingBottom: 8, display: "flex", flexDirection: "column", width: "100%" }}>
                                                    <Box {...defaultBoxStyle} style={{ gap: "16px", marginLeft: "auto" }}>
                                                        <EditEntryActions {...formik}
                                                            entryProduct={this.state.entryProduct}
                                                            isParentDialog={false}
                                                            onChange={async () => {
                                                                const nextValue = await this.loadEntry(this.props.match.params.id);
                                                                resetForm({ values: nextValue });
                                                            }}
                                                        />
                                                    </Box>
                                                </Box>
                                                <EditEntryFormContent {...formik}
                                                    classes={classes}
                                                    person={person}
                                                    validClubs={validClubs}
                                                    entryProduct={this.state.entryProduct}
                                                    onShowPerson={() => {
                                                        this.setState({ showPerson: true });
                                                    }}
                                                    onRequestChangePerson={() => {
                                                        this.setState({ showSearchChangePerson: true });
                                                    }}
                                                    onRequestReloadEntry={async () => {
                                                        const nextValue = await this.loadEntry(this.props.match.params.id);
                                                        resetForm({ values: nextValue });
                                                    }}
                                                />
                                            </CardContent>
                                            <CardActions>
                                                <SplitButton>
                                                    <Button type="submit" color="secondary" disabled={isSaveDisabled} variant="contained">Spara</Button>
                                                    <Button type="submit" color="secondary" variant="text">Forcera Spara</Button>
                                                </SplitButton>
                                                <Button style={{ marginLeft: 10 }} color="secondary" disabled={!dirty} variant="contained" onClick={handleReset}>Avbryt</Button>
                                            </CardActions>
                                        </Card>
                                    </form>
                                </DirtyPageChecker>

                                {showPerson &&
                                    <EditPersonDialog
                                        personId={this.state.entry.personId}
                                        onAbortEdit={async () => {
                                            const nextValue = await this.loadEntry(this.props.match.params.id);
                                            resetForm({ values: nextValue });
                                            this.setState({ showPerson: false });
                                        }}
                                        onSave={async () => {
                                            const nextValue = await this.loadEntry(this.props.match.params.id);
                                            resetForm({ values: nextValue });
                                            this.setState({ showPerson: false });
                                        }}
                                    />
                                }
                                {showSearchChangePerson &&
                                    <PersonSearchDialog
                                        title="Välj person att byta till"
                                        action={
                                            <Button variant="text" color="secondary"
                                                onClick={() => {
                                                    this.setState({ showSearchChangePerson: false, showCreateChangePerson: true });
                                                }}
                                            >
                                                Skapa ny person
                                            </Button>
                                        }
                                        onAbort={() => {
                                            this.setState({ showSearchChangePerson: false });
                                        }}
                                        onPersonSelect={async (value: Person) => {
                                            await this.handleChangePerson(value, formik, resetForm);
                                        }}
                                    />
                                }
                                {showCreateChangePerson &&
                                    <EditPersonDialog
                                        onAbortEdit={() => {
                                            this.setState({ showCreateChangePerson: false });
                                        }}
                                        onSave={async (value: Person) => {
                                            await this.handleChangePerson(value, formik, resetForm);
                                        }}
                                    />
                                }
                            </>)
                        }}

                    </Formik>
                }
            </>
        )
    }

    private handleChangePerson = async (nextValue: Person, formik: FormikProps<Entry>, resetForm: (nextState?: Partial<FormikState<Entry>>) => void) => {
        const { person } = this.state;

        let nextEntry = { personId: nextValue.id } as Entry;
        copyValuesFromPersonToEntry(nextValue, nextEntry);

        const from = `${formik.values.firstName} ${formik.values.lastName} (${person.vasaId})`;
        const to = `${nextEntry.firstName} ${nextEntry.lastName} (${nextValue.vasaId})`;
        const result = await this.props.showConfirm(`Är du säker på att du vill byta person från ${from} till ${to}?`);

        if (result) {
            const nextValues: Entry = {
                ...formik.values,
                ...nextEntry
            };
            formik.setValues(nextValues);
            this.setState({ showSearchChangePerson: false, showCreateChangePerson: false });
            const res = await this.api.saveEntry(nextValues);

            if (res) {
                const nextValue = await this.loadEntry(this.props.match.params.id);
                resetForm({ values: nextValue });
            }
        }
    };

    private loadEntry = async (id: string): Promise<Entry> => {
        return new Promise<Entry>(async (resolve) => {
            const entry = await this.api.getEntry(id);
            const work = [
                this.api.getPerson(entry.personId),
                this.api.getProduct(entry.productId),
                this.api.listClubs(false)
            ];

            const [person, entryProduct, clubs] = await Promise.all(work);

            this.setState({
                loading: false,
                entry: entry,
                person: person as Person,
                validClubs: clubs as Club[],
                entryProduct: entryProduct as Product
            }, () => {
                resolve(entry);
            });
        });
    };

    private pageTitle = (): string => {
        const { entry, entryProduct } = this.state;

        return `Anmälan: ${entry.firstName} ${entry.lastName} - ${entryProduct.name}`;
    };

    private cardHeader = (): string => {
        const { entry, entryProduct } = this.state;

        return `Hantera anmälan: ${entry.firstName} ${entry.lastName} - ${entryProduct.name} / ${entryProduct.variants?.find(x => x.Id === entry.variantId)?.Name}`;
    };
}


const useStyles = ({ palette, spacing }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
    photo: {
        height: '30px',
        verticalAlign: 'middle',
        borderRadius: '10px'
    },
    root: {

    },
    form: {
        '& > *': {
            margin: spacing(1),
            width: '25ch',
        },
        '& label.Mui-focused': {
            color: palette.secondary.main,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: palette.secondary.main,
        },
    }
});

export default withRouter(withConfirm(withStyles(useStyles)(EditEntry)));
