import React, { useState } from "react";
import { Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputLabel, TextField } from "@material-ui/core";
import { FormikErrors, FormikProps, FormikTouched } from "formik";

interface IMetaDataItem {
    key: string;
    value: string;
}

interface IHasMetadata {
    metadata?: IMetaDataItem[];
}

interface IProps<T extends IHasMetadata> extends FormikProps<T> {
    disabled?: boolean;
    label?: string;
    errors: FormikErrors<any>;
    touched: FormikTouched<any>;
    values: T;
    handleBlur: {
        (e: React.FocusEvent<any>): void;
        <T = string | any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
    };
    handleChange: {
        (e: React.ChangeEvent<any>): void;
        <T = string | React.ChangeEvent<any>>(field: T): T extends React.ChangeEvent<any> ? void : (e: string | React.ChangeEvent<any>) => void;
    };
    onChange?: (handler: any) => (e: any) => void;
}

const FormMetadataField = <T extends IHasMetadata>(props: IProps<T>) => {
    const [showDialog, setShowDialog] = useState<false | { editItem: IMetaDataItem }>(false);

    const handleChipDelete = (key: string) => {
        const { values, setFieldValue } = props;

        const nextValue = values.metadata.filter(x => x.key !== key);
        setFieldValue("metadata", nextValue);
    };

    const metadataToChips = () => {
        const { values } = props;

        if (!values.metadata) {
            return null;
        }

        return values.metadata.map((x, idx) => {
            const label = `${x.key} = ${x.value}`;
            return (
                <Chip key={idx} label={label} color="secondary"
                    onClick={() => {
                        setShowDialog({ editItem: x });
                    }}
                    onDelete={() => {
                        handleChipDelete(x.key);
                    }}
                >
                </Chip>
            )
        });
    };

    const handleAddItem = (key: string, value: string, editItem?: IMetaDataItem) => {
        const { values, setFieldValue } = props;
        const nextValue = values.metadata ? [...values.metadata] : [];

        if (!!editItem) {
            const idx = nextValue.findIndex(x => x.key === editItem.key);
            nextValue[idx] = { key: editItem.key, value: value };
        } else {
            nextValue.push({ key: key, value: value });
        }

        setFieldValue("metadata", nextValue);
    };

    const render = () => {
        const { label, disabled } = props;

        const outerBoxStyle = { display: "flex", marginTop: 20, gap: 5 };
        const chipBoxStyle = { display: "flex", flexFlow: "row wrap", gap: 5 };

        return (<>
            <FormControl disabled={disabled} fullWidth>
                {label && <InputLabel shrink>{label}</InputLabel>}
                <Box style={outerBoxStyle}>
                    <Box style={{ minWidth: 90 }}>
                        <Button color="secondary" variant="text"
                            onClick={() => {
                                setShowDialog({ editItem: null });
                            }}
                        >
                            Lägg till
                        </Button>
                    </Box>
                    <Box style={chipBoxStyle}>
                        {metadataToChips()}
                    </Box>
                </Box>
            </FormControl>
            {!!showDialog &&
                <AddItemDialog
                    editItem={showDialog.editItem}
                    onAbort={() => {
                        setShowDialog(false);
                    }}
                    onAdd={(key, value, editItem) => {
                        handleAddItem(key, value, editItem);
                        setShowDialog(false);
                    }}
                />
            }
        </>);
    };

    return render();
};

type AddItemDialogProps = {
    editItem?: IMetaDataItem;
    onAbort: () => void;
    onAdd: (key: string, value: string, editItem?: IMetaDataItem) => void;
}
const AddItemDialog = (props: AddItemDialogProps) => {
    const [key, setKey] = useState(props.editItem ? props.editItem.key : "");
    const [value, setValue] = useState(props.editItem ? props.editItem.value : "");

    const handleOnAdd = () => {
        const { editItem, onAdd } = props;
        onAdd(key, value, editItem);
    };

    const render = () => {
        const { editItem, onAbort } = props;
        const isSaveDisabled = !key || !value;

        const isEditMode = !!editItem;
        const title = isEditMode ? `Redigera värde för ${editItem.key}` : "Lägg till nyckel/värde";
        const saveBtnText = isEditMode ? "Spara" : "Lägg till";

        return (
            <Dialog
                disableEscapeKeyDown
                fullWidth={true}
                maxWidth="sm"
                aria-labelledby="add-metadataitem-title"
                open={true}
            >
                <DialogTitle id="add-metadataitem-title">{title}</DialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={2} style={{ width: "100%" }}>
                        <Grid item xs={6}>
                            <TextField fullWidth
                                label="Nyckel"
                                disabled={!!editItem}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={key}
                                onChange={(e) => {
                                    setKey(e.target.value);
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField fullWidth
                                label="Värde"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={value}
                                onChange={(e) => {
                                    setValue(e.target.value);
                                }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button style={{ marginLeft: 10 }} color="secondary" variant="contained" onClick={onAbort}>
                        Avbryt
                    </Button>
                    <Button type="button" color="secondary" disabled={isSaveDisabled} variant="contained" onClick={handleOnAdd}>
                        {saveBtnText}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    return render();
}

export default FormMetadataField;
