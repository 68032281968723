import React, { forwardRef, useImperativeHandle, useRef } from "react";
import { useState } from "react";
import { Button, Card, CardContent, CardHeader, Grid } from "@material-ui/core";
import { debounce } from "lodash";
import { createForwardedSearchFilter, Filters, SearchFilterRef } from "../../../../../Common/SearchFilter/SearchFilter";
import { IColumnDefinition, ISortModel } from "../../../../../Common/EnhancedTable/models";
import { ISearchFilterEvent } from "../EventSelectorButton";
import { ApiBackend } from "../../../../../../providers/apibackend";
import useDidMountEffect from "../../../../../Common/UseDidMountEffect";
import { ListEngravingEntriesParameters } from "../../../../../../providers/models";
import EnhancedTable from "../../../../../Common/EnhancedTable/EnhancedTable";
import { IListEngravingEntry } from "../../../../../../model/ListEngravingEntry";

export type SearchEntryRef = {
    reset: () => void;
};

export type SearchEntryProps = {
    searchFilterEvent: ISearchFilterEvent | null;
    onChange: (entry: IListEngravingEntry | null) => void;
};

interface ISearchFilterEntries {
    bib?: string;
    lastName?: string;
    firstName?: string;
    productVariant?: string[];
}

const SearchFilterEntries = createForwardedSearchFilter<ISearchFilterEntries>();

const SearchEntry = forwardRef<SearchEntryRef, SearchEntryProps>((props, ref) => {
    const [loading, setLoading] = useState(false);
    const [searchFilterEntries, setSearchFilterEntries] = useState<ISearchFilterEntries>();
    const [entries, setEntries] = useState<IListEngravingEntry[]>([]);
    const [sortModelEntries, setSortModelEntries] = useState({ sortBy: "bibtext", sortOrder: "desc" } as ISortModel<IListEngravingEntry>);

    const searchFilterEntriesRef = useRef<SearchFilterRef>(null);
    const maxRowsEntries = 500;
    const backend = new ApiBackend();

    useImperativeHandle(ref, () => ({
        reset: () => {
            searchFilterEntriesRef?.current?.clearFilter(props.searchFilterEvent?.eventId);
        }
    }));

    useDidMountEffect(() => {
        searchEntries();
    }, [searchFilterEntries]);

    useDidMountEffect(() => {
        searchEntries();
    }, [props.searchFilterEvent]);

    const searchEntries = debounce(async () => {
        setLoading(true);
        const { searchFilterEvent } = props;
        const params: ListEngravingEntriesParameters = {
            ...searchFilterEntries,
            eventId: searchFilterEvent?.eventId,
            sortProp: sortModelEntries?.sortBy as string,
            sortDir: sortModelEntries?.sortOrder,
            begin: 0,
            limit: maxRowsEntries
        };

        const response = await backend.listEngravingEntries(params);
        setEntries(response?.entries ?? []);
        setLoading(false);
    }, 500);

    const columnDefinitionsEntries: IColumnDefinition<IListEngravingEntry>[] = [
        {
            renderCell: (row) => {
                return (
                    <Button
                        color="secondary"
                        variant="text"
                        onClick={() => {
                            props.onChange(row);
                        }}
                    >
                        Välj
                    </Button>
                );
            }
        },
        {
            propName: "isPreBooked",
            label: "Förbokad",
            valueFormatter: (row: IListEngravingEntry) => row.isPreBooked ? "Ja" : "Nej",
        },
        {
            propName: "isDelivered",
            label: "Utlämnad",
            valueFormatter: (row: IListEngravingEntry) => row.isDelivered ? "Ja" : "Nej",
        },
        { propName: "bibtext", label: "Startnummer" },
        { propName: "lastName", label: "Efternamn" },
        { propName: "firstName", label: "Förnamn" },
        { propName: "email", label: "E-post" },
        { propName: "phone", label: "Telefon" },
        { propName: "productName", label: "Lopp" },
        { propName: "variantName", label: "Klass" },
    ];

    const render = () => {
        const { searchFilterEvent } = props;

        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Card>
                        <CardHeader title="Filtrera anmälningar"></CardHeader>
                        <CardContent>

                            <SearchFilterEntries
                                id={"expo-engraving-entries-filter"}
                                ref={searchFilterEntriesRef}
                                filters={{
                                    "bib": {
                                        id: "filter-bib",
                                        type: "DebouncedText",
                                        label: "Startnummer",
                                        size: 3
                                    },
                                    "lastName": {
                                        id: "filter-last-name",
                                        type: "DebouncedText",
                                        label: "Efternamn",
                                        size: 3
                                    },
                                    "firstName": {
                                        id: "filter-first-name",
                                        type: "DebouncedText",
                                        label: "Förnamn",
                                        size: 3
                                    },
                                    "productVariant": {
                                        id: "filter-productVariant",
                                        type: "ProductList",
                                        label: "Lopp",
                                        size: 3,
                                        productTypes: ["race"],
                                        useVariants: true,
                                        multiple: true
                                    },
                                }}
                                eventId={searchFilterEvent.eventId}
                                persist={true}
                                onInit={async (filter: Filters<ISearchFilterEntries>) => {
                                    setSearchFilterEntries(filter as unknown as ISearchFilterEntries);
                                }}
                                onChange={async (filter: Filters<ISearchFilterEntries>) => {
                                    setSearchFilterEntries(filter as unknown as ISearchFilterEntries);
                                }}
                            />

                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <CardHeader title="Anmälningar"></CardHeader>
                        <CardContent>
                            <EnhancedTable<IListEngravingEntry>
                                columnDefinitions={columnDefinitionsEntries}
                                data={entries ?? []}
                                pageSize={10}
                                maxRows={maxRowsEntries}
                                paginationMode="client"
                                sortingMode="client"
                                sortModel={sortModelEntries}
                                onSortModelChange={(nextSortModel: ISortModel<IListEngravingEntry>) => {
                                    setSortModelEntries(nextSortModel);
                                }}
                                loading={loading}
                                dense
                            />
                        </CardContent>
                    </Card>

                </Grid>
            </Grid>
        );
    };

    return render();
});

export default SearchEntry;
