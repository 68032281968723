import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Theme, WithStyles, createStyles, withStyles } from "@material-ui/core";
import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import uuidv4 from "react-uuid";
import { ApiBackend } from "../../providers/apibackend";
import { PickupItemLocation } from "../../model/PickupItemLocation";
import EditPickupLocationFormContent from "./EditPickupLocationFormContent";
import { editPickupLocationValidationSchema } from "./EditPickupLocationValidationSchema";

interface IProps {
    onAbort: () => void;
}

const CreatePickupLocationDialog = (props: IProps & WithStyles & RouteComponentProps) => {
    const [location, setLocation] = useState(new PickupItemLocation());
    const didMount = useRef<boolean>(false);
    const backend = new ApiBackend();
    const formId = `create-pickup-location-form-${uuidv4()}`;

    // Run on load
    useEffect(() => {
        didMount.current = true;

        // unmount
        return () => { didMount.current = false };
    }, []);

    const save = async (values: PickupItemLocation): Promise<PickupItemLocation> => {
        return await backend.createPickupItemLocation(values);
    };

    const handleOnSaveSuccessful = (values: PickupItemLocation) => {
        props.history.push(`manage/${values.id}`);
    };

    const render = () => {
        const { classes, onAbort } = props;

        return <Dialog
            disableEscapeKeyDown
            fullWidth={true}
            maxWidth="lg"
            aria-labelledby="edit-pickup-location-title"
            open={true}
        >
            <DialogTitle id="edit-pickup-location-title">Lägg till avhämtningsplats</DialogTitle>

            <Formik
                initialValues={location}
                validationSchema={editPickupLocationValidationSchema}
                onReset={(values): void => {
                    setLocation(values);
                    onAbort();
                }}
                onSubmit={async (values, { resetForm }) => {
                    const result = await save(values);

                    if (result) {
                        resetForm({ values: result });
                        handleOnSaveSuccessful(result);
                    }
                }}
            >
                {formik => {
                    const { isValid, dirty, handleSubmit, handleReset, isSubmitting } = formik;
                    const isSaveDisabled = !dirty || !isValid || isSubmitting;

                    return (<>
                        <DialogContent dividers>
                            <form id={formId} autoComplete="off" onSubmit={handleSubmit}>
                                <EditPickupLocationFormContent {...formik} classes={classes} mode="create" />
                            </form>
                        </DialogContent>
                        <DialogActions>
                            <Button form={formId} type="submit" color="secondary" disabled={isSaveDisabled} variant="contained">
                                Spara
                            </Button>
                            <Button style={{ marginLeft: 10 }} color="secondary" variant="contained" onClick={handleReset}>
                                {dirty ? "Avbryt" : "Stäng"}
                            </Button>
                        </DialogActions>
                    </>);
                }}
            </Formik>

        </Dialog>;
    }

    return render();
}

const useStyles = ({ palette, spacing }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
    photo: {
        height: "30px",
        verticalAlign: "middle",
        borderRadius: "10px"
    },
    root: {

    },
    form: {
        "& > *": {
            margin: spacing(1),
            width: "25ch",
        },
        "& label.Mui-focused": {
            color: palette.secondary.main,
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: palette.secondary.main,
        },
    }
});

export default withRouter(withStyles(useStyles)(CreatePickupLocationDialog));
