import { Box, Button, Card, CardActions, CardContent, CardHeader, CircularProgress, Tab, Tabs, Theme, Typography, WithStyles, createStyles, withStyles } from "@material-ui/core";
import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { RouteChildrenProps, withRouter } from "react-router-dom";
import { ApiBackend } from "../../providers/apibackend";
import DirtyPageChecker from "../Common/DirtyPageChecker";
import { vasaloppetDateTimeGetterFormatter } from "../../utilities/date";
import { PickupItemLocation } from "../../model/PickupItemLocation";
import EditPickupLocationFormContent from "./EditPickupLocationFormContent";
import { editPickupLocationValidationSchema } from "./EditPickupLocationValidationSchema";
import PickupLocationItemsTable from "./items/PickupLocationItemsTable";
import TabPanel from "../Common/TabPanel";

interface IProps extends RouteChildrenProps<{ id: string }> {
}

const EditPickupLocation = (props: IProps & WithStyles) => {
    const [loading, setLoading] = useState(true);
    const [location, setLocation] = useState(null as PickupItemLocation);
    const didMount = useRef<boolean>(false);
    const backend = new ApiBackend();

    // Run on load
    useEffect(() => {
        didMount.current = true;

        const init = async (): Promise<void> => {
            const result = await backend.getPickupItemLocation(props.match.params.id);

            if (didMount.current) {
                setLocation(result);
                setLoading(false);
            }
        };

        init();

        // unmount
        return () => { didMount.current = false };
    }, []);

    const handleExpoClick = () => {
        props.history.push(`../expo/${location.id}`);
    };

    const render = () => {
        const { classes } = props;
        const errorText = "Ett okänt fel har uppstått - ändringar är inte sparade.";
        const cardHeader = (): string => {
            if (location?.name) {
                return `Hantera ${location.name}`;
            }

            return `Hantera ${location?.id}`
        };

        const defaultBoxStyle = {
            display: "flex",
            flexDirection: "row",
        };

        return (<>
            {loading &&
                <CircularProgress color="primary" />
            }
            {location &&
                <Formik
                    initialValues={location}
                    validationSchema={editPickupLocationValidationSchema}
                    onSubmit={async (values, { resetForm }) => {
                        const response = await backend.updatePickupItemLocation(values.id, values);

                        if (response) {
                            setLocation(response);
                            resetForm({ values: response });
                        } else {
                            alert(`${errorText}\n\nFick felkod från server`);
                        }
                    }}
                >
                    {formik => {
                        const { isValid, dirty, handleSubmit, handleReset, isSubmitting } = formik;
                        const isSaveDisabled = !dirty || !isValid || isSubmitting;

                        return (
                            <DirtyPageChecker isDirty={dirty} getTitle={getTitle} {...props}>
                                <form autoComplete="off" onSubmit={handleSubmit}>
                                    <Card>
                                        <CardHeader className={classes.cardHeader} title={cardHeader()} />
                                        <CardContent className={classes.form}>
                                            <Box style={{ marginTop: -8, paddingBottom: 8, display: "flex", flexDirection: "column", width: "100%" }}>
                                                <Box {...defaultBoxStyle} style={{ gap: "16px", marginLeft: "auto" }}>
                                                    <Button color="secondary" variant="outlined"
                                                        onClick={handleExpoClick}
                                                    >
                                                        Expoläge
                                                    </Button>
                                                </Box>
                                            </Box>
                                            <EditPickupLocationFormContent {...formik} classes={classes} mode="edit" />
                                            <Tabs value={0}>
                                                <Tab label="Avhämtningar" />
                                            </Tabs>
                                            <TabPanel value={0} index={0} style={{ width: "100%" }}>
                                                <PickupLocationItemsTable locationId={formik.values.id} />
                                            </TabPanel>
                                        </CardContent>
                                        <CardActions>
                                            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} width={"100%"}>
                                                <Box>
                                                    <Button type="submit" color="secondary" disabled={isSaveDisabled} variant="contained">Spara</Button>
                                                    <Button style={{ marginLeft: 10 }} color="secondary" disabled={!dirty} variant="contained" onClick={handleReset}>Avbryt</Button>
                                                </Box>
                                                <Box>
                                                    <Typography variant="body2">Skapad: {vasaloppetDateTimeGetterFormatter(formik.values.created)}</Typography>
                                                    <Typography variant="body2">Uppdaterad: {vasaloppetDateTimeGetterFormatter(formik.values.updated)}</Typography>
                                                </Box>
                                            </Box>

                                        </CardActions>
                                    </Card>
                                </form>
                            </DirtyPageChecker>
                        )
                    }}
                </Formik>
            }
        </>);
    }


    const getTitle = (): string | undefined => {
        if (location?.name) {
            return `Avhämtningsplats: ${location.name}`;
        }
    };

    return render();
}

const useStyles = ({ palette, spacing }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
    photo: {
        height: "30px",
        verticalAlign: "middle",
        borderRadius: "10px"
    },
    root: {

    },
    form: {
        "& > *": {
            margin: spacing(1),
            width: "25ch",
        },
        "& label.Mui-focused": {
            color: palette.secondary.main,
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: palette.secondary.main,
        },
    }
});

export default withRouter(withStyles(useStyles)(EditPickupLocation));
